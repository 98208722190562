import React from 'react';

import DrawUtil from '../../helper/DrawUtil';
// Styles
import style from '../../styles/ContentPages.module.css';

class SolitonGame extends React.Component {
    constructor(props) {
        super(props);

        this.s0 = {
            x: -1.0,
            y: 3
        };

        this.s1 = {
            x: 2.5,
            y: 1
        }
        
        this.mouseIsClicked = false;

        // Splash Image
        this.splashImg = new Image();
        this.splashImg.setAttribute("src", "/images/soliton_splash.png");

        this.canvas = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);

        this.updateCanvasProps();
        this.update();

        this.startTime = performance.now();
        this.animTime = 0;
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.requestID);
        window.removeEventListener('resize', this.onWindowResize);
    }

    componentDidUpdate(oldProps) {
        if (this.props.isAnimating) {
            this.startTime = performance.now();
            this.animTime = 0;
            this.randomSplashOrientation = 2*Math.PI*Math.random();
        } else if (this.props.resetAnimationFlag !== oldProps.resetAnimationFlag) {
            this.animTime = 0;
        }
    }

    onMouseDown = (ev) => {
        // No manipulation when Animation is running
        if (this.props.isAnimating) { return; }

        // Reset animation by click if not in startposition
        if (this.animTime > 0) {
            this.animTime = 0; 
            return;
        }
        
        const {pageX, pageY} = ev.touches ? ev.touches[0] : ev;
        this.mousePos = this.pageToCanvasCoordinates(pageX, pageY);
        this.mouseIsClicked = true;
    }

    onMouseMove = (ev) => {
        if (!this.mouseIsClicked) { return; }

        const {pageX, pageY} = ev.touches ? ev.touches[0] : ev;
        let newMousePos = this.pageToCanvasCoordinates(pageX, pageY);

        // Apply delta
        this.s1.x -= ((this.mousePos.x - newMousePos.x) * 38 / (this.refLength * 2));
        this.s1.y += 8 * (this.mousePos.y - newMousePos.y) / this.refLength;

        // ensure correct max / min values
        this.s1.x = Math.max(Math.min(this.s1.x, 14), -2.2);
        this.s1.y = Math.max(Math.min(this.s1.y, 2.3), 0);

        this.mousePos = newMousePos;
    }

    onMouseUp = () => {
        this.mouseIsClicked = false;
    }

    pageToCanvasCoordinates = (clickX, clickY) => {
        return {
            x: (clickX - this.canvas.current.offsetLeft) * this.dpr - this.offset.x,
            y: (clickY - this.canvas.current.offsetTop) * this.dpr - this.offset.y
        };
    }

    updateCanvasProps = () => {
        this.dpr = window.devicePixelRatio || 1;

        // Get current dpr adjusted width / height of DOM element
        this.width = this.canvas.current.clientWidth * this.dpr;
        this.height = this.canvas.current.clientHeight * this.dpr;

        // Set Canvas resolution based on width / height
        this.canvas.current.width = this.width;
        this.canvas.current.height = this.height;
        
        let aspect = this.width / this.height;
        this.refLength = (aspect >= 2) ? this.height : (this.width / 2);
    
        this.offset = {
            x: (this.width - this.refLength * 2) / 2,
            y: (this.height - this.refLength) / 2,
        };
    
        this.ctx = this.canvas.current.getContext("2d");
    }

    update = () => {
        this.ctx.clearRect(0,0,this.width, this.height);
        this.ctx.lineWidth = 1 * this.dpr;

        // Translate offset
        this.ctx.save();
        this.ctx.translate(this.offset.x, this.offset.y);
    
        // Fill Background white
        this.ctx.fillStyle = "#ffffff";
        this.ctx.fillRect(0, 0, this.refLength * 2, this.refLength);

        this.drawStickFigure( // Left
            this.refLength * 0.15,   // X 
            this.refLength * 0.25,   // Y
            this.refLength * 0.1,   // Width
            this.refLength * 0.275,    // Height
            "Alice", false
        );

        this.drawTower( // Left
            this.refLength * 0.1,   // X 
            this.refLength * 0.5,   // Y
            this.refLength * 0.2,   // Width
            this.refLength * 0.5    // Height
        );

        this.drawStickFigure( // Left
            this.refLength * 1.75,   // X 
            this.refLength * 0.25,   // Y
            this.refLength * 0.1,   // Width
            this.refLength * 0.275,    // Height
            "Bob", true
        );

        this.drawTower( // Right
            this.refLength * 1.7, // X 
            this.refLength * 0.5,   // Y
            this.refLength * 0.2,   // Width
            this.refLength * 0.5    // Height
        );

        this.drawBus(
            this.refLength * 0.95, // X 
            this.refLength * 0.25,   // Y
            this.refLength * 0.1,   // Width
            this.refLength * 0.13    // Height
        );

        this.drawBridge(
            this.refLength * 0.9, // X 
            this.refLength * 0.05,   // Y
            this.refLength * 0.2,   // Width
            this.refLength * 0.95    // Height
        );

        this.drawWaves();
        
        this.ctx.restore();

        this.requestID = window.requestAnimationFrame(this.update);
    }

    drawWaves() {
        // Animation time calculation 
        this.animTime = (this.props.isAnimating) ? performance.now() - this.startTime : this.animTime;
        let t = this.animTime / 1000;
        
        // Move to origin of graph and apply scale to fit curve to drawing
        this.ctx.translate(0, 0.75*this.refLength);
        this.ctx.save();
        this.ctx.scale((this.refLength*2) / 38, (this.refLength) / 8);

        // Set colors and transparency
        this.ctx.strokeStyle = "#5858b3";
        this.ctx.fillStyle = "#4b70e3";
        this.ctx.globalAlpha = 0.8;

        let xBegin = -8, xEnd = 30;
        
        this.ctx.beginPath();
        this.ctx.moveTo(0, 0);

        this.ctx.lineWidth=0.05;
        let bridgeCollision = 0;
        let bobCollision = 0;
        let collisionPt = {x:0, y:0};
        for (let i=xBegin; i<xEnd; i+=0.02) {
            let y = -this.calcPlotPoint(t,i);
            this.ctx.lineTo(i-xBegin,y);
        
            let collision = false;
            // Hit-Test Bridge
            if (i > xBegin+17 && i < xBegin+21 && y <= -2.7) {
                bridgeCollision++; collision = true;
            } else if (i > xBegin+33.1 && y <= -2.8) { // Hit Test Bob
                bobCollision++; collision = true;
            }

            if (collision && collisionPt.y > y) {
                collisionPt.y = y;
                collisionPt.x = i-xBegin;
            }
        }
        this.ctx.lineTo(30-xBegin,2);
        this.ctx.lineTo(0,2);
        this.ctx.closePath();

        this.ctx.stroke();
        this.ctx.fill();

        this.ctx.restore();

        if (bridgeCollision > 0 || bobCollision > 0) {
            const success = bobCollision > 0;
            // Propagate Collision if animation is running (and therefore end animation)
            if (this.props.isAnimating) {
                this.props.onCollision(success ? "bob" : "bridge");
            }

            // Draw Splash image and user info (Fail / Success)
            this.ctx.save();
            this.ctx.translate(collisionPt.x * (this.refLength*2) / 38, collisionPt.y * (this.refLength) / 8);
            this.ctx.rotate(this.randomSplashOrientation);
            this.ctx.translate(-0.05*this.refLength,-0.05*this.refLength);

            this.ctx.drawImage(this.splashImg, 
                0, 0,
                0.1*this.refLength, 0.1*this.refLength
            );
            this.ctx.restore();

            this.ctx.save();
            this.ctx.translate(this.refLength, 0.075*this.refLength);
            this.ctx.font = this.refLength*0.1+"px Arial";
            this.ctx.textBaseline = "middle";
            this.ctx.textAlign = "center";
            this.ctx.fillStyle = success ? "#42f54e" : "#f54242";
            this.ctx.strokeStyle = "#000";
            const title = success? "Success!" : "Fail!";
            this.ctx.fillText(title, 0, 0);
            this.ctx.strokeText(title, 0, 0);
            this.ctx.fillStyle = "#fff";
            this.ctx.font = this.refLength*0.07+"px Arial";
            const text = success? "You splashed Bob using Solitons" : "Click to reset and try again";
            this.ctx.fillText(text, 0, 0.1*this.refLength);
            this.ctx.strokeText(text, 0, 0.1 * this.refLength);
            this.ctx.restore();
        }
    }

    drawTower = (x, y, width, height) => {
        // Horizontal Supports
        DrawUtil.drawRectWithOutline(this.ctx, x+0.225*width, y+0.3*height, width*0.5, 0.02 * height, "#000", "#a52a29");
        DrawUtil.drawRectWithOutline(this.ctx, x+0.225*width, y+0.6*height, width*0.5, 0.02 * height, "#000", "#a52a29");
        
        // Columns
        DrawUtil.drawRectWithOutline(this.ctx, x+0.225*width, y, width*0.04, height, "#000", "#a52a29");
        DrawUtil.drawRectWithOutline(this.ctx, x+0.725*width, y, width*0.04, height, "#000", "#a52a29");
        
        // Platform
        DrawUtil.drawRectWithOutline(this.ctx, x, y+0.03*height, width, 0.045 * height, "#000", "#a52a29");
        
        // Foundation
        DrawUtil.drawRectWithOutline(this.ctx, x+0.17*width, y+height*0.9, width*0.15, height*0.1, "#000", "#808080");
        DrawUtil.drawRectWithOutline(this.ctx, x+0.67*width, y+height*0.9, width*0.15, height*0.1, "#000", "#808080");
        DrawUtil.drawRectWithOutline(this.ctx, x, y+0.96*height, width, 0.04 * height, "#000", "#808080");
    }

    drawStickFigure = (x, y, width, height, name, mirror) => {
        let sign = (mirror) ? -1 : 1;
        
        this.ctx.save();
        this.ctx.translate(x+0.5*width, y);
        this.ctx.translate(sign*0.2*width, 0);
        
        this.ctx.strokeStyle = "#000";
        this.ctx.lineWidth = 0.05 * width;
        
        this.ctx.beginPath();
        // Legs
        this.ctx.moveTo(sign*-0.2*width, height);
        this.ctx.lineTo(sign*0.15*width, height*0.75);
        this.ctx.lineTo(sign*0.35*width, height);
        // Body
        this.ctx.moveTo(sign*0.15*width, height*0.75);
        this.ctx.lineTo(sign*0.2*width, height*0.4);
        // Arms
        this.ctx.moveTo(sign*-0.2*width, height*0.65);
        this.ctx.lineTo(sign*0.175*width, height*0.525);
        this.ctx.lineTo(sign*0.7*width, height*0.6);
        this.ctx.stroke();
        DrawUtil.drawCircleWithOutline(this.ctx, sign*0.2*width, 0.4*height, 0.2 * width, "#000", "#000");
        
        this.ctx.fillStyle="#000";
        this.ctx.textAlign="center";
        this.ctx.font=0.4*width+"px Arial";
        this.ctx.fillText(name, sign*width*0.175, 0.2*height, width);

        this.ctx.restore();

    }

    drawBridge = (x, y, width, height) => {
        // Horizontal Supports
        DrawUtil.drawRectWithOutline(this.ctx, x+0.05*width, y+0.05*height, width*0.9, 0.015 * height, "#000", "#808080");
        DrawUtil.drawRectWithOutline(this.ctx, x+0.05*width, y+0.85*height, width*0.9, 0.015 * height, "#000", "#808080");
        DrawUtil.drawRectWithOutline(this.ctx, x+0.05*width, y+0.7*height, width*0.9, 0.015 * height, "#000", "#808080");

        // Columns
        DrawUtil.drawRectWithOutline(this.ctx, x+0.05*width, y, width*0.1, height, "#000", "#808080");
        DrawUtil.drawRectWithOutline(this.ctx, x+0.85*width, y, width*0.1, height, "#000", "#808080");

        // Street
        DrawUtil.drawRectWithOutline(this.ctx, x, y+0.35*height, width, 0.03*height, "#000", "#c0c0c0");

        // Foundation
        DrawUtil.drawRectWithOutline(this.ctx, x+0.02*width, y+height*0.9, width*0.16, height*0.1, "#000", "#808080");
        DrawUtil.drawRectWithOutline(this.ctx, x+0.82*width, y+height*0.9, width*0.16, height*0.1, "#000", "#808080");
        DrawUtil.drawRectWithOutline(this.ctx, x, y+0.96*height, width, 0.04 * height, "#000", "#808080");
    }

    drawBus = (x, y, width, height) => {
        // Tires
        DrawUtil.drawRectWithOutline(this.ctx, x + width * 0.1, y + height * 0.825, width * 0.2, height * 0.175, "#000", "#2f4f4f");
        DrawUtil.drawRectWithOutline(this.ctx, x + width * 0.7, y + height * 0.825, width * 0.2, height * 0.175, "#000", "#2f4f4f");
        
        // Body
        DrawUtil.drawRectWithOutline(this.ctx, x, y, width, height * 0.825, "#000", "#6f8090");
        // Window, plate & lights
        DrawUtil.drawRectWithOutline(this.ctx, x + 0.1 * width, y + 0.1 * height, width * 0.8, height * 0.5, "#000", "#6f8090");
        DrawUtil.drawCircleWithOutline(this.ctx, x + 0.175 * width, y + 0.725*height, 0.075 * width, "#000", "#f00");
        DrawUtil.drawCircleWithOutline(this.ctx, x + 0.825 * width, y + 0.725*height, 0.075 * width, "#000", "#f00");
        DrawUtil.drawRectWithOutline(this.ctx, x + 0.325 * width, y + 0.6775 * height, width * 0.35, height * 0.075, "#000", "#fff");
    }

    calcPlotPoint(t, x) {
        if (this.s0.y < this.s1.y) {
            return -1;
        } else {
            // From Maple:
            // -2*(v[1]-v[2])*(v[1]*sech(sqrt(v[1]/2)*(-2*t*v[1]+x-x0[1]))^2 +
            // v[2]*csch(sqrt(v[2]/2)*(-2*t*v[2]+x-x0[2]))^2)/
            // (sqrt(2*v[1])*tanh(sqrt(v[1]/2)*(-2*t*v[1]+x-x0[1]))
            // -sqrt(2*v[2])*coth(sqrt(v[2]/2)*(-2*t*v[2]+x-x0[2])))^2;

            // a = v[1]*sech(sqrt(v[1]/2)*(-2*t*v[1]+x-x0[1]))^2
            let a = this.s1.y * Math.pow(1 / Math.cosh(Math.sqrt(this.s1.y/2) * (-2 * t * this.s1.y + x - this.s1.x)), 2);
            // b = v[2]*csch(sqrt(v[2]/2)*(-2*t*v[2]+x-x0[2]))^2
            let b = this.s0.y * Math.pow(1 / Math.sinh(Math.sqrt(this.s0.y/2) * (-2 * t * this.s0.y + x - this.s0.x)), 2);
            // c = sqrt(2*v[1])*tanh(sqrt(v[1]/2)*(-2*t*v[1]+x-x0[1]))
            let c = Math.sqrt(2 * this.s1.y) * Math.tanh(Math.sqrt(this.s1.y/2) * (-2 * t * this.s1.y + x - this.s1.x));
            // d = sqrt(2*v[2])*coth(sqrt(v[2]/2)*(-2*t*v[2]+x-x0[2]))
            let d = Math.sqrt(2 * this.s0.y) * this.coth(Math.sqrt(this.s0.y/2) * (-2 * t * this.s0.y + x - this.s0.x));
            
            // -2*(v[1]-v[2]) *(a + b) / (c - d)^2
            return -2 * (this.s1.y - this.s0.y) * (a + b) / Math.pow(c - d, 2);
        }
    }

    coth(x) {
        return Math.cosh(x) / Math.sinh(x);
    }

    onWindowResize = () => {
        this.updateCanvasProps();
    };

    render() {
      return <div className={`${style.mediaWrapper} ${style.largeMediaContent}`}>
              <canvas className={style.canvasContent} ref={this.canvas} 
                onMouseDown={this.onMouseDown} onTouchStart={this.onMouseDown} 
                onMouseUp={this.onMouseUp} onTouchEnd={this.onMouseUp} onMouseLeave={this.onMouseUp} 
                onMouseMove={this.onMouseMove} onTouchMove={this.onMouseMove}></canvas>
          </div>;
    }
}
export default SolitonGame;