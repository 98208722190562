import React from 'react';

import PBMultipleChoiceButton from './PBMultipleChoiceButton';

// Conf
import config from "../../config/PBParticleShooterConfig";

// Styles
import style from '../../styles/PhysicalBasics.module.css';

class PBParticleShooterControls extends React.Component {
    constructor(props) {
        super(props);

        this.buttons = {};
        this.updateButtonIcons();

        this.state = {
            updateButtonsFlag: 0,
            disableButtons: false
        };
    }

    componentDidUpdate(oldProps) {
        if (this.props.polygonIndex !== oldProps.polygonIndex) {
            this.updateButtonIcons();
            this.setState({ updateButtonsFlag: this.state.updateButtonsFlag + 1, disableButtons: true});
            setTimeout(()=> {
                this.setState({disableButtons: false});
            }, 4000);
        }
    }

    onSelection = (buttonId) => {
        this.lastClick = performance.now();
        this.props.onUserSelection(buttonId === this.correctId);
    }

    updateButtonIcons() {
        let buttonIds = Array.from(Array(4).keys());

        // Prepare correct answer
        let index = Math.floor(Math.random()*buttonIds.length);
        this.correctId = buttonIds.splice(index, 1)[0];
        
        this.buttons[this.correctId] = {
            correct: true, imgId: config.polygons[this.props.polygonIndex].imgId
        };

        let unusedPolygonIds = Array.from(Array(config.polygons.length).keys());
        unusedPolygonIds.splice(unusedPolygonIds.indexOf(this.props.polygonIndex), 1);

        for (let i=0; i<3; i++) {
            index = Math.floor(Math.random()*buttonIds.length);
            let buttonId = buttonIds.splice(index, 1)[0];
            index = Math.floor(Math.random()*unusedPolygonIds.length);
            let polygonId = unusedPolygonIds.splice(index, 1)[0];
            
            this.buttons[buttonId] = {
                correct: false,
                imgId: config.polygons[polygonId].imgId
            };
        }
    }

    render() {
        const hidden = (this.props.screen === "game") ? null : style.hidden;
        return <div className={`${style.gameControls} ${hidden}`}>
            <PBMultipleChoiceButton buttonId={0} imgId={this.buttons[0].imgId} onSelection={this.onSelection} disabled={this.state.disableButtons}/>
            <PBMultipleChoiceButton buttonId={1} imgId={this.buttons[1].imgId} onSelection={this.onSelection} disabled={this.state.disableButtons}/>
            <PBMultipleChoiceButton buttonId={2} imgId={this.buttons[2].imgId} onSelection={this.onSelection} disabled={this.state.disableButtons}/>
            <PBMultipleChoiceButton buttonId={3} imgId={this.buttons[3].imgId} onSelection={this.onSelection} disabled={this.state.disableButtons}/>
        </div>;
    }
}
export default PBParticleShooterControls;