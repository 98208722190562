const config = {
    polygons: [
        {
            imgId: "tri-1",
            path: [ // Triangle, edge down (tri_1)
                { x: 0.5, y: 0.7 }, // Top Point
                { x: 0.3, y: 0.3},  // Left
                { x: 0.7, y: 0.3}   // Right
            ]
        },
        {
            imgId: "pent-1",
            path: [ // Square with one edge cut (pent_1)
                { x: 0.7, y: 0.7 },
                { x: 0.5, y: 0.7 },
                { x: 0.3, y: 0.5 },
                { x: 0.3, y: 0.3 },
                { x: 0.7, y: 0.3}
            ]
        },
        {
            imgId: "pent-2",
            path: [ // Square with one edge cut (pent_2)
                { x: 0.3, y: 0.3 },
                { x: 0.7, y: 0.3 },
                { x: 0.7, y: 0.5 },
                { x: 0.5, y: 0.7 },
                { x: 0.3, y: 0.7 }
            ]
        },
        {
            imgId: "tri-2",
            path: [ // Diagonal triangle (tri_2)
                { x: 0.5 , y: 0.7 },
                { x: 0.3, y: 0.5 },
                { x: 0.7, y: 0.3 }
            ]
        },
        {
            imgId: "tri-3",
            path: [ // One face triangle, left (tri_3)
                { x: 0.3, y: 0.3 },
                { x: 0.7, y: 0.3 },
                { x: 0.7, y: 0.7 }
            ]
        },
        {
            imgId: "tri-4",
            path: [ // Diagonal triangle (tri_4)
                { x: 0.5, y: 0.7 },
                { x: 0.3, y: 0.3 },
                { x: 0.7, y: 0.5 }
            ] 
        },
        {
            imgId: "tri-5",
            path: [ // One face triangle, right (tri_5)
                { x: 0.3, y: 0.3 },
                { x: 0.7, y: 0.3 },
                { x: 0.3, y: 0.7 }
            ]
        },
        {
            imgId: "oct",
            path: [ // Octagon (oct)
                { x: 0.3, y: 0.4333 },
                { x: 0.4333, y: 0.3 },
                { x: 0.5666, y: 0.3 },
                { x: 0.7, y: 0.4333 },
                { x: 0.7, y: 0.5666 },
                { x: 0.5666, y: 0.7 },
                { x: 0.4333, y: 0.7 },
                { x: 0.3, y: 0.5666 }
            ]
        },
        {
            imgId: "arrow",
            path: [ // Arrow
                { x: 0.3, y: 0.7 },
                { x: 0.5, y: 0.3 },
                { x: 0.7, y: 0.7 },
                { x: 0.5, y: 0.6 }
            ]
        },
        {
            imgId: "quad-1",
            path: [ // Quad (quad_1)
                { x: 0.3, y: 0.3 },
                { x: 0.7, y: 0.3 },
                { x: 0.5, y: 0.6 },
                { x: 0.3, y: 0.7 }
            ]
        },
        {
            imgId: "quad-2",
            path: [ // Quad (quad_2)
                { x: 0.3, y: 0.3 },
                { x: 0.7, y: 0.3 },
                { x: 0.7, y: 0.7 },
                { x: 0.5, y: 0.6 }
            ]
        },
        {
            imgId: "hex",
            path: [ // Hexagon (hex)
                { x: 0.3, y: 0.5 },
                { x: 0.4, y: 0.3 },
                { x: 0.6, y: 0.3 },
                { x: 0.7, y: 0.5 },
                { x: 0.6, y: 0.7 },
                { x: 0.4, y: 0.7 }
            ]
        }
    ]
};

export default config;