export default class ToolboxCell {
    constructor(shape, count, x, y) {
        this.shape = shape;
        this.count = count;
        this.shapeId = shape.shapeId;

        this.x = x; this.y = y;

        this.toolboxScale = 0.2;
    }

    draw (ctx, cellSize) {
        ctx.save();
        ctx.translate((this.x+4)*cellSize, this.y*cellSize);

        ctx.strokeStyle = "#000";
        ctx.beginPath();
        ctx.rect(0,0,cellSize, cellSize);
        ctx.stroke();

        ctx.translate(0.5*cellSize, 0);
        ctx.fillStyle = "#000";
        ctx.font = 0.1*cellSize+"px Arial";
        ctx.textAlign = "center";
        ctx.fillText(this.count+"x", 0, 0.2*cellSize);

        ctx.translate(0, 0.5*cellSize);
        this.shape.drawThumbnail(ctx, this.toolboxScale);

        ctx.restore();
    }

    checkClick(mousePos, cellSize) {
        return (mousePos.x > (cellSize * (this.x + 4)) && mousePos.x < (cellSize * (this.x + 5)) &&
            (mousePos.y > (this.y * cellSize) && mousePos.y < (cellSize * (this.y + 1)))) ;
    }

    resize(resizeScale) {
        this.shape.resize(resizeScale);
    }
}