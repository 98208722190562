import * as THREE from 'three';

const quaternions = {
    "Physical Basics" : new THREE.Quaternion(0.8967501874259493, -0.09153650411235745, 0.26054523559175674, 0.34579813472147203),
    "Gravity" : new THREE.Quaternion(-0.2614114308932796, -0.44286427368953457, 0.21799324109973603, 0.8294662414604957),
    "Solitons" : new THREE.Quaternion(-0.5073687502179444, 0.752430196207388, 0.11366187435455695, 0.40435965359541215),
    "Feynman Diagrams" : new THREE.Quaternion(0.4969838092851116, 0.10782571409501639, 0.02234026140782429, 0.8607448062048297),
    "Beyond Feynman Diagrams" : new THREE.Quaternion(0.47156303531423927, -0.21077393351222357, -0.49232781672650805, 0.7005825958117404),
    "About SAGEX" : new THREE.Quaternion(0.8811336916093664, -0.24952274133007812, -0.37297296928702167, -0.14910728771993445),
    "Team" : new THREE.Quaternion(-0.5217424113188829, -0.06521963291782885, -0.1199298058276509, 0.8421093143929494),
    "Acknowledgements" : new THREE.Quaternion(-0.11187122477936948, 0.9300915024004768, -0.2680518934319808, -0.22486175454335888)
};

const positions = {
    "Physical Basics" : new THREE.Vector3(1.6159266917509396, -2.6715515372177765, -2.5003186418686827),
    "Gravity" : new THREE.Vector3(-3.394615117242685, 0.9623243093519712, 1.8842823911021505),
    "Solitons" : new THREE.Vector3(1.9489993855215282, 2.297551148504793, -2.557530863008441),
    "Feynman Diagrams" : new THREE.Vector3(0.8313053727799794, -3.4029390237654504, 1.9310456695068674),
    "Beyond Feynman Diagrams" : new THREE.Vector3(-3.038625192757029, -1.8127918788830522, 1.8656212214160484),
    "About SAGEX" : new THREE.Vector3(-2.3314671209136657, 1.7955895409043319, -2.7092654474402047),
    "Team" : new THREE.Vector3(0.06120324576104991, 3.577487377579607, 1.7882500457057557),
    "Acknowledgements" : new THREE.Vector3(-1.433237707943797, -2.1957467853619703, -3.0206829901696888)
};

const conf = {
    quaternions: quaternions,
    positions: positions
}

export default conf;