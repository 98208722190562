import React from 'react';

import style from "../styles/ExhibitionHub.module.css";

class AmplituhedronTopicListEntry extends React.Component {
    handleHover = () => {
        this.props.onTopicChange(this.props.title, true);
    }

    handleHoverExit = () => {
        this.props.onTopicChange(null, true);
    }

    handleClick = () => {
        this.props.onSelectTopic(this.props.title);
    }

    render() {
        let active = (this.props.currentListTopic === this.props.title || (this.props.currentListTopic === null && this.props.currentTopic === this.props.title)) ? style.active : null;
        return <h2 className={`${style.topicListEntry} ${active}`} onClick={this.handleClick} onMouseOver={this.handleHover} onMouseLeave={this.handleHoverExit}>
            {this.props.title}
        </h2>;
    }
}
export default AmplituhedronTopicListEntry;