import React from 'react';

// Styles 
import style from '../styles/ContentPages.module.css'

class SubsectionFooterNavElement extends React.Component {
    handleClick = () => {
        this.props.onPageUpdate(this.props.pageID);
    }
    
    render() {
        let activeIndicator = (this.props.active) ? <div className={style.activeNavElem}/> : null;
        return <div className={`${style.footerElemWrapper} ${style.footerNavElem}`} onClick={this.handleClick}>
            {activeIndicator}
        </div>
    }
}
export default SubsectionFooterNavElement;