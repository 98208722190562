import React from 'react';

// Styles
import style from '../../styles/PhysicalBasics.module.css';

class PBParticleShooterControls extends React.Component {
    
    onSelection = (ev) => {
        this.props.onSelection(this.props.buttonId);
    }

    render() {
        const imgPrefix = "/images/physical-basics/pb-particle-shooter_";
        
        return <button className={style.iconButton} onClick={this.onSelection} disabled={this.props.disabled}>
            <img alt="" src={imgPrefix + this.props.imgId + ".png"}></img>
        </button>;
    }
}
export default PBParticleShooterControls;