import React from 'react';
import {withRouter, Redirect} from "react-router-dom";

// Styles 
import style from '../styles/ContentPages.module.css'

import SubsectionFooterNavElement from './SubsectionFooterNavElement';

class SubsectionFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backToAmplituhedron: false
        };
    }

    handleBackToAmplituhedron = () => {
        this.setState({backToAmplituhedron: true});
    }
    
    render() {
        if (this.state.backToAmplituhedron) {
            return <Redirect push to={"/exhibition-hub"} />;
        }

        let pageNav = [];
        if (this.props.numberOfPages > 1) {
            for (let i=0; i<this.props.numberOfPages; i++) {
                pageNav.push(<SubsectionFooterNavElement key={i} pageID={i} active={parseInt(this.props.page) === i} onPageUpdate={this.props.onPageUpdate}/>)
            }
        }

        return <div className={style.footerWrapper}>
            <div className={style.footerLimiter}>
                <div className={style.homeNavWrapper} onClick={this.handleBackToAmplituhedron}>
                    <img className={style.footerHomeIcon} src="/amplituhedron_icon.png" alt="Icon of an amplituhedron"/>
                    <div className={style.footerHomeLabel}><span className={style.hideOverflow}>Back to Hub</span></div>
                </div>
                <div className={style.footerNavElemWrapper}>
                    {pageNav}
                </div>
                <div className={style.footerTitleWrapper}><span className={style.hideOverflow}>{this.props.title}</span></div>
            </div>         
        </div>;
    }
}
export default withRouter(SubsectionFooter);