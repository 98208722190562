import React from 'react';
import {withRouter} from "react-router-dom";

import style from '../styles/ContentPages.module.css';

class Video extends React.Component {
    constructor(props) {
        super(props);
        this.video = React.createRef();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.location !== prevProps.location) {
            this.video.current.load();
        }
    }

    render() {
        return <div className={`${style.mediaWrapper} ${style.largeMediaContent}`} >
            <video className={style.mediaContent} ref={this.video} poster={"/videos/thumbnails/"+this.props.name+".png"} controls>
                <source type="video/webm" src={"/videos/"+this.props.name+".webm"}></source>
                <source type="video/mp4" src={"/videos/"+this.props.name+".mp4"}></source>
            </video>
        </div>;
    }
}

export default withRouter(Video);