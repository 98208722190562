import React from 'react';
import {withRouter} from "react-router-dom";

// Styles 
import baseStyle from '../styles/App.module.css';
import style from '../styles/ContentPages.module.css';

class SubsectionContentController extends React.Component {
    constructor(props) {
        super(props);
        
        this.updateContent();
        this.state = {
            contentChangedFlag: 0
        }
    }

    componentDidUpdate(oldProps) {
        if (oldProps.topic !== this.props.topic) {
            this.updateContent();
            this.setState({contentChangedFlag: this.state.contentChangedFlag+1});
        }
    }

    updateContent() {
        this.content = [];

        this.props.panels.forEach((panel) => {
            this.content.push(panel.content);
        });
    }

    nextPage = () => {
        if (parseInt(this.props.page) === (this.props.panels.length - 1)) {
            this.props.history.push("/exhibition-hub/");
        } else {
            this.props.onPageUpdate(parseInt(this.props.page)+1);
        }
    }

    prevPage = () => {
        this.props.onPageUpdate(parseInt(this.props.page)-1);
    }

    render() {
        let leftHidden = (parseInt(this.props.page) === 0) ? style.hidden : null;
        let leftSpan = <div className={`${style.navArrowLabel} ${style.navArrowLabelLeft}`}>Previous</div>;
        let lastRightStyle = null;
        let rightSpan = <div className={`${style.navArrowLabel} ${style.navArrowLabelRight}`}>Next</div>;
        if (parseInt(this.props.page) === (this.props.panels.length - 1)) {
            lastRightStyle = style.backHubArrow;
            rightSpan = <div className={`${style.navArrowLabel} ${style.navArrowLabelRight}`}>Back to Hub</div>
        }

        return <div className={baseStyle.mainDisplay}>
            {this.content[this.props.page]}
            <div className={`${style.navArrow} ${style.leftArrow} ${leftHidden}`} onClick={this.prevPage}>
                <img src="arrow-nav.png" alt="navigation element, click leads to previous panel"></img>
                {leftSpan}
            </div>
            <div className={`${style.navArrow} ${style.rightArrow} ${lastRightStyle}`} onClick={this.nextPage}>
                <img src="arrow-nav.png" alt="navigation element, click leads to next panel"></img>
                {rightSpan}
            </div>
        </div>;
    }
}
export default withRouter(SubsectionContentController);