const questions = [
    { // Question 0 - #1 > Compton
        path: "/images/feynman-diagrams/quiz/compton/",
        theory: "qed",
        title: "Compton Scattering",
        question: <span>One of the most fundamental interactions in nature is the (quantum) scattering of light off electrons, known as “Compton scattering”. This is described in QED, where both the initial and final states consist of a photon and electron. <br/>Can you identify all the possible <b>tree-level</b> (= no loops) contributions to the amplitude?<br/>Remember that the diagrams need to follow the Feynman rules of this theory, which are displayed below the "Check Answer" button.</span>,
        correct: [1,2],
        wrongInfo: {
            3: <span>This diagram is incorrect because it contains the exchange of a scalar particle, which is not present in QED.</span>,
            4: <span>This diagram is incorrect because it contains the exchange of a scalar particle, which is not present in QED.</span>,
            5: <span>This diagram is incorrect because it consists of a 4 particle interaction, which is not present in QED.</span>,
            6: <span>While this diagram does represent Compton scattering, it is not at <b>tree-level</b>.</span>
        }
    },
    { // Question 1 -  #2 > Phi 4
        path: "/images/feynman-diagrams/quiz/phi/",
        theory: "phi",
        title: <span>One-loop two scalar scattering</span>,
        question: <span>One could also consider the two to two scattering of particles in scalar 𝝋⁴ theory. <br/>Can you identify all the possible Feynman diagrams which contribute to this process up to <b>one-loop</b>?</span>,
        correct: [1,2,3,4],
        wrongInfo: {
            5: <span>While this diagram does represent two to two scattering in 𝝋⁴ theory, it is at <b>two loops</b>.</span>,
            6: <span>This diagram has 3-point interactions, which are not present in 𝝋⁴ theory.</span>
        }
    },
    { // Question 2 - #3 > BhaBha
        path: "/images/feynman-diagrams/quiz/bhabha/",
        theory: "qed",
        title: "Bhabha Scattering",
        question: <span>Another classic example of a scattering process in QED is Bhabha scattering, which is the scattering of an electron with its antiparticle, the positron.<br/>Can you identify the diagrams which contribute to this process at <b>tree-level</b>?</span>,
        correct: [1,2],
        wrongInfo: {
            3: <span>This diagram is incorrect because it contains the exchange of a scalar particle, which is not present in QED.</span>,
            4: <span>This diagram is incorrect because it contains the exchange of a scalar particle, which is not present in QED.</span>,
            5: <span>This diagram is incorrect because it does not respect charge conservation (direction of the arrows).</span>,
            6: <span>This diagram is incorrect because it consists of a 4-particle interaction, which is not present in QED.</span>
        }
    },
    { // Question 3 - #4 > Epem Annihilation
        path: "/images/feynman-diagrams/quiz/epem-annihilation/",
        theory: "qed",
        title: "Electron Positron Annihilation",
        question: <span>In quantum field theory particles can be created and destroyed. For example in QED an electron and a positron can annihilate to form two photons.<br/>Can you identify all the diagrams which contribute to electron-positron annihilation at <b>tree-level</b>?</span>,
        correct: [1,2],
        wrongInfo: {
            3: <span>This diagram is not correct because it contains an electron-photon-photon vertex, which is not present in QED.</span>,
            4: <span>This diagram is not correct because it both contains four-particle vertices, which are not present in QED, and also is a <b>one-loop</b> diagram.</span>,
            5: <span>This diagram is not correct because it both contains an electron-photon-photon vertex, and also is a <b>one-loop</b> diagram.</span>,
            6: <span>This diagram is not correct because it contains a four-particle vertex.</span>
        }
    },
    { // Question 4 - #5 > Gluon Gluon
        path: "/images/feynman-diagrams/quiz/gluon-gluon/",
        theory: "qcd",
        title: "Gluon Gluon Scattering",
        question: <span>Another central physical theory is Quantum Chromodynamics (QCD), which describes the interaction between quarks (the most fundamental constituents of matter) and gluons (responsible for holding the atomic nuclei together). <br/>The quarks, like electrons, are represented by lines with arrows which show the direction of charge flow. The gluons are represented by springs.<br/>Gluons are strange in that they can interact with themselves via both three- and four-particle vertices (see rules displayed below).<br/>Try to identify all the diagrams which contribute to gluon-gluon scattering at <b>tree-level</b>!</span>,
        correct: [1,2,3,4],
        wrongInfo: {
            5: <span>While this contributes to gluon-gluon scattering, it does so at loop level.</span>,
            6: <span>This diagram is incorrect because it contains only two external gluons, and it is and <b>one-loop</b>.</span>
        }
    },
    { // Question 5 - #6 > 
        path: "/images/feynman-diagrams/quiz/quark-gluon/",
        theory: "qcd",
        title: "Quark Gluon Scattering",
        question: <span>Another simple process in QCD is quark-gluon scattering. Quarks and gluons interacting in the same way as electrons and photons, via a three-particle vertex.<br/>As a final test, can you identify the diagrams which contribute to this process at <b>tree-level</b>?</span>,
        correct: [1,2,3],
        wrongInfo: {
            4: <span>This diagram contains a quark-gluon-gluon vertex, which is not present in QCD.</span>,
            5: <span>This diagram contains a quark-gluon-gluon vertex, which is not present in QCD.</span>,
            6: <span>This diagram contains a quark-quark-gluon-gluon vertex, which is not present in QCD.</span>
        }
    }
];

const rules = {
    phi: [
        {
            name: "Scalar propagator",
            paths: ["/images/feynman-diagrams/Rules/phi/propagator.svg"]
        },
        {
            name: "Interaction",
            paths: ["/images/feynman-diagrams/Rules/phi/rule.svg"]
        }
    ],
    qcd: [
        {
            name: "Gluon propagator",
            paths: ["/images/feynman-diagrams/Rules/qcd/gluon-propagator.svg"]
        },
        {
            name: "Quark propagator",
            paths: ["/images/feynman-diagrams/Rules/qcd/quark-propagator.svg"]
        },
        {
            name: "Interaction",
            paths: [
                "/images/feynman-diagrams/Rules/qcd/rule-1.svg",
                "/images/feynman-diagrams/Rules/qcd/rule-2.svg",
                "/images/feynman-diagrams/Rules/qcd/rule-3.svg"
            ]
        }
    ],
    qed: [
        {
            name: "Electron propagator",
            paths: ["/images/feynman-diagrams/Rules/qed/electron-propagator.svg"]
        },
        {
            name: "Positron propagator",
            paths: ["/images/feynman-diagrams/Rules/qed/positron-propagator.svg"]
        },
        {
            name: "Photon propagator",
            paths: ["/images/feynman-diagrams/Rules/qed/photon-propagator.svg"]
        },
        {
            name: "Interaction",
            paths: ["/images/feynman-diagrams/Rules/qed/rule.svg"]
        }
    ]
}

export {
    questions, rules
};

export default questions;