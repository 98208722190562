import React from 'react';

import conf from '../../config/FeynmanSketchTutorial';

import style from '../../styles/FeynmanDiagrams.module.css';

class SketchpadIntro extends React.Component {

    handleModeChange = () => {
        this.props.onModeChange("sandbox");
    }

    handleNext = () => {
        this.props.onTutorialStep(1);
    }

    handlePrev = () => {
        this.props.onTutorialStep(-1);
    }

    render() {
        const modeIntro = (this.props.mode === "tutorial") ? this.renderTutorialIntro() : this.renderSandboxIntro();
        return modeIntro;
    }

    renderSandboxIntro() {
        return <div className={style.introWrapper}>
            <span>In this sandbox mode you are free to explore the possibilities of Feynman diagrams and apply what you learned in the tutorial. Select the different tools and try to create different interactions for both theories. Check if your created diagram is correct by using the check functionality at the bottom.</span>
            <br/>
            <span>Enjoy the diagram tool!</span>
        </div>;
    }

    renderTutorialIntro() {
        let title = <h1>Tutorial {this.props.tutorialStep+1} of 6</h1>;
        let controls = <div className={style.introControls}>
                <button onClick={this.handlePrev} disabled={this.props.tutorialStep===0}>&lt;&lt; Previous</button><button onClick={this.handleNext}>Next &gt;&gt;</button>
            </div>;
        
        if (this.props.tutorialStep >= (conf.length - 1)) {
            title = null;
            controls = <div className={style.introControls}><button onClick={this.handleModeChange}>Switch to Sandbox mode</button></div>
        }
        
        return <div>
            {title}
            {conf[this.props.tutorialStep].intro}
            {controls}
        </div>;
    }
}
export default SketchpadIntro;