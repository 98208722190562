import React from 'react';

import Propagator from '../../helper/FeynmanGame/Propagator';
import {
    ExternalVertex,
    InternalVertex
    } from '../../helper/FeynmanGame/Vertex';

import GridController from '../../helper/FeynmanGame/GridController';
import PlacementErrors from '../../config/ErrorCodes';

import tutorialConf from '../../config/FeynmanSketchTutorial';

import style from '../../styles/ContentPages.module.css';

class SketchpadBody extends React.Component {
    constructor(props) {
        super(props);

        this.unfinishedPropagator = null;

        this.sketchCanvas = React.createRef();
        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
    }

    componentDidMount() {
        this.updateCanvasProps();
        this.resetGrid();

        window.addEventListener('resize', this.onWindowResize);
        
        this.update();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tool !== this.props.tool) {
            // New Tool selected, reset current propagator
            this.unfinishedPropagator = null;
        }

        if (this.props.mode === "tutorial") {
            if (prevProps.tutorialStep !== this.props.tutorialStep) {
                this.clearCurrentDiagram();
            }
        }
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.requestID);
        window.removeEventListener("resize", this.onWindowResize);
    }

    
    updateCanvasProps = () => {
        this.dpr = window.devicePixelRatio || 1;

        // Get current dpr adjusted width / height of DOM element
        this.width = this.sketchCanvas.current.clientWidth * this.dpr;
        this.height = this.sketchCanvas.current.clientHeight * this.dpr;

        // Set Canvas resolution based on width / height
        this.sketchCanvas.current.width = this.width;
        this.sketchCanvas.current.height = this.height;
        
        this.ctx = this.sketchCanvas.current.getContext("2d");
    }

    resetGrid = () => {
        this.grid = new GridController(this.width, this.height, this.dpr);
        this.addTutorialTemplate();
    }

    addTutorialTemplate() {
        if (this.props.mode === "tutorial") {
            let template = tutorialConf[this.props.tutorialStep].preplacedVertices;
            for (let i=0; i<template.length; i++) {
                this.grid.addVertexTemplate(template[i], i, this.props.theory);
            }
        }
    }

    clearObjects() {
        this.propagators.length = 0;
        this.unfinishedPropagator = null;
    }

    clearErrorHighlight() {
        this.grid.currentErrorID = null;
    }

    checkCurrentDiagram = () => {
        let checkResult = this.grid.checkFeynmanRules();
        if (checkResult.isValid) {
            alert("Congratulations! Everything looks correct!");
        } else {
            this.props.onErrorMessage(checkResult.error);
        }
    }

    clearCurrentDiagram = () => {
        this.grid.removeEverything();
        this.addTutorialTemplate();
    }

    onMouseDown = (ev) => {
        const mousePos = this.getMousePos(ev);
        const gridPos = this.grid.getGridPos(mousePos);

        if (!this.grid.checkValidityOfPosition(gridPos)) {
            return;
        }

        const gridPixelPos = this.grid.getGridPixelPos(mousePos);
        const vertexIDAtMousePos = this.grid.getVertexIDAtGridPosition(mousePos);
        
        switch (this.props.tool) {
            case null:
                this.props.onErrorMessage(PlacementErrors.NO_TOOL_SELECTED);
                break;
            case "externalVertex":
                if (vertexIDAtMousePos) {
                    this.props.onErrorMessage(PlacementErrors.NON_EMPTY_VERTEX_POSITION);
                } else {
                    this.grid.addVertex(
                        mousePos, 
                        new ExternalVertex(gridPixelPos, this.props.theory)
                    );
                    if (this.props.mode === "tutorial") {
                        this.props.onGridChange([this.props.tool], true);
                    }
                }
                break;
            case "internalVertex":
                if (vertexIDAtMousePos) {
                    this.props.onErrorMessage(PlacementErrors.NON_EMPTY_VERTEX_POSITION);
                } else {
                    this.grid.addVertex(
                        mousePos, 
                        new InternalVertex(gridPixelPos, this.props.theory)
                    );
                    if (this.props.mode === "tutorial") {
                        this.props.onGridChange([this.props.tool], true);
                    } 
                }
                break;
            case "delete":
                let deleteCheckResult = this.grid.tryRemoveAtMousePos(mousePos);
                if (deleteCheckResult === false) {
                    this.props.onErrorMessage(PlacementErrors.NO_SELECT_DELETE);
                } else if (this.props.mode === "tutorial" && deleteCheckResult) {
                    this.props.onGridChange(deleteCheckResult, false);
                }
                break;
            default: // Handles all kinds of propagators
                if (!vertexIDAtMousePos) {
                    this.props.onErrorMessage(PlacementErrors.EMPTY_PROPAGATOR_POSITION);
                } else if (!this.unfinishedPropagator) {
                    this.unfinishedPropagator = new Propagator(
                        gridPixelPos, 
                        this.props.tool,
                        vertexIDAtMousePos,
                        this.grid.cellSize
                    );
                } else {
                    if (this.unfinishedPropagator.startVertexID !== vertexIDAtMousePos) {
                        this.unfinishedPropagator.setEndPoint(gridPixelPos, vertexIDAtMousePos);
                        this.grid.addPropagator(this.unfinishedPropagator);
                        this.unfinishedPropagator = null;
                        
                        if (this.props.mode === "tutorial") {
                            this.props.onGridChange([this.props.tool], true);
                        }
                    } else {
                        this.props.onErrorMessage(PlacementErrors.ZERO_LENGTH_PROPAGATOR);
                    }
                }
                break;
        }
    }

    onMouseMove = (ev) => {
        let mousePos = this.getMousePos(ev);
        let gridPos = this.grid.getGridPos(mousePos);
        let gridPixelPos = this.grid.getGridPixelPos(mousePos);

        if (this.grid.checkValidityOfPosition(gridPos)) {
            this.cursor = gridPixelPos;
            if (this.unfinishedPropagator) {
                this.unfinishedPropagator.setEndPoint(gridPixelPos);
            }
        } else {
            this.cursor = null;
            this.unfinishedPropagator = null;
        }
    }

    getMousePos(ev) {
        const {clientX, clientY} = ev.touches ? ev.touches[0] : ev;
        const rect = this.sketchCanvas.current.getBoundingClientRect()
        return {
            x: (clientX - rect.left) * this.dpr,
            y: (clientY - rect.top) * this.dpr
        };
    }

    update = () => {
        this.ctx.clearRect(0, 0, this.width, this.height);

        this.grid.draw(this.ctx, this.props.tool);

        if (this.unfinishedPropagator) {
            this.unfinishedPropagator.draw(this.ctx, true, false);
        }

        this.grid.drawCursor(this.ctx, this.cursor);

        this.requestID = requestAnimationFrame(this.update);
    }

    onWindowResize = () => {
        this.updateCanvasProps();
        this.resetGrid();
        this.props.onGridChange();
    };

    render() {
        return <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`}>
            <canvas
                style={{height:"100%", width:"100%"}}
                ref={this.sketchCanvas} 
                onMouseMove={this.onMouseMove} 
                onMouseDown={this.onMouseDown} >
            </canvas>
        </div>;
    }
}
export default SketchpadBody;