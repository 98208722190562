import React from 'react';

import SketchpadTools from './SketchpadTools';

import style from '../../styles/FeynmanDiagrams.module.css';

import tutorialConf from '../../config/FeynmanSketchTutorial';

class SketchpadToolbox extends React.Component {
    
    handleTheoryChange = (ev) => {
        this.props.onTheoryChange(ev.target.value)
    }

    handleDeleteAllClick = () => {
        this.props.onControlButtonAction("delete-all");
    }

    handleCheckClick = () => {
        this.props.onControlButtonAction("check");
    }
  
    render() {
        const disableTheorySelection = (this.props.mode === "tutorial" && tutorialConf[this.props.tutorialStep].parts.theory !== "");
        return <div className={style.toolboxWrapper}>
            <div className={style.theorySelector}>
                <label>Theory</label>
                <select name="theory" value={this.props.theory} onChange={this.handleTheoryChange} disabled={disableTheorySelection}>
                    <option value="qed">QED</option>
                    <option value="phi_4">𝝋⁴</option>
                </select>
            </div>
            <SketchpadTools 
                theory={this.props.theory}
                tool={this.props.tool}
                onToolChange={this.props.onToolChange}
                mode={this.props.mode}
                tutorialStep={this.props.tutorialStep}
                toolAvailabilityChangeFlag={this.props.toolAvailabilityChangeFlag} 
                toolAvailabilityChange={this.props.toolAvailabilityChange} 
            />
            <button className={`${style.sketchpadControlButtons} ${style.checkButton}`} onClick={this.handleCheckClick}>
                <span>Check Diagram</span>
            </button>
            <button className={`${style.sketchpadControlButtons} ${style.resetButton}`} onClick={this.handleDeleteAllClick}>
                <span>Reset Diagram</span>
            </button>
        </div>;
    }
}
export default SketchpadToolbox;