import React from 'react';

import * as THREE from 'three';
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";

// Helper
import AmplituhedronFaces from '../helper/AmplituhedronFaceTitles'
import OBJModelController from '../helper/OBJModelController';
import SceneManager from '../helper/SceneManager';

// styles
import style from "../styles/ExhibitionHub.module.css";

import conf from "../config/AmplituhedronFaceRotations";

const Hammer = require('hammerjs');

class AmplituhedronExplorer extends React.Component {
    constructor(props) {
        super(props);

        this.selectTopicByFaceClick = false;

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);

        this.inAnimation = false;
    }

    componentDidMount() {
        this.sceneManager = new SceneManager(this.threeMount);

        // Setup Controls
        this.controls = new TrackballControls(this.sceneManager.camera, this.sceneManager.threeMount);
        this.controls.rotateSpeed = 2.0;
        this.controls.noPan = true;
        this.controls.noZoom = true;
        this.controls.noRoll = true;
        this.controls.staticMoving = true;

        this.hammerManager = new Hammer.Manager(this.sceneManager.threeMount);
        this.hammerManager.add(new Hammer.Rotate());
        this.hammerManager.on("rotate", this.onRotate);
        this.cameraZAngle = 0;

        this.addCustomSceneObjects();

        window.addEventListener('resize', this.onWindowResize);
        this.sceneManager.renderer.domElement.addEventListener('mousedown', this.onMouseDown, false);
        this.sceneManager.renderer.domElement.addEventListener('touchstart', this.onMouseDown, false);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
        window.cancelAnimationFrame(this.requestID);
        this.controls.dispose();
    }

    componentDidUpdate(oldProps) {
        if (this.props.currentListTopic && this.props.currentListTopic !== oldProps.currentListTopic) {
            this.animationStart = performance.now();
            this.inAnimation = true;
            this.controls.enabled = false;
            this.initialCamPosition = this.sceneManager.camera.position.clone();
        }

        if (!this.props.currentListTopic) {
            this.inAnimation = false;
            this.controls.enabled = true;
            if (this.initialCamPosition) {
                this.sceneManager.camera.position.x = this.initialCamPosition.x;
                this.sceneManager.camera.position.y = this.initialCamPosition.y;
                this.sceneManager.camera.position.z = this.initialCamPosition.z;
                this.initialCamPosition = null;
            }
        }
    }

    addCustomSceneObjects = () => {
        // Prepare Amplituhedron
        this.amplituhedron = new OBJModelController(
            "/meshes/SAGEX_Amplituhedron_NewUV.obj",
            "/textures/amplituhedron_newuv_tex.png"
        );

        // Load Object
        this.amplituhedron.loadObjPromise().then(() => {
            // All Objects loaded, set values
            this.amplituhedron.setScale(1.75);
            this.amplituhedron.setRotation(0, 180, 0);
            this.amplituhedron.setFaceTitles(AmplituhedronFaces);

            this.generateHull();
            this.sceneManager.add(this.amplituhedron.obj);

            this.update();
        }, () => {
            console.log("Some objects could not be loaded!");
        });
    };

    update = () => {
        if (this.inAnimation && this.props.currentListTopic) {
            let p = (performance.now() - this.animationStart) / 1000;
            this.sceneManager.camera.quaternion.slerp(conf.quaternions[this.props.currentListTopic], p);
            this.sceneManager.camera.position.lerp(conf.positions[this.props.currentListTopic], p);
        } else {
            this.controls.update();

            // Automatic Front Face Raycast:
            this.amplituhedron.updateFrontFace(this.sceneManager.camera);
            this.tryUpdateTitle(this.amplituhedron.frontFace);
        }
        
        this.sceneManager.render();

        this.requestID = window.requestAnimationFrame(this.update);
    }

    generateHull() {
        let obj = this.amplituhedron.obj.clone();
        let geometry = new THREE.Geometry();
        this.amplituhedron.setHighlightHull(geometry.fromBufferGeometry(obj.geometry));
    }

    onMouseDown = (event) => {
        this.clickStart = event.timeStamp;
        this.clickCameraPos = this.sceneManager.camera.position.clone();
    }

    onMouseUp = (event) => {
        // Abort if mouseUp was triggered by a drag and not a Tap / Click
        if (!this.clickStart || event.timeStamp - this.clickStart > 200
            || (this.clickCameraPos.angleTo(this.sceneManager.camera.position) * (180 / Math.PI)) > 2) {
            return;
        }
        // Reset Click Timer
        this.clickStart = null;

        if (!this.selectTopicByFaceClick) {
            // Topic is selected by clicking a specific face

            // Calculate correct pixel position
            const rect = this.sceneManager.renderer.domElement.getBoundingClientRect();
            const x = ((event.touches) ? event.changedTouches[0] : event).clientX - rect.left;
            const y = ((event.touches) ? event.changedTouches[0] : event).clientY - rect.top;

            // Calculate relative position
            var mouse = new THREE.Vector2();
            mouse.x = (x / this.sceneManager.width) * 2 - 1;
            mouse.y = - (y / this.sceneManager.height) * 2 + 1;

            let selectedTopic = this.amplituhedron.getClickedFace(this.sceneManager.camera, mouse);
            if (selectedTopic) {
                // Set clicked face as topic (if new topic)
                if (this.props.currentTopic !== selectedTopic) {
                    this.props.onTopicChange(selectedTopic);
                } else { // Select topic (if same face)
                    this.props.onSelectTopic();
                }
            }
        } else if (this.props.currentTopic) {
            // Topic is selected based on current front face
            this.props.onTopicChange(this.props.currentTopic);
        }
    }

    onRotate = (ev) => {
        switch (ev.eventType) {
            case 1:
                this.startEvRotation = ev.rotation;
                this.currentRotation = 0;
                break;
            case 2:
                let newRotation = ev.rotation - this.startEvRotation;
                let change = (newRotation - this.currentRotation) * Math.PI / 180;

                let axis = this.sceneManager.camera.position.clone();
                axis.normalize();

                this.amplituhedron.obj.rotateOnWorldAxis(axis, -change);
                this.currentRotation = newRotation;
                break;
            default: // Gesture canceled or ended
                this.startEvRotation = 0;
                this.currentRotation = 0;
                break;
        }
    }

    tryUpdateTitle(newTopic) {
        if (this.props.currentTopic !== newTopic) {
            this.props.onTopicChange(newTopic);
        }
    }

    onWindowResize = () => {
        this.sceneManager.handleResize();
    };

    render() {
        return <div className={style.explorerWrapper} ref={(threeMount) => { this.threeMount = threeMount }}
            onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp}>
        </div>;
    }
}
export default AmplituhedronExplorer;