import React from 'react';

import Video from '../../components/Video';

import PBForceGame from '../../components/PBForceGame/PBForceGame';
import PBHeisenberg from '../../components/PBHeisenbergSlider';
import PBParticleShooterPanel from '../../components/PBParticleShooter/PBParticleShooterPanel';

import style from '../../styles/ContentPages.module.css';

const panels = [
    {
        sectionTitle: "Introduction",
        content: 
            <div className={style.displayContent}>
                <Video name="physical-basics-0" />
                <p>
                    As physicists, our goal is to understand the fundamental laws of nature. How do particles, our fundamental building blocks, interact or tie together to form our universe?” To answer this question, we need to address three key concepts:
                </p>
                <ul>
                    <li>What are these particle interactions?</li>
                    <li>How can we test and explore their properties?</li>
                    <li>How do we describe interactions and predict experimental results?</li>
                </ul>
            </div>
    },
    {
        sectionTitle: "Interactions",
        content: 
            <div className={style.displayContent}>
                <p>
                    What are interactions? Think of your phone as a particle, you have Whatsapp and use it to contact your friend John to have a chat. You are having a great time and John decides to add his friend Mary to the conversation. Mary however does not have Whatsapp but only Skype, which is how she usually calls John. In other words, you can interact with John who can talk to both you and Mary, but there is no way for you to get in touch with Mary.
                </p>
                <p>
                    The particle equivalents of messaging apps are called charges. One that you might be familiar with is the electromagnetic charge. Only particles with the same type of charge can ‘talk’ to each other, and when they do so they generate a force. Information about this force is carried from one particle to the other by a ‘mediator’. This can also be thought of as another particle. For example, electromagnetic charge generates electromagnetic forces. Here, the mediator is a photon, which you might know as light.
                </p>
                <p>
                    In the following game there are particles with two charges, a yellow one and a blue one, falling into a grid. Will you be able to get every particle in the right hole by changing the direction of the forces in the grid? Be wary of gravity, it affects all particles and increases after each round making them fall faster!
                </p>
                <PBForceGame />
                <p>
                    In nature we know of four different forces, each with their own charge and mediator. The electromagnetic force holds atoms together, binding electrons to the nucleus. The mediator is the photon. The weak force is mediated by W and Z bosons, and it is involved in the decay of nuclei. The strong force ties together quarks to form protons and neutrons, it is mediated by gluons and the associated charge is called colour charge. Finally, the most familiar to us, is the gravitational force that binds us to the Earth, and the Earth in orbit around the sun. The gravitational ‘charge’ is mass, and the mediator is called the graviton.
                </p>
                <p>
                    Theoretical physicists dream of a single unifying description of all forces, just as electromagnetism unified the description of both electric and magnetic phenomena. This has been accomplished for the electromagnetic and weak force, in so called electroweak interactions which prompted a Nobel prize in 1979. On the other hand the strong force and gravity still present many puzzles even in their standalone description, and they might be considered as the hardest interactions to describe.
                </p>
            </div>
    },
    {
        sectionTitle: "Scattering Experiments",
        content: 
            <div className={style.displayContent}>
                <p>
                    If our theory is a good description of reality it should predict the outcome of the scattering experiment. If it doesn’t, we should correct or even discard it
                </p>
                <p>
                    In the following game you will control a “marble shooter”, you can move it freely left and right, while it shoots at a hidden geometric figure. Along with the shooter you’ll get 4 different shape predictions from four theories, will you be able to select the correct theory by looking at the outcome of the scattering? 
                </p>
                <PBParticleShooterPanel />
                <p>
                    The very same idea behind the game you just played led to one of the most famous scattering experiments of the 20th century: the Geiger-Marsden experiment (or Rutherford’s goldfoil experiment). This was used in 1908-1913 to test contemporary models of the atomic structure, in particular Thomson’s “plum pudding model”, which was found to be wrong. Following the experimental results a new theory was devised, Rutherdford’s model, which included a radically new feature: the atomic nucleus.
                </p>
                <p>
                    However, there is a very big difference between our scattering game and real particle collisions. The laws governing our game are the same as those governing the collisions of billiard balls on a pool table. Only classical ‘everyday-life’ mechanics is involved, so we call this a classical scattering. Since real particles are so small, they must obey the laws of quantum mechanics. So, next we need a quantum description of scattering…
                </p>
            </div>
    },
    {
        sectionTitle: "Scattering Amplitudes",
        content: 
            <div className={style.displayContent}>
                <p>
                    Quantum mechanics is a broad and deep subject, so much so that to this very day the physical (or sometimes really philosophical) interpretation of some of its axioms and phenomena is still debated, as opposed to the solidity of its mathematical predictions.
                </p>
                <Video name="physical-basics-1" />
                <p>
                    The quantum world has many peculiarities, the discussion of which would require a whole exhibition of its own. Just to mention one of them, there are pairs of variables that cannot be measured at the same time with infinite precision. Such variables are called conjugate, and one example is the position and momentum of a particle. Imagine that the colour and the shape of an object are conjugate. You can then use the slider below to explore how this looks. If you have complete knowledge of shape, you can only have partial knowledge of colour and vice versa. Every position of the slider represents a simultaneous measurement.
                </p>
                <PBHeisenberg />
                <p>
                    When particle collisions happen at very high energies, we also have to think about Special Relativity. This theory recognises that many things in our daily lives that seem fixed, or absolute, are actually not. The flow of time or the size of an object for example can appear different to different observers. Here, it is enough to stress that Special Relativity is also an important ingredient in Quantum Field Theory. But with all of these complications, how do we actually predict the outcome of a particle collision?
                </p>
                <p>
                    But how do we predict the outcome of a particle collision then?
                </p>
                <Video name="physical-basics-2" />
                <p>
                    Explore the rest of our site to find out about scattering amplitudes, from well-established methods to cutting-edge techniques.
                </p>
        </div>
    }
];

const content = {
    title: "Physical Basics",
    color: "rgb(255,255,255)",
    backgroundColor: "rgba(24,24,24,0.8)",
    panels: panels
}

export default content;