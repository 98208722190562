import React from 'react';

// Styles
import style from '../../styles/FeynmanQuiz.module.css';
import FQSummaryGrid from './FQSummaryGrid';

class FQSummary extends React.Component {
    render() {
      return <div className={style.summaryWrapper}>
          <h1>Summary</h1>
          <p>Here you can see how well you performed in the quiz. A question can grant a maximum of 6 points that can be achieved by selecting all correct answers for the first check. Every additional check subtracts a point, leading to a minimum of 1 point for a question that was successfully answered after a high number of checks or zero for a wrongly answered question.</p>
          <FQSummaryGrid questionStates={this.props.questionStates} points={this.props.points} />
          <p>If you want to try to improve your score, you can reset the quiz:</p>
          <button className={`${style.quizButton} ${style.resetButton}`} onClick={this.props.onReset}>Reset Quiz</button>
      </div>;
    }
}
export default FQSummary;