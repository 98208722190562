import React from 'react';

// Components
import BeyondFeynmanPuzzle from './BeyondFeynmanPuzzle';

// Styles
import baseStyle from '../../styles/ContentPages.module.css';

class BFPuzzlePanel extends React.Component {

    render() {
        return <div className={baseStyle.displayContent}>
            <div className={`${baseStyle.mediaWrapper} ${baseStyle.largeMediaContent}`}>
                <BeyondFeynmanPuzzle />
            </div>
            <p>
                The game does <b>not</b> notify you if a correct tiling is completed. As the puzzle scales with screen size, 
                it works best on a larger screen as the pieces get too small on devices such as smart phones. For the best experience
            </p>
            <p>
                Finding all coverings is not easy. You can help yourself starting from
                the two examples in the end of the previous page which you will find again below the puzzle. Try to modify them and
                see if you find out other tilings.
            </p>
            <details>
                <summary>Click to show two example solutions</summary>
                <div className={`${baseStyle.mediaWrapper} ${baseStyle.mediumMediaContent}`} >
                    <img className={baseStyle.mediaContent} alt="" src="/images/beyond-feynman/panel-5/5.png"></img>
                </div>
            </details>
        </div>;
    }
}
export default BFPuzzlePanel;