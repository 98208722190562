import React from 'react';

// Components
import SolitonGame from './SolitonGame';
import SolitonGameControls from './SolitonGameControls';

class SolitonGamePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAnimating: false,
      resetAnimationFlag: false
    }
  }

  onCollision = () => {
    if (this.state.isAnimating) {
      this.setState({isAnimating: false});
    } 
  }

  onUserUpdate = (action) => {
    switch (action) {
      case "start" :
        this.setState({isAnimating: true});
        break;
      case "reset" :
        this.setState({
          resetAnimationFlag: !this.state.resetAnimationFlag,
          isAnimating: false
        });
        break;
      default:
        console.log("Unsupported User command!");
        break;
    }
  }

  render() {
    return <div>
        <SolitonGame isAnimating={this.state.isAnimating} onCollision={this.onCollision} resetAnimationFlag={this.state.resetAnimationFlag}/>
        <SolitonGameControls onUserUpdate={this.onUserUpdate} isAnimating={this.state.isAnimating} />
    </div>;
  }
}
export default SolitonGamePanel;