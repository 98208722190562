import React from 'react';

import style from '../styles/Team.module.css';

class TeamMemberProfile extends React.Component {

    render() {
        const path = "/images/team/"+(this.props.url ? this.props.url : "placeholder.png");
        return <div className={style.teamMember}>
            <div className={style.avatarContainer}>
                <img src={path} alt={"Picture of "+this.props.name}></img>
            </div>
            <span className={style.memberName}>{this.props.name}</span>
            <span className={style.memberRole}>{this.props.role}</span>
        </div>;
    }
}

export default TeamMemberProfile;