import ToolboxCell from './BFToolboxCell';
import BFParallelogram from './BFParallelogram';
import BFTriangle from './BFTriangle';

export default class BFToolbox {
    constructor(dim) {
        this.cellSize = dim / 4;

        let data = [
            { shape: new BFTriangle("L_m1", "L_m1", "L_m3"), count: 2 },
            { shape: new BFTriangle("L_m1", "L_m3", "L_m4"), count: 1 },
            { shape: new BFTriangle("L_m4", "L_m1", "L_m4"), count: 2 },
            { shape: new BFTriangle("L_m1", "L_m2", "L_m3"), count: 2 },
            { shape: new BFTriangle("L_m1", "L_m4", "L_m5"), count: 2 },
            { shape: new BFTriangle("L_m1", "L_m1", "L_m2"), count: 1 },
            { shape: new BFTriangle("L_m3", "L_m1", "L_m5"), count: 2 },
            { shape: new BFTriangle("L_m2", "L_m2", "L_m5"), count: 2 },
            { shape: new BFTriangle("L_m1", "L_m2", "L_m4"), count: 2 },
            { shape: new BFTriangle("L_m2", "L_m2", "L_m1"), count: 1 },
            { shape: new BFTriangle("L_m3", "L_m3", "L_m2"), count: 2 },
            { shape: new BFTriangle("L_m2", "L_m2", "L_m4"), count: 1 },
            { shape: new BFTriangle("L_m1", "L_m1", "L_m1"), count: 2 },
            { shape: new BFParallelogram("L_m2", "L_m1", 2 * Math.PI/30), count: 1 },
            { shape: new BFParallelogram("L_m2", "L_m1", 10 * Math.PI/30), count: 1 },
            { shape: new BFParallelogram("L_m1", "L_m1", 8 * Math.PI/30), count: 1 }
        ];

        this.grid = [];
        for (let i=0; i<4; i++) {
            for (let j=0; j<4; j++) {
                let entry = data[i*4+j];
                this.grid.push(new ToolboxCell(
                    entry.shape,
                    entry.count,
                    j, i
                ));
            }
        }
    }

    draw (ctx, shapeSelected) {
        this.grid.forEach(cell => {
            cell.draw(ctx, this.cellSize);
        });

        if (shapeSelected) {
            ctx.save();
            // Gray Overlay
            ctx.globalAlpha = 0.5;
            ctx.fillStyle = "#ccc";
            ctx.beginPath();
            ctx.rect(this.cellSize*4, 0, this.cellSize*4, this.cellSize*4);
            ctx.fill();

            // Text
            ctx.translate(6*this.cellSize, 2*this.cellSize);
            ctx.fillStyle = "#000";
            ctx.globalAlpha = 1;
            ctx.font = 0.2*this.cellSize+"px Arial";
            ctx.textAlign = "center";
            ctx.fillText("Drop here to put back to Toolbox", 0, 0);

            ctx.restore();
        }
    }

    addPiece(shapeId) {
        this.grid.forEach(cell => {
            if (cell.shapeId === shapeId) {
                cell.count++;
            }
        });
    }

    checkClick(mousePos) {
        let result = false;
        if (mousePos.x > 4 * this.cellSize) {
            this.grid.forEach(cell => {
                if (cell.checkClick(mousePos, this.cellSize) && cell.count > 0) {
                    cell.count --;
                    result = cell.shape.getClone();
                }
            });
        }
        return result;
    }

    resize(newDim, resizeScale) {
        this.cellSize = newDim / 4;
        this.grid.forEach(cell => {
            cell.resize(resizeScale);
        });
    }
}