import React from 'react';

// Components
import FQAnswerGrid from './FQAnswerGrid';
import FQSummary from './FQSummary';

// Styles
import style from '../../styles/FeynmanQuiz.module.css';

// Config
import questions from '../../config/FeynmanQuizConfig';

class FQQuestionWrapper extends React.Component {

    onAnswerSelectToggle = (id, isSelected) => {
        this.props.onAnswerSelectToggle(id, isSelected);
    }

    renderQuestion(question) {
        const paragraphs = question.question;
        const solved = this.props.questionStates[this.props.currentQuestion] === "success";
        
        return <div className={style.questionWrapper}>
            <div className={style.questionIntroduction}>
                <h1 className={style.questionTitle}>{question.title}</h1>
                <div>{paragraphs}</div>
                <div className={style.blobImageWrapper}><img alt="" src={question.path+"blob.svg"}></img></div>
                <div className={style.taskDescription}>
                    Select the diagrams from the suggestions below that correctly fill the center of the diagram above. 
                    Clicking on an option selects or deselects it. Click the "Check Answer" button below the suggestions
                    if you think you selected all correct answers.
                    <br/>
                    Below the button you will also find a selection of the applicable rules for Feynman diagrams relevant for the question.
                </div>
            </div>
            <FQAnswerGrid currentQuestion={this.props.currentQuestion} path={question.path} 
                onAnswerSelectToggle={this.onAnswerSelectToggle} solved={solved}/>
        </div>;
    }

    renderSummary() {
        return <div className={style.questionWrapper}>
            <FQSummary questionStates={this.props.questionStates} points={this.props.points} onReset={this.props.onReset} />
        </div>;
    }

    render() {
        const question = questions[this.props.currentQuestion];
        if (question) {
            return this.renderQuestion(question);
        } else {
            return this.renderSummary();
        }
    }
}
export default FQQuestionWrapper;