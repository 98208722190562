import React from 'react';

import SolitonGamePanel from '../../components/SolitonGame/SolitonGamePanel';
import Video from '../../components/Video';

import style from '../../styles/ContentPages.module.css';

const panels = [
    {
        sectionTitle: "What are Solitons?",
        content : 
            <div className={style.displayContent}>
                <p>
                    While in the vast majority of physical scenarios we cannot compute scattering amplitudes exactly, there are a 
                    number of models where this is actually achieved. One example is the scattering between objects called solitons - 
                    but what are solitons? Watch the video to find out!
                </p>
                <Video name="solitons-1" />
            </div>
    },
    {
        sectionTitle: "A little bit of soliton history",
        content:
            <div className={style.displayContent}>
                <p>
                    Solitons are very special wave packets, for example water waves, that do not change their shape unless they hit each other. When they do, you initially seem to get a mess. But then the solitons escape from the collision entirely unscathed! This is what we have just seen in the movie.
                </p>
                <div className={`${style.mediaWrapper} ${style.smallMediaContent}`}>
                    <img className={style.mediaContent} src="/gifs/single_soliton.gif" alt=""></img>
                </div>
                <p>
                    You might think all this is very unphysical: If you throw a stone into a lake, the created waterwaves get smaller and smaller as they spread and will eventually vanish, so they don’t behave like solitons at all. But in fact solitons exist in nature! They were first observed and described by the Scottish engineer and shipbuilder John Scott Russellin 1834, who published an account of the event in his <a href="https://www.macs.hw.ac.uk/~chris/Scott-Russell/SR44.pdf" target="_blank" rel="noopener noreferrer">“Report on Waves”</a> in the following words:
                </p>
                <cite>
                    <img src="/images/soliton_2.jpg" alt="" className={style.floatingImage}></img>
                    I was observing the motion of a boat which was rapidly drawn along a narrow channel by a pair of horses, when the boat suddenly stopped - not so the mass of water in the channel which it had put in motion; it accumulated round the prow of the vessel in a state of violent agitation, then suddenly leaving it behind, rolled forward with great velocity, assuming the form of a large solitary elevation, a rounded, smooth and well-defined heap of water, which continued its course along the channel apparently without change of form or diminution of speed. I followed it on horseback, and overtook it still rolling on at a rate of some eight or nine miles an hour [...] Such, in the month of August 1834, was my first chance interview with that singular and beautiful phenomenon which I have called the Wave of Translation.
                </cite>
                <p style={{clear: "both"}}>
                    What Russell introduced as the “Wave of Translation” is today known under the name “soliton”. And while he described a single soliton above, it is even more interesting to consider their interaction. Find out what happens when two solitons meet by playing the <a href="/#/exhibition-hub/Solitons/2">following game</a>.
                </p>
                <div className={`${style.mediaWrapper} ${style.largeMediaContent}`}>
                    <img className={style.mediaContent} src="/images/soliton_1.png" alt=""></img>
                </div>
                <figcaption className={`${style.imageCaption} ${style.centerText}`}>
                    Sketches of solitons from Russell’s <a href="https://www.macs.hw.ac.uk/~chris/Scott-Russell/SR44.pdf" target="_blank" rel="noopener noreferrer">“Report on Waves” (1844)</a>
                </figcaption>
            </div>
        
    },
    {
        sectionTitle: "Soliton Game",
        content: 
            <div className={style.displayContent}>
                <p>
                    Alice and Bob are standing on separated platforms in a canal. As it is a very hot day, Alice
                    would like to send Bob a soliton of refreshing water. Too bad that there is a rather low-hanging
                    bridge between them! Is there any way that Alice can send Bob a nice, big soliton taller than the
                    bridge? Help her with the following trick: try to send a smaller soliton along with the big one,
                    such that their combination is able to sneak under the bridge. After this is achieved, the two
                    solitons will once again disentangle and Bob will get the big splash of water as Alice intended!
                </p>
                <p className={style.centerText}>
                    <i>
                        Drag and drop the smaller soliton to the desired position and click the Animate button to send
                        the solitons on their way.
                    </i>
                </p>
                <SolitonGamePanel />
                <p>
                    This game was first <a href="https://maple.cloud/app/6320205578371072/AliceBob2" target="_blank" rel="noopener noreferrer">developed in Maple</a>&nbsp; 
                    which is a symbolic programming language used in many research communities such as SAGEX to analyse, 
                    explore, visualise, and solve mathematical problems.
                </p>
                
            </div>
            
    },
    {
        sectionTitle: "Soliton scattering and integrability",
        content: 
            <div className={style.displayContent}>
                <p>
                    In the game, you have just learned about a further peculiar feature of solitons: when two of them overtake each other, their waveforms do not simply add up, but instead have a more complicated shape. Nevertheless, after the interaction, the solitons reacquire their original waveform. With your help, this allowed Alice to get a large soliton past a low bridge.
                </p>
                <div className={`${style.mediaWrapper} ${style.smallMediaContent}`}>
                    <img className={style.mediaContent} src="/gifs/double_soliton.gif" alt=""></img>
                </div>
                <p>
                    For a physicist this type of interaction is a sign of “integrability”: the shape of wave packets, after an interaction has taken place, remains intact. This feature is not only found in Russell’s solitons in the Union Canal, but in many theoretical models of physics. An important example is the scattering of fundamental particles: here integrable structures sometimes appear as well, helping us to solve a number of highly non-trivial problems. In fact, this is one of the focal research areas of SAGEX.
                </p>
            </div>
    }
];

const content = {
    title: "Solitons",
    color: "rgb(255,255,255)",
    backgroundColor: "rgba(24,24,24,0.8)",
    panels: panels
}

export default content;