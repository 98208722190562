import React from 'react';

// Styles
import style from '../../styles/ContentPages.module.css';

class SolitonGameControls extends React.Component {
    onAnimate = () => { 
      this.props.onUserUpdate("start");
    }

    onReset = () => {
      this.props.onUserUpdate("reset");
    }

    render() {
      return <div className={`${style.displayContent} ${style.centerText}`}>
        <button className={style.gameButton} onClick={this.onAnimate} disabled={this.props.isAnimating}>Animate!</button>
        <span> - </span>
        <button className={style.gameButton} onClick={this.onReset}>Reset</button>
      </div>;
    }
}
export default SolitonGameControls;