import React from 'react';

import Video from '../../components/Video';

import BFPuzzle from '../../components/BeyondFeynmanPuzzle/BeyondFeynmanPuzzle';
import BFPuzzlePanel from '../../components/BeyondFeynmanPuzzle/BFPuzzlePanel';
import BFPentagonTesselation from '../../components/BFTesselation/BFPentagonTesselation';
import BFHexagonTesselation from '../../components/BFTesselation/BFHexagonTesselation';

import style from '../../styles/ContentPages.module.css';

const panels = [
    {
        sectionTitle : "Introduction",
        content : 
            <div className={style.displayContent}>
                <p>
                    The first method developed to compute scattering amplitudes are Feynman diagrams,
                    which you have already seen. While they give a very nice visual for the way particles
                    interact, current experiments have reached a point where it is practically impossible to
                    compute the required amplitudes using Feynman diagrams due to their sheer number.
                </p>
                <p>
                    In the last 30 years an enormous amount of progress has been made in the development of
                    new techniques and ideas that sidestep the computation of Feynman diagrams and allow us
                    to directly study the simple and elegant structure underlying scattering amplitudes.
                    One example of such structure is the amplituhedron, a geometrical object which was the
                    origin for the SAGEX logo. One of the SAGEX students wrote a <a href="https://blog.wolfram.com/2019/11/21/on-the-polygon-front-lines-visualizing-the-amplituhedron-with-the-wolfram-language/" target="_blank" rel="noopener noreferrer">blog article</a>, where you can
                    find out more about it.
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="the amplituhedron" src="/images/beyond-feynman/intro.png">
                    </img>
                </div>
                <figcaption className={`${style.imageCaption} ${style.centerText}`}>
                    The image of a face of the amplituhedron (<a href="https://arxiv.org/abs/1312.2007" target="_blank" rel="noopener noreferrer">original source</a>) that inspired the SAGEX logo
                </figcaption>
                <p>
                    We show here three different examples of properties hidden by the Feynman diagram
                    formulation, which have changed the way we think about amplitudes.
                </p>
            </div>
    },
    {
        sectionTitle : "Amplitudes from Physical Principles",
        content : 
            <div className={style.displayContent}>
                <p>
                    The first indication that there is something deeper behind amplitudes came out of the need of calculating processes for particle colliders. In the 1980s, Parke and Taylor studied the simplest scattering amplitude at the tree level for gluons, which are the particles mediating the strong force — the same force that binds together protons and neutrons in atomic nuclei. The amplitude describing the interaction of six gluons was derived using Feynman diagrams, and after a long and arduous computation, a miracle happened: hundreds of pages of complex algebra collapsed into an expression that fits on a single line.
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-2/sun.png"></img>
                </div>
                <p>
                    This naturally raises the question: <i>Is there perhaps a better way of computing amplitudes that makes this simple structure easier to see?</i>
                </p>
                <h3 className={style.pageTitle}>Physics Behind Amplitudes</h3>
                <p>
                    An important fact to realize about amplitudes is that, while the contribution of each Feynman diagram may seem random, the final amplitude is not. As it describes a physical process that could occur in nature, its form is also constrained by physical principles which we generally assume to be true for our world.
                </p>
                <p>
                    One such principle is special relativity. It tells us that information cannot be exchanged faster than the speed of light. For particles this means, that they can only interact with one another if they are at the same point in space-time, or if they exchange another particle, which travels at most at the speed of light. In physics, this constraint is typically called <i>locality</i>.
                </p>
                <p>
                    Another such principle is <i>unitarity</i>. It is one of the central ideas in quantum mechanics, and says that all quantum probabilities have to add up to one. Due to the small scales at which particle interactions occur, the rules of quantum mechanics have to apply, and we require amplitudes to obey unitarity as well.
                </p>
                <h3 className={style.pageTitle}>Glueing Trees</h3>
                <p>
                    One of the consequence of these principles is that when we tune the energies and velocities of the interacting particles just right, the scattering amplitudes can be split into smaller ones in a procedure called factorization. Very powerful techniques used in modern computations exploit this property by essentially reversing the procedure:
                </p>
                <p className={style.centerText}>
                    <i>Instead of splitting amplitudes into simpler ones, we can also “glue” simpler ones together to build more complicated amplitudes.</i>
                </p>
                <p>
                    One example of this glueing technique for tree-level amplitudes can be seen below. We can build a six-gluon amplitude from a three and a five-gluon one.
                </p>
                <Video name="beyond-feynman-1" />
                <p>
                    This example demonstrates another feature of the technique: <i>We can reuse previously computed amplitudes!</i> If we have managed to get the amplitudes used as building blocks into a simple form, we automatically obtain a simple form for our new amplitude.
                </p>
                <p>
                    Finding simple forms for amplitudes is important as it helps reveal hidden structures. You can see one example of such a structure in the animation below: in this particular case, adding a gluon to the interaction only requires a very simple change in the form of the amplitude. In fact it is easy to guess what the general form of the amplitude for any number of gluons should be
                </p>
                <Video name="beyond-feynman-2" />
                <p>
                    While the examples above show only tree amplitudes, the glueing procedure can also be used for loop amplitudes. These are typically much more complicated than their tree-level counterparts, and the gluing approach simplifies their computation immensely. In fact it has allowed us to obtain scattering amplitudes which would have been too hard to obtain just using Feynman diagrams.
                </p>
                <p>
                    Lastly, it turns out that just requiring an amplitude to be consistent with locality and unitarity is so powerful that it can often <i> completely fix an amplitude</i>. In such cases we can therefore usually get away with never having to look at a single Feynman diagram in the computation!
                </p>
                <p>
                    If you want to learn more, below you can find some additional details as to how the interplay of locality and unitarity leads to the factorization of an amplitude
                </p>
                <h3 className={style.pageTitle}>Locality</h3>
                <p>
                    As mentioned before, the locality constraint is a direct consequence of special relativity and its fundamental law that two objects cannot communicate faster than the speed of light. Locality requires that particles have to be at exactly the same point in space-time to interact with one another. Let us see how this motivates the splitting of an amplitude.
                </p>
                <p>
                    Consider an amplitude and a possible splitting:
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-2/locality-1.png"></img>
                </div>
                <p>
                    The dashed line shows which particles we would like to have on each side of the split. We want the right hand side to again consist of standard amplitudes. For the splitting to take place we therefore adjust the velocities of the external particles, so that the particle crossing the dashed line on the right hand side behaves as if it were an external particle itself.
                </p>
                <p>
                    To understand how the shown splitting occurs, we can look at the Feynman diagrams of the amplitude. Note how Feynman diagrams already have locality built in: the interactions occur at single points (the vertices), while in between interactions they travel freely (the propagators). As an example, the following diagram can be split as shown above,
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-2/locality-2.png"></img>
                </div>
                <p>
                    For the specific particle velocities, we could interpret this Feynman diagram as two separate diagrams stuck together.
                </p>
                <p>
                    There also exist diagrams which do not allow to be split in the same manner. Take for example the diagram
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-2/locality-3.png"></img>
                </div>
                <p>
                    Here there exists no internal propagator that is compatible with the splitting. Luckily, when evaluating the amplitude on the specific particle velocities for the splitting we see that these types of diagrams end up being irrelevant compared to the ones which do have such a propagator. This is exactly what locality promises:
                </p>
                <p>
                    <i>The only relevant contributions to a splitting come from Feynman diagrams, which can be split along one of the propagators.</i>
                </p>    
                <h3 className={style.pageTitle}>Unitarity</h3>
                <p>
                    <i>Unitarity</i> is one of the fundamental assumptions in quantum mechanics. Scattering amplitudes express how likely an interaction of particles with specific energies and velocities is, and are therefore closely related to probabilities. Unitarity is the requirement that the total probability is conserved.
                </p>
                <p>
                    As explained above, locality tells us that amplitudes can be split into smaller amplitudes. The principle of unitarity tells us how to perform such splittings that they are consistent with quantum mechanics. By using the connection of amplitudes with probabilities, we can understand the role of unitarity through an analogy:
                </p>
                <p>
                    Assume you have two normal dice, and you would like to know the probability of rolling a total of five. We can split the roll into two single die rolls. For the first die, we need to consider all possibilities that allow us to have a final total of five, meaning one, two, three, or four. For each such roll of the first die, there is then a specific roll of the second die that gives us the required total. In each case, we need to multiply the probabilities for both the first and second die rolls. Then, to get the total probability, we have to sum over all these cases, and we find that we have a one-in-nine chance to get a five.
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-2/unitarity-1.png"></img>
                </div>
                <p>
                    Unitarity tells us that when splitting an amplitude we need to proceed in a similar fashion. We need to consider all intermediate particles along which the split can occur. In the example shown below, we tune the external particle velocities such that splitting with intermediate particles a, b, c, and d is possible. Each time we multiply the scattering amplitudes of both sides. To obtain a consistent splitting of the original amplitude, unitarity tells us that we then need to sum over all these products.
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-2/unitarity-2.png"></img>
                </div>
            </div>
    },
    {
        sectionTitle : "Amplitudes as Volumes",
        content : 
            <div className={style.displayContent}>
                <p>
                    Amplitudes have such amazing and unexpected mathematical properties that many believe these very properties could be a hint to new deep physical principles. One of these is the connection between amplitudes and the volume of some geometric figures. Some geometries are more familiar like polygons while others are more exotic, like the amplituhedron, and represent a novelty even in mathematics!
                </p>
                <p>
                    The connection between amplitudes and geometry can be seen in a famous toy model that has been fundamental to many great progress in the field. This model is called bi-adjoint φ<sup>3</sup> theory and has the property that only three particles can interact at each vertex, that is we only have vertices of the form:
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-3/1.png"></img>
                </div>
                <p>
                    To compute the tree level amplitude it is actually sufficient to take the sum of a subset of diagrams for which no internal lines ever cross. This sum is called the ordered amplitude. The full amplitude can be then extracted from the ordered amplitude. For example, the 4-point ordered amplitude is  given by just  two diagrams that are
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-3/2.png"></img>
                </div>
                <p>
                    You can see that the labels of the external particles go clockwise starting from 1 to 4, which is why it is called an ordered amplitude. If we consider the scattering of 5 particles instead, the ordered amplitudes are given by the following diagrams
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-3/3.png"></img>
                </div>
                <p>
                    Ordered amplitudes in this model have a geometric interpretation! It is possible to show that the 4-, 5-, 6-particle ordered amplitudes are connected to the following shapes
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-3/4.png"></img>
                </div>
                <p>
                    that are a segment, a pentagon, and a triaugmented triangular prism. These shapes are also known as the 1D, the 2D and the 3D version of the dual associahedron. If we have a number n of particles, the ordered amplitude will correspond to a dual associahedron in n-3 dimensions. 
                </p>
                <h3 className={style.pageTitle}>Dual Graphs</h3>
                <p>
                    There is a different way of representing diagrams that will get us closer to their geometrical interpretation. If you take the lines of the external particles, the ones labeled by a number, as infinite, a Feynman diagram cuts the page into separate regions. We can obtain a new graph, called the dual graph, by drawing a point in each region and connecting points of adjacent regions with a line.
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/gifs/beyond-feynman-1.gif"></img>
                </div>
                <p>
                    For example, the dual diagrams for four particles are
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-3/5.png"></img>
                </div>
                <p>
                    while the dual diagrams for five particles are
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-3/6.png"></img>
                </div>
                <p>
                    You can see that the dual diagram is always made by a polygon and several non-crossing diagonals. Each polygon side is crossed by an external particle line and each diagonal is crossed by an internal line of the original Feynman diagram.
                </p>
                <h3 className={style.pageTitle}>Networks of Feynman diagrams</h3>
                <p>
                    The dual graphs give us a way to create a notion of "closeness" between Feynman diagrams. We say that two diagrams are close to each other if they differ by just one diagonal. Starting from one diagram we can move to its neighboring diagrams by "flipping" its diagonals. The “flip” consists of removing one diagonal and substituting it with the only possible alternative such that it does not cross any other diagonal.
                </p>
                <p>
                    The 4-particle amplitude is given by just two diagrams which are connected such a flip. We can associate this amplitude to the simplest geometrical object: the segment. The two diagrams correspond to the end points of the segment.
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-3/7.png"></img>
                </div>
                <p>
                    instead of thinking about diagonals flip you can also visualize the flip move as shrinking an internal line and expanding it as in the animation
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/gifs/beyond-feynman-2.gif"></img>
                </div>
                <p>
                    For five particles we have two propagators we can flip. Each diagram is connected to two other diagrams by the flipping move. Can you go through all Feynman diagrams using flipping moves?
                </p>
                <BFPentagonTesselation />
                <p>
                    There are five diagrams and each one has two neighbors, just like the edges of a pentagon. Physicists discovered that there is a way to link the velocities of the scattering particles to the coordinates of the vertices of the pentagon in such a way that the pentagon’s area is equal to the amplitude!
                </p>
                <p>
                    Now we have three internal lines that we can flip, therefore each diagram is close to three other diagrams. Each Feynman diagram corresponds to a triangular face of a solid called the triaugmented triangular prism or 3-dimensional dual associahedron. Again, there is a way to connect the particle velocities to the coordinates of this solid so that the volume of the solid corresponds to the amplitude. Check out the next tab!
                </p>
            </div>
    },
    {
        sectionTitle : "The Six Particle Dual Associahedron",
        content : 
            <div className={style.displayContent}>
                <BFHexagonTesselation />
                <div>
                    <h3 className={style.pageTitle}>What is this object?</h3>
                    <p>
                        The 14 planar Feynman diagrams corresponding to the 6 particle scattering occupy the faces of the 3D dual associahedron, also called tetrakis. 
                        Every time you click a button you will flip the diagonal of the same color and land on a different face of the dual associahedron. 
                        Try pressing the Red button. You will see the solid rotating in the direction of the red/blue edge of the triangle face. 
                        You will also see that the red diagonal has been flipped and has changed its color to blue. If you now press the blue button you will 
                        flip the same diagonal and go back where you started. <br/>
                        Can you explore all the 14 faces of the solid without ever going back to the same face? 
                    </p>
                </div>
            </div>
    },
    {
        sectionTitle : "Puzzling Feynman diagrams",
        content : 
            <div className={style.displayContent}>
                <p>
                    What theoretical physicists call a “toy model” is a playground where it is more simple to perform computations than in the universe we live in. Sometimes physicists and mathematicians put the real universe aside and come up with a simpler and more symmetrical version of it, which they are better able to understand. Only after having completely understood these toy models do they return to the original problem, the true universe, stronger and with new tools to tackle complicated computations.
                </p>
                <p>
                    A particular class of such “toy models” is represented by Affine Toda theories. They are an example of a quantum universe where we have many particles labeled by letters “a,b,c,d,...”  and each one of them comes with its own mass. In these theories, Feynman diagrams can be represented by polygons whose sides and diagonals are equal in length to the masses of the interacting particles.
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-5/1.png"></img>
                </div>
                <p>
                    Above is a Feynman diagram representing a pair of particles a and b merging into a particle i that, after propagating for a certain amount of time, decays into c and d. The corresponding polygon is obtained by crossing each line of the Feynman diagram, respectively a,b,c,d, and i (depicted with different colours), with a segment. Each particle has a different mass so that all the segments of the polygon are of different lengths.
                </p>
                <p>
                    From a mathematical point of view the issue of finding all the relevant Feynman diagrams contributing to a certain process amounts to a “tiling” problem. The equivalent description of the process, drawn as a parallelogram, has to be filled in with different triangles corresponding to the elementary couplings of the theory.
                </p>
                <p>
                    Every side of a triangle corresponds to a line in the Feynman diagram. Sometimes additional gaps in the tilling are also needed to represent particles that cross each other during the scattering (as it happens for the particles c and d in the image below.).
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-5/2.png"></img>
                </div>
                <p>
                    Are you able to find all the relevant Feynman diagrams contributing to a two-loop process in terms of their associated polygons?
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-5/3.png"></img>
                </div>
                <p>
                    There are in total 26 different Feynman diagrams. The diagrams can be arranged into a network such that the tilings of connected diagrams differ just by substituting two triangles with two different ones and filling a possible generated gap with a parallelogram. See whether you can generate the network from scratch!
                </p>
                <h3 className={style.pageTitle}>Game rules</h3>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-5/4.png"></img>
                </div>
                <ul>
                    <li>Drag the geometrical figures inside the grey frame trying to cover the entire surface</li>
                    <li>You can rotate and reflect the pieces to arrange them properly inside the frame</li>
                    <li>Only sides of the same colour can touch each other</li>
                    <li>Once you find a possible tiling try to substitute a proper pair of triangles to generate a new tiling (see the examples at the end of the page)</li>
                    <li>Watch out! You might also need parallelograms to completely fill the frame</li>
                </ul>
                <h3 className={style.pageTitle}>A puzzling amplitude game</h3>
                <div className={`${style.mediaWrapper} ${style.largeMediaContent}`}>
                    <BFPuzzle />
                </div>
                <p>
                    The game does <b>not</b> notify you if a correct tiling is completed. As the puzzle scales with screen size, 
                    it works best on a larger screen as the pieces get too small on devices such as smart phones. For the best experience
                </p>
                <p>
                    Finding all coverings is not easy. You can help yourself starting from
                    the two examples below. Try to modify them and see if you find out other tilings.
                </p>
                <details>
                    <summary>Click to show examples of valid tilings</summary>
                    <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                        <img className={style.mediaContent} alt="" src="/images/beyond-feynman/panel-5/5.png"></img>
                    </div>
                </details>
            </div>
    }//,
    // {
    //     sectionTitle : "A puzzling amplitude game",
    //     content : <BFPuzzlePanel />
    // }
];

const content = {
    title: "Beyond Feynman Diagrams",
    color: "rgb(255,255,255)",
    backgroundColor: "rgba(24,24,24,0.8)",
    panels: panels
}

export default content;