import React from 'react';
import {
    Route,
    NavLink,
    useLocation,
    Switch,
    Redirect
} from "react-router-dom";

import ReactGA from "react-ga4";

import CookieConsent from "react-cookie-consent";

// Views
import LandingPage from "./views/LandingPage";
import ExhibitionHub from "./views/ExhibitionHub";
import CookiesPrivacy from "./views/CookiesPrivacy";
import ExhibitionContent from './views/ExhibitionContent';

// Components
import SideMenu from './components/SideMenu';

import style from './styles/App.module.css';

function App() {
    let gaOptions = {
        //cookie_update: false,
        send_page_view: false
        
    }
    
    function onCookieAccept() {
        ReactGA.initialize("G-BC9N1RG1MD", gaOptions);
    }

    function usePageViews() {
        let location = useLocation();

        React.useEffect(() => {
            if (ReactGA.isInitialized) {
                ReactGA.send({ 
                    hitType: "pageview", 
                    page_path: location.pathname, 
                    page_location: window.location.href 
                });
            }
        }, [location]);
    }

    usePageViews();

    function onTitleChange(newTitle) {
        document.title = newTitle;
        if (ReactGA.isInitialized) {
            ReactGA.send({ hitType: "pageview", page_title: document.title });
        }
    }

    return <div className={style.appBody}>
        <SideMenu />
        
        <Switch>
            {/*<Route exact path="/" render={(props) => <LandingPage {...props} onTitleChange={onTitleChange}/>} />*/}
            <Route exact path="/">
                <Redirect to="/exhibition-hub" />
            </Route>
            <Route exact path="/exhibition-hub" render={(props) => <ExhibitionHub {...props} onTitleChange={onTitleChange} />} />
            <Route exact path="/exhibition-hub/:topicID" render={(props) => <ExhibitionContent {...props} onTitleChange={onTitleChange} />} />
            <Route path="/exhibition-hub/:topicID/:subPage" render={(props) => <ExhibitionContent {...props} onTitleChange={onTitleChange}/>} />
            <Route exact path="/privacy-and-cookies" render={(props) => <CookiesPrivacy {...props} onTitleChange={onTitleChange}/>} />
        </Switch>
        <CookieConsent location="bottom"  enableDeclineButton buttonText="I accept" declineButtonText="I decline" setDeclineCookie={false} flipButtons
            cookieName="sagexAnalyticsConsent" style={{ background: "#2B373B" }} buttonStyle={{ color: "#4e503b", fontSize: "13px" }} onAccept={onCookieAccept}>
            We would like to use cookies from Google Analytics. These cookies are used to collect anonymous information about how visitors use our site.
            More information can be found <NavLink to="/privacy-and-cookies" style={{color:"white"}}>here</NavLink>.
        </CookieConsent>
    </div>;
}

export default App;
