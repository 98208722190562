import React from 'react';

// Config
import questions from '../../config/FeynmanQuizConfig';

// Styles
import style from '../../styles/FeynmanQuiz.module.css';

class FQCheckPopup extends React.Component {

    renderError() {
        return <div className={style.overlayBox}>
            <div className={style.closeOverlayButton} onClick={this.props.onCloseOverlay}><img alt="" src="/overlay-close.png"></img></div>
            <h1 className={style.overlayBoxTitle}>Oops!</h1>
            <span>There was at least one wrong answer selected! For example: </span>
            <img alt="" src={questions[this.props.currentQuestion].path+this.props.errorID+".svg"}></img>
            {questions[this.props.currentQuestion].wrongInfo[this.props.errorID]}
        </div>;
    }

    renderSuccess() {
        return <div className={style.overlayBox}>
            <div className={style.closeOverlayButton} onClick={this.props.onCloseOverlay}><img alt="" src="/overlay-close.png"></img></div>
            <h1 className={style.overlayBoxTitle}>Congratulations!</h1>
            <span>All answers you selected are correct and none are missing!</span>
            <button className={`${style.checkboxButton} ${style.nextButton}`} onClick={this.props.onNextQuestion}>Next Question</button>
        </div>;
    }

    renderMissing() {
        return <div className={style.overlayBox}>
            <div className={style.closeOverlayButton} onClick={this.props.onCloseOverlay}><img alt="" src="/overlay-close.png"></img></div>
            <h1 className={style.overlayBoxTitle}>Sorry ...</h1>
            <span>There are still some correct diagram(s) which you have yet to find!</span>
        </div>;
    }

    render() {
        let content;
        switch (this.props.qState) {
            case "success":
                content = this.renderSuccess();
                break;
            case "partly":
                content = this.renderMissing();
                break;
            case "wrong":
                content = this.renderError();
                break;
            default:
                console.log("Unsupported Question State!");
                break;
        }

        return <div className={style.fullOverlay} onClick={this.props.onCloseOverlay}>
            {content}
        </div>;
    }
}
export default FQCheckPopup;