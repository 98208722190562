import React from 'react';

import SketchpadIntro from './SketchpadIntro';
import SketchpadToolbox from './SketchpadToolbox';
import SketchpadBody from './SketchpadBody';
import SketchpadFooter from './SketchpadFooter';
import HeaderNavigation from './HeaderNavigation';

import baseStyle from '../../styles/ContentPages.module.css';
import style from '../../styles/FeynmanDiagrams.module.css';

import tutorialConf from '../../config/FeynmanSketchTutorial';

class FeynmanDiagramSketchpad extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            theory: "qed", // "phi_4"
            tool: "externalVertex",
            mode: "tutorial", // "tutorial" or "sandbox"
            tutorialStep: 0,
            toolAvailabilityChangeFlag: 0
        }

        this.toolAvailabilityChange = {};

        this.sketchBodyRef = React.createRef();
    }

    onModeChange = (newMode) => {
        if (newMode !== this.state.mode) {
            if (this.sketchBodyRef.current) {
                this.sketchBodyRef.current.clearCurrentDiagram();
            }
            this.setState({mode: newMode, tutorialStep: 0});
        } 
    }

    onTutorialStep = (step) => {
        let newStep = (this.state.tutorialStep + step) % tutorialConf.length;
        if (newStep < 0) {
            newStep = tutorialConf.length - 1;
        }
        this.setState({tutorialStep: newStep, theory: tutorialConf[newStep]?.parts?.theory});
    }

    onTheoryChange = (newTheory) => {
        if (this.state.theory !== newTheory) {
            this.sketchBodyRef.current.clearCurrentDiagram();

            this.setState({
                theory : newTheory,
                tool: "externalVertex",
                errorMessage: "",
                toolAvailabilityChangeFlag: this.state.toolAvailabilityChangeFlag + 1
            });
        }
    }

    onGridChange = (tools, isDecreasing) => {
        if (tools) {
            this.toolAvailabilityChange = {
                tools: tools,
                isDecreasing: isDecreasing
            };
        } else {
            this.toolAvailabilityChange = {};
        }
        this.setState({toolAvailabilityChangeFlag: this.state.toolAvailabilityChangeFlag + 1});
    }

    onToolChange = (newTool) => {
        if (this.state.tool !== newTool) {
            this.setState({
                tool: newTool
            });
        }
    }

    onErrorMessage = (errorMessage) => {
        // Cancel possible planned timeout
        if (this.errorMessageTimeoutID) {
            clearTimeout(this.errorMessageTimeoutID);
        }
        // Set Timeout to clear error message
        this.errorMessageTimeoutID = setTimeout(() => {
            this.sketchBodyRef.current.clearErrorHighlight();
            this.clearErrorMessage();
        }, 5000);

        if (this.state.errorMessage !== errorMessage) {
            this.setState({
                errorMessage: errorMessage
            });
        }
    }

    onControlButtonAction = (action) => {
        switch (action) {
            case "check":
                this.sketchBodyRef.current.checkCurrentDiagram();
                break;
            case "delete-all":
                this.sketchBodyRef.current.clearCurrentDiagram();

                if (this.state.mode === "tutorial") {
                    this.toolAvailabilityChange = {};
                    this.setState({toolAvailabilityChangeFlag: this.state.toolAvailabilityChangeFlag + 1});
                }
                
                break;
            default:
                console.log("Unsupported action was selected in footer");
        }
        
    }

    clearErrorMessage = () => {
        this.errorMessageTimeoutID = null;
        this.setState({
            errorMessage: ""
        });
    }

    renderSketchpad = () => {
        return <div className={style.sketchpad}>
            <SketchpadToolbox
                ref={this.sketchToolboxRef}
                mode={this.state.mode}
                tutorialStep={this.state.tutorialStep}
                theory={this.state.theory} 
                onTheoryChange={this.onTheoryChange}
                tool={this.state.tool} 
                onToolChange={this.onToolChange} 
                toolAvailabilityChangeFlag={this.state.toolAvailabilityChangeFlag} 
                toolAvailabilityChange={this.toolAvailabilityChange}
                onControlButtonAction={this.onControlButtonAction} />
            <div className={style.timeArrow}>
                <img className={style.timeArrowImg} src="/images/feynman-diagrams/time.svg" alt="a labeled arrow indicating the flow of time"></img>
            </div>
            <SketchpadBody 
                ref={this.sketchBodyRef}
                tool={this.state.tool} 
                mode={this.state.mode}
                tutorialStep={this.state.tutorialStep}
                onGridChange={this.onGridChange}
                theory={this.state.theory}
                onErrorMessage={this.onErrorMessage} />
            <SketchpadFooter 
                tool={this.state.tool}
                errorMessage={this.state.errorMessage} />
        </div>
    }

    render() {
        let sketchpad = null;
        if (this.state.tutorialStep < tutorialConf.length - 1) {
            sketchpad = this.renderSketchpad();
        }
        return <div className={baseStyle.displayContent}>
            <HeaderNavigation mode={this.state.mode} onModeChange={this.onModeChange} />
            <div className={style.sketchpadWrapper}>
                <SketchpadIntro
                    mode={this.state.mode}
                    tutorialStep={this.state.tutorialStep} 
                    onModeChange={this.onModeChange}
                    onTutorialStep={this.onTutorialStep} />
                {sketchpad}
            </div>
        </div>;
    }
}
export default FeynmanDiagramSketchpad;