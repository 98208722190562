import React from 'react';

// Helper
import BFToolbox from '../../helper/BeyondFeynmanPuzzle/BFToolbox'; 
import BFShapeHelper from '../../helper/BeyondFeynmanPuzzle/BFShapeHelper'; 
import BFParallelogram from '../../helper/BeyondFeynmanPuzzle/BFParallelogram';

// Styles
import style from '../../styles/BeyondFeynman.module.css';

class BeyondFeynmanPuzzle extends React.Component {
    constructor(props) {
        super(props);

        this.mouseIsClicked = false;

        this.state = {
            showShapeOperations : false,
            rotation : 0
        };

        this.canvas = React.createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);

        this.updateCanvasProps();

        this.toolbox = new BFToolbox(this.refLength);

        this.puzzleBackground = new BFParallelogram("L_m4", "L_m3", 14 * Math.PI/30, "#eee", false);
        this.puzzleBackground.position = {
            x: this.refLength * 0.5,
            y: this.refLength * 0.5
        };

        this.currentPieces = [];

        this.mousePos = {x: 0, y: 0};
        this.update();
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.requestID);
        window.removeEventListener('resize', this.onWindowResize);
    }

    onMouseDown = (ev) => {
        this.mouseIsClicked = true;
        
        const {clientX, clientY} = ev.touches ? ev.touches[0] : ev;
        this.mousePos = this.pageToCanvasCoordinates(clientX, clientY);

        if (this.selectedPiece) {
            this.selectedPiece.isSelected = false;
            this.selectedPiece = null;
        }   
        
        // Check current pieces
        for (let i=0; i< this.currentPieces.length; i++) {
            let shape = this.currentPieces[i];
            if (shape.checkClick(this.ctx, this.mousePos)) {
                this.selectedPiece = shape;
                this.selectedPiece.isSelected = true;
                this.setState({showShapeOperations: true, rotation: this.selectedPiece.rotation});
                return;
            }
        }

        // Check Toolbox (if no piece is selected)
        let shape = this.toolbox.checkClick(this.mousePos);
        if (shape) {
            shape.position = {x: this.refLength * 0.9, y: this.refLength * 0.5};
            this.currentPieces.push(shape);
        }

        if (!this.selectedPiece) {
            this.setState({showShapeOperations: false});
        }
    }

    onMouseMove = (ev) => {
        if (!this.mouseIsClicked) { return; }

        const {clientX, clientY} = ev.touches ? ev.touches[0] : ev;
        let newMousePos = this.pageToCanvasCoordinates(clientX, clientY);

        // Apply Delta
        if (this.selectedPiece) {
            this.selectedPiece.position = {
                x: Math.min(Math.max(this.margin, this.selectedPiece.position.x + (newMousePos.x - this.mousePos.x)), this.refLength * 2 - this.margin),
                y: Math.min(Math.max(this.margin, this.selectedPiece.position.y + (newMousePos.y - this.mousePos.y)), this.refLength - this.margin)
            }
        }

        this.mousePos = newMousePos;
    }

    onMouseUp = () => {
        if (this.selectedPiece && this.selectedPiece.position.x > this.refLength) {
            this.currentPieces = this.currentPieces.filter((piece) => {
                return piece.id !== this.selectedPiece.id;
            });
            this.toolbox.addPiece(this.selectedPiece.shapeId);
            this.selectedPiece = null;
            this.setState({showShapeOperations: false});
        }
        this.mouseIsClicked = false;
    }

    onFlipShape = () => {
        if (this.selectedPiece) {
            this.selectedPiece.flip();
        }
    }

    onRotationChange = (ev) => {
        const rotation = ev.target.value;
        this.setState({rotation: rotation});
        if (this.selectedPiece) {
            this.selectedPiece.rotation = rotation; 
        }
    }

    pageToCanvasCoordinates = (clickX, clickY) => {
        const rect = this.canvas.current.getBoundingClientRect();
        return {
            x: (clickX - rect.left) * this.dpr - this.offset.x,
            y: (clickY - rect.top) * this.dpr - this.offset.y
        };
    }

    updateCanvasProps = () => {
        this.dpr = window.devicePixelRatio || 1;

        // Get current dpr adjusted width / height of DOM element
        this.width = this.canvas.current.clientWidth * this.dpr;
        this.height = this.canvas.current.clientHeight * this.dpr;

        // Set Canvas resolution based on width / height
        this.canvas.current.width = this.width;
        this.canvas.current.height = this.height;
        
        let aspect = this.width / this.height;
        this.refLength = (aspect >= 2) ? this.height : (this.width / 2);
        this.margin = 0.075 * this.refLength;
    
        this.offset = {
            x: (this.width - this.refLength * 2) / 2,
            y: (this.height - this.refLength) / 2,
        };

        this.ctx = this.canvas.current.getContext("2d");

        // Update Scale for Puzzle Elements
        BFShapeHelper.calcLengthForScale(this.refLength * 0.3);
    }

    update = () => {
        this.ctx.clearRect(0,0,this.width, this.height);
        this.ctx.lineWidth = 1 * this.dpr;

        // Translate offset
        this.ctx.save();
        this.ctx.translate(this.offset.x, this.offset.y);
    
        // Fill Background white
        this.ctx.fillStyle = "#ffffff";
        this.ctx.fillRect(0, 0, this.refLength * 2, this.refLength);

        this.puzzleBackground.draw(this.ctx);

        this.toolbox.draw(this.ctx, this.selectedPiece);

        this.currentPieces.forEach(piece => {
            piece.draw(this.ctx);
        });
        
        // ### DEBUG: Shows Mouse Position
        /*this.ctx.beginPath();
        this.ctx.arc(this.mousePos.x, this.mousePos.y, 10, 0, 2*Math.PI);
        this.ctx.fillStyle = "#ff0000";
        this.ctx.fill();
        */
        this.ctx.restore();

        this.requestID = window.requestAnimationFrame(this.update);
    }

    onWindowResize = () => {
        let oldRefLength = this.refLength;
        this.updateCanvasProps();

        let positionScale = this.refLength / oldRefLength;

        this.toolbox.resize(this.refLength, positionScale);
        this.puzzleBackground.resize(positionScale);
        this.currentPieces.forEach((piece) => {
            piece.resize(positionScale);
        });
    };

    render() {
        let visible = (!this.state.showShapeOperations) ? style.hide : null;
        return <div className={style.gameWrapper}>
            <div className={`${style.operationMenuWrapper} ${visible}`} style={{left:(this.refLength/2)/this.dpr+"px"}}>
                <button onClick={this.onFlipShape}>Flip Shape</button>
                <input type="range" step={Math.PI/30} min={0} max={2*Math.PI} value={this.state.rotation} onChange={this.onRotationChange}></input>
            </div>
            <canvas className={style.gameCanvas} ref={this.canvas} 
                onMouseDown={this.onMouseDown} onTouchStart={this.onMouseDown} 
                onMouseUp={this.onMouseUp} onTouchEnd={this.onMouseUp} onMouseLeave={this.onMouseUp} 
                onMouseMove={this.onMouseMove} onTouchMove={this.onMouseMove}></canvas>
          </div>;
    }
}
export default BeyondFeynmanPuzzle;