import React from 'react';

// Config
import {questions, rules as rulesConf} from '../../config/FeynmanQuizConfig';

// Styles
import style from '../../styles/FeynmanQuiz.module.css';

class FQRulesBox extends React.Component {

    render() {
        let rules = [];

        let theory = questions[this.props.currentQuestion].theory;
        let rulesPreset = rulesConf[theory];
        for (let i=0; i < rulesPreset.length; i++) {
            let title = <span className={style.ruleWrapperTitle}>{rulesPreset[i].name}</span>;
            let images = [];
            for (let j=0; j<rulesPreset[i].paths.length; j++) {
                images.push(<img alt="" src={rulesPreset[i].paths[j]} key={j}></img>);
            }
            rules.push(<div key={i} className={style.ruleContainer}>
                {title}
                {images}
            </div>);
        }

        return <div className={style.rulesBox}>
            <details open>
                <summary>Applicable Rules</summary>
                <div className={style.rulesWrapper}>
                    {rules}
                </div>
            </details>
        </div>;
    }
}
export default FQRulesBox;