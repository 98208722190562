import React from 'react';

// Styles
import style from '../../styles/ContentPages.module.css';

// Helper
import ForceGridController from '../../helper/PBForceGame/ForceGridController';
import DrawUtil from '../../helper/DrawUtil';

// Conf
import conf from '../../config/PBForceGameConfig';

class PBForceGame extends React.Component {
    constructor(props) {
        super(props);

        this.canvas = React.createRef();
        
        this.state = {
            stage: 0,
            screen: "start"
        }

        this.points = {};
        this.numOfStages = 3;

        this.drawScreen = this.drawStart;
        this.setStartMenuColors();

        this.timeSinceStartUp = 0;
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.updateCanvasProps();

        this.update();
    }

    componentDidUpdate(oldProps, oldState) {
        if (oldState.stage !== this.state.stage) {
            this.updateStage();
        }

        if (oldState.screen !== this.state.screen) {
            switch (this.state.screen) {
                case "countdown":
                    this.stageStart = performance.now();
                    this.drawScreen = this.drawCountdown;
                    window.setTimeout(() => {
                        this.setState({screen: "game"})
                    }, 3000);
                    break;
                case "game":
                    this.updateStage();
                    this.drawScreen = this.drawGame;
                    break;
                case "end":
                    this.drawScreen = this.drawEnd;
                    break;
                case "start":
                default:
                    this.setStartMenuColors();
                    this.drawScreen = this.drawStart;
                    break;
            }
        }
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.requestID);
        window.removeEventListener('resize', this.onWindowResize);
    }

    onMouseDown = (ev) => {
        const {clientX, clientY} = ev.touches ? ev.touches[0] : ev;
        let mousePos = this.pageToCanvasCoordinates(clientX, clientY);
        
        switch (this.state.screen) {
            case "start":
            case "end":
                this.setState({screen: "countdown"});
                break;
            case "game":
                this.gameController.checkButtonClicks(mousePos, this.ctx);
                break;
            default:
                break;
        } 
        
    }

    pageToCanvasCoordinates = (clickX, clickY) => {
        const rect = this.canvas.current.getBoundingClientRect();
        return {
            x: (clickX - rect.left) * this.dpr - this.offset.x,
            y: (clickY - rect.top) * this.dpr - this.offset.y
        };
    }

    updateCanvasProps = () => {
        this.dpr = window.devicePixelRatio || 1;

        // Get current dpr adjusted width / height of DOM element
        this.width = this.canvas.current.clientWidth * this.dpr;
        this.height = this.canvas.current.clientHeight * this.dpr;

        // Set Canvas resolution based on width / height
        this.canvas.current.width = this.width;
        this.canvas.current.height = this.height;
        
        this.refLength = Math.min(this.height, this.width);
        
        this.offset = {
            x: (this.width - this.refLength) / 2,
            y: (this.height - this.refLength) / 2,
        };
    
        this.ctx = this.canvas.current.getContext("2d");
    }

    update = () => {
        // Timing
        let newTimeSinceStartUp = performance.now() - this.stageStart;
        this.deltaT = newTimeSinceStartUp - this.timeSinceStartUp;
        this.timeSinceStartUp = newTimeSinceStartUp;

        this.draw();

        this.requestID = window.requestAnimationFrame(this.update);
    }

    setStartMenuColors() {
        if (Math.random() > 0.5) {
            this.startButtonColor = conf.colors.m;
            this.startTitleColor = conf.colors.r;
        } else {
            this.startButtonColor = conf.colors.r;
            this.startTitleColor = conf.colors.m;
        }
    }

    draw = () => {
        this.ctx.clearRect(0,0,this.width, this.height);
        this.ctx.lineWidth = 1 * this.dpr;

        // Translate offset
        this.ctx.save();
        this.ctx.translate(this.offset.x, this.offset.y);
    
        // Fill Background white
        this.ctx.strokeStyle = "#ffffff";
        this.ctx.fillStyle = "#ffffff";
        this.ctx.beginPath();
        this.ctx.rect(0, 0, this.refLength, this.refLength);
        this.ctx.stroke();
        this.ctx.globalAlpha = 0.4;
        this.ctx.fill();
        this.ctx.globalAlpha = 1;

        this.drawScreen();

        this.ctx.restore();
    }

    drawCountdown = () => {
        this.ctx.fillStyle = "#fff";
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";

        this.ctx.font = this.refLength*0.1+"px Arial";
        this.ctx.fillText("Stage "+(this.state.stage+1), 0.5*this.refLength, 0.2*this.refLength);

        this.ctx.font = this.refLength*0.2+"px Arial";
        
        this.ctx.fillText(Math.floor((3000-this.timeSinceStartUp)/1000)+1, 0.5*this.refLength, 0.6*this.refLength);
    }

    drawEnd = () => {
        this.ctx.fillStyle = "#fff";
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";

        this.ctx.font = this.refLength*0.1+"px Arial";
        this.ctx.fillText("Game cleared!", 0.5*this.refLength, 0.1*this.refLength);
        this.ctx.fillText("Thanks for Playing!", 0.5*this.refLength, 0.7*this.refLength);

        this.ctx.font = this.refLength*0.075+"px Arial";
        this.ctx.fillText("Click to Play Again", 0.5*this.refLength, 0.85*this.refLength);

        this.ctx.font = this.refLength*0.075+"px Arial";
        let stageInfos = Object.keys(this.points);
        for (let i=0; i<stageInfos.length; i++) {
            let stage = stageInfos[i];
            this.ctx.fillText("Stage "+(parseInt(stage)+1)+" - "+this.points[stage].points+" of "+this.points[stage].max, 0.5*this.refLength, (0.3+i*0.1)*this.refLength);
        }        
    }

    drawGame = () => {
        this.gameController.update(this.timeSinceStartUp, this.deltaT);
        this.gameController.draw(this.ctx, this.cellSize);
    }

    drawStart = () => {
        this.ctx.font = this.refLength*0.1+"px Arial";
        this.ctx.fillStyle = this.startTitleColor;
        this.ctx.strokeStyle = "#fff";
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.fillText("Force Grid Maze", 0.5*this.refLength, 0.1*this.refLength);
        this.ctx.strokeText("Force Grid Maze", 0.5*this.refLength, 0.1*this.refLength);

        DrawUtil.drawCircleWithOutline(this.ctx, 
            this.refLength*0.5, this.refLength*0.5, this.refLength* 0.25, 
            "#fff", this.startButtonColor);

        this.ctx.fillStyle = "#fff";
        this.ctx.beginPath();
        this.ctx.moveTo(this.refLength*0.4, this.refLength*0.4);
        this.ctx.lineTo(this.refLength*0.65, this.refLength*0.5);
        this.ctx.lineTo(this.refLength*0.4, this.refLength*0.6);
        this.ctx.closePath();
        this.ctx.fill();
        this.ctx.stroke();

        this.ctx.font = this.refLength*0.05+"px Arial";
        this.ctx.fillStyle = "#fff";
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.fillText("Click to START the game", 0.5*this.refLength, 0.9*this.refLength);
    }
    
    updateStage() {
        this.stageStart = performance.now();
        this.gameController = new ForceGridController(this.state.stage, this.stageStart);
        this.gameController.onStageFinished = this.onStageEnd;
        this.gameController.updateCellSize(this.refLength);
    }

    onStageEnd = (points) => {
        this.points[this.state.stage] = points;
        if (this.state.stage < (this.numOfStages-1)) {
            this.setState({stage: this.state.stage+1, screen: "countdown"});
        } else {
            this.setState({stage: 0, screen: "end"});
        }
        
    }

    onWindowResize = () => {
        this.updateCanvasProps();
        if (this.gameController) {
            this.gameController.updateCellSize(this.refLength);
        }
    };

    render() {
      return <div className={`${style.mediaWrapper} ${style.largeMediaContent}`}>
              <canvas className={style.canvasContent} ref={this.canvas} onMouseDown={this.onMouseDown}></canvas>
          </div>;
    }
}
export default PBForceGame;