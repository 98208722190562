import React from 'react';

import ToolDescription from '../../config/ToolDescription'

import style from '../../styles/FeynmanDiagrams.module.css';

class SketchpadFooter extends React.Component {
  
    render() {
        return <div className={style.sketchpadFooter}>
            <div className={style.infoTextWrapper}>
                {ToolDescription[this.props.tool]}
                <br/>
                <span className={style.errorText}>{this.props.errorMessage}</span>
            </div>
            
        </div>;
    }
}
export default SketchpadFooter;