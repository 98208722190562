import BFShapeHelper from './BFShapeHelper';

export default class BFTriangle {
    constructor(key_l_a, key_l_b, key_l_c, fillColor = "#fff") {
        this.key_l_a = key_l_a;
        this.key_l_b = key_l_b;
        this.key_l_c = key_l_c;

        // Generate ID
        this.shapeId = this.key_l_a + this.key_l_b + this.key_l_c;
        this.id = BFShapeHelper.getNextFreeId();

        this.resize();

        this.position = {x : 0, y: 0};
        this.rotation = 0;
        this.isSelected = false;

        this.fillColor = fillColor;
    }

    draw(ctx) {
        ctx.save();
        ctx.translate(this.position.x, this.position.y);
        ctx.rotate(this.rotation);

        ctx.fillStyle = this.isSelected ? "#aef2c1" : this.fillColor;
        ctx.beginPath();
        ctx.moveTo(this.aX, this.aY);
        ctx.lineTo(this.bX, this.bY);
        ctx.lineTo(this.cX, this.cY);
        ctx.closePath();
        ctx.fill();

        BFShapeHelper.fillQuad(ctx, this.aX, this.aY, this.inner_aX, this.inner_aY, this.inner_bX, this.inner_bY, this.bX, this.bY, BFShapeHelper.getColorByLength(this.l_c));
        BFShapeHelper.fillQuad(ctx, this.bX, this.bY, this.inner_bX, this.inner_bY, this.inner_cX, this.inner_cY, this.cX, this.cY, BFShapeHelper.getColorByLength(this.l_a));
        BFShapeHelper.fillQuad(ctx, this.aX, this.aY, this.inner_aX, this.inner_aY, this.inner_cX, this.inner_cY, this.cX, this.cY, BFShapeHelper.getColorByLength(this.l_b));
        
        ctx.restore();
    }

    drawThumbnail(ctx, scale) {
        let lineWidth = ctx.lineWidth;
        ctx.save();
        ctx.scale(scale, scale);
        ctx.lineWidth = lineWidth / scale;

        ctx.fillStyle = this.fillColor;
        ctx.beginPath();
        ctx.moveTo(this.aX, this.aY);
        ctx.lineTo(this.bX, this.bY);
        ctx.lineTo(this.cX, this.cY);
        ctx.closePath();
        ctx.fill();

        BFShapeHelper.fillQuad(ctx, this.aX, this.aY, this.inner_aX, this.inner_aY, this.inner_bX, this.inner_bY, this.bX, this.bY, BFShapeHelper.getColorByLength(this.l_c));
        BFShapeHelper.fillQuad(ctx, this.bX, this.bY, this.inner_bX, this.inner_bY, this.inner_cX, this.inner_cY, this.cX, this.cY, BFShapeHelper.getColorByLength(this.l_a));
        BFShapeHelper.fillQuad(ctx, this.aX, this.aY, this.inner_aX, this.inner_aY, this.inner_cX, this.inner_cY, this.cX, this.cY, BFShapeHelper.getColorByLength(this.l_b));

        ctx.restore();
    }

    getClone() {
        return new BFTriangle(this.key_l_a, this.key_l_b, this.key_l_c);
    }

    checkClick(ctx, mousePos) {
        ctx.save();
        ctx.translate(this.position.x, this.position.y);
        ctx.rotate(this.rotation);

        ctx.beginPath();
        ctx.moveTo(this.aX, this.aY);
        ctx.lineTo(this.bX, this.bY);
        ctx.lineTo(this.cX, this.cY);
        ctx.closePath();

        ctx.restore();

        return ctx.isPointInPath(mousePos.x, mousePos.y);
    }

    resize(positionScale) {
        // 0 - Save triangle side lengths
        this.l_a = BFShapeHelper[this.key_l_a];
        this.l_b = BFShapeHelper[this.key_l_b];
        this.l_c = BFShapeHelper[this.key_l_c];

        // 1 - Calculate vertex positions a, b, and c with a at Origin and b at x-axis in distance l_c
        this.aX = 0;
        this.aY = 0;
        this.bX = this.l_c;
        this.bY = 0;
        this.cX = this.l_b *  (this.l_b * this.l_b + this.l_c * this.l_c - this.l_a * this.l_a) / (2 * this.l_b * this.l_c);
        this.cY = - Math.sqrt (this.l_b * this.l_b - this.cX * this.cX);

        // 2 - Calculate Bisection Points bis_a, bis_b, and bis_c
        this.bis_aX = (this.bX + this.cX)/2;
        this.bis_aY = (this.bY + this.cY)/2;
        this.bis_bX = this.cX/2;
        this.bis_bY = this.cY/2;
        this.bis_cX = this.l_c/2;
        this.bis_cY = 0; 

        // 3 - Calculate centroid m
        let mX = (this.aX + this.bX + this.cX) / 3;
        let mY = (this.aY + this.bY + this.cY) / 3;

        // 4 - Translate all points so that m is at origin
        this.aX -= mX; this.aY -= mY;
        this.bX -= mX; this.bY -= mY;
        this.cX -= mX; this.cY -= mY;
        this.bis_aX -= mX; this.bis_aY -= mY;
        this.bis_bX -= mX; this.bis_bY -= mY;
        this.bis_cX -= mX; this.bis_cY -= mY;

        // 5 - Calculate vertex offset to center for thicker lines
        let perimeter = this.l_a + this.l_b + this.l_c;
        let incenterX = (this.aX * this.l_a + this.bX * this.l_b + this.cX * this.l_c) / perimeter;
        let incenterY = (this.aY * this.l_a + this.bY * this.l_b + this.cY * this.l_c) / perimeter;
        let inradius = 0.5 * Math.sqrt(((this.l_b + this.l_c - this.l_a)*(this.l_c + this.l_a - this.l_b)*(this.l_a + this.l_b - this.l_c))/perimeter);

        this.inner_aX = incenterX + (1 - BFShapeHelper.lineWidth / inradius) * (this.aX - incenterX);
        this.inner_aY = incenterY + (1 - BFShapeHelper.lineWidth / inradius) * (this.aY - incenterY);

        this.inner_bX = incenterX + (1 - BFShapeHelper.lineWidth / inradius) * (this.bX - incenterX);
        this.inner_bY = incenterY + (1 - BFShapeHelper.lineWidth / inradius) * (this.bY - incenterY);

        this.inner_cX = incenterX + (1 - BFShapeHelper.lineWidth / inradius) * (this.cX - incenterX);
        this.inner_cY = incenterY + (1 - BFShapeHelper.lineWidth / inradius) * (this.cY - incenterY);

        // Optional - Scale position based on resize factor
        if (positionScale) {
            this.position.x *= positionScale;
            this.position.y *= positionScale;
        }
    }

    flip () {
        this.aX *= -1; this.inner_aX *= -1;
        this.bX *= -1; this.inner_bX *= -1; 
        this.cX *= -1; this.inner_cX *= -1;
        this.rotation *= -1;
    }

    setCustomFillColor(color) {
        this.fillColor = color;
    }
}