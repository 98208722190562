const amplituhedronFaceTitles =  {
    "0" : null,
    "1" : "Physical Basics",
    "2" : "Feynman Diagrams",
    "3" : null,
    "4" : "Team",
    "5" : "Solitons",
    "6" : "About SAGEX",
    "7" : "Acknowledgements",
    "8" : "Gravity",
    "9" : "Beyond Feynman Diagrams"
};

export default amplituhedronFaceTitles;