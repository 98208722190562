import React from 'react';

// Components
import PentagonImage from './BFPentagonImage';

// Styles
import baseStyle from '../../styles/ContentPages.module.css';
import style from '../../styles/BeyondFeynman.module.css';


class BFPentagonTesselation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: 1
        }
    }

    flipBlue = () => {
        let newState = 0;
        switch(this.state.content) {
            case 1:
                newState = 2;
                break;
            case 2:
                newState = 3;
                break;
            case 3:
                newState = 4;
                break;
            case 4:
                newState = 5;
                break;
            case 5:
                newState = 1;
                break;
            default:
                newState = 0;
                break;
        }

        this.setState({content: newState});
    }

    flipRed = () => {
        let newState = 0;
        switch(this.state.content) {
            case 1:
                newState = 5;
                break;
            case 2:
                newState = 1;
                break;
            case 3:
                newState = 2;
                break;
            case 4:
                newState = 3;
                break;
            case 5:
                newState = 4;
                break;
            default:
                newState = 0;
                break;
        }

        this.setState({content: newState});
    }

    render() {
        return <div>
            <div className={style.tesselationWrapper}>
                <div className={style.flipImageWrapper}>
                    <PentagonImage fileName="penta-12.png" isVisible={this.state.content === 1} />
                    <PentagonImage fileName="penta-23.png" isVisible={this.state.content === 2} />
                    <PentagonImage fileName="penta-34.png" isVisible={this.state.content === 3} />
                    <PentagonImage fileName="penta-45.png" isVisible={this.state.content === 4} />
                    <PentagonImage fileName="penta-51.png" isVisible={this.state.content === 5} />
                </div>
                <div>
                    <img className={baseStyle.mediaContent} alt="" src="/images/beyond-feynman/panel-3/8.png"></img>
                </div>
            </div>
            
            <div className={style.tesselationControls}>
                <button className={baseStyle.gameButton} onClick={this.flipRed}>Flip Red</button>
                <button className={baseStyle.gameButton} onClick={this.flipBlue}>Flip Blue</button>
            </div>
        </div>;
    }
}
export default BFPentagonTesselation;