import React from 'react';

import AmplituhedronTopicListEntry from './AmplituhedronTopicListEntry';

import style from "../styles/ExhibitionHub.module.css";

class AmplituhedronTopicList extends React.Component {
    render() {
        return <div className={style.topicListWrapper}>
            <AmplituhedronTopicListEntry {...this.props} title="Physical Basics"/>
            <AmplituhedronTopicListEntry {...this.props} title="Gravity"/>
            <AmplituhedronTopicListEntry {...this.props} title="Solitons"/>
            <AmplituhedronTopicListEntry {...this.props} title="Feynman Diagrams"/>
            <AmplituhedronTopicListEntry {...this.props} title="Beyond Feynman Diagrams"/>
            <AmplituhedronTopicListEntry {...this.props} title="About SAGEX"/>
            <AmplituhedronTopicListEntry {...this.props} title="Team"/>
            <AmplituhedronTopicListEntry {...this.props} title="Acknowledgements"/>
        </div>;
    }
}
export default AmplituhedronTopicList;