export default class BFShapeHelper {
    static nextFreeId = 0;

    static getNextFreeId () {
        return this.nextFreeId ++;
    }
    static drawColoredLine(ctx, aX, aY, bX, bY, color) {
        ctx.strokeStyle = color;
        ctx.beginPath();
        ctx.moveTo(aX, aY);
        ctx.lineTo(bX, bY);
        ctx.stroke();
    }

    static fillQuad(ctx, aX, aY, bX, bY, cX, cY, dX, dY, color) {
        ctx.beginPath();
        ctx.moveTo(aX, aY);
        ctx.lineTo(bX, bY);
        ctx.lineTo(cX, cY);
        ctx.lineTo(dX, dY);
        ctx.closePath();
        ctx.fillStyle = color;
        ctx.fill();
    }
    
    // Possible lengths of Beyond Feynman game puzzle
    static L_m1 = 0;
    static L_m2 = 0;
    static L_m3 = 0;
    static L_m4 = 0;
    static L_m5 = 0;

    static L_m1_color = "#ffbf00";
    static L_m2_color = "#8a2be3";
    static L_m3_color = "#008000";
    static L_m4_color = "#cc0000";
    static L_m5_color = "#66ff00";

    static lineWidth = 0;

    static calcLengthForScale(scale) {
        BFShapeHelper.L_m1 = scale;
        BFShapeHelper.L_m2 = 2*BFShapeHelper.L_m1*Math.cos(Math.PI/5);
        BFShapeHelper.L_m3 = 2*BFShapeHelper.L_m1*Math.cos(Math.PI/30);
        BFShapeHelper.L_m4 = 2*BFShapeHelper.L_m2*Math.cos(7*Math.PI/30);
        BFShapeHelper.L_m5 = 2*BFShapeHelper.L_m2*Math.cos(2*Math.PI/15);
        BFShapeHelper.lineWidth = scale * 0.025;
    }

    static getColorByLength(length) {
        let result = "#ffffff";
        switch (length) {
            case BFShapeHelper.L_m1:
                result = this.L_m1_color;
                break;
            case BFShapeHelper.L_m2:
                result = this.L_m2_color;
                break;
            case BFShapeHelper.L_m3:
                result = this.L_m3_color;
                break;
            case BFShapeHelper.L_m4:
                result = this.L_m4_color;
                break;
            case BFShapeHelper.L_m5:
                result = this.L_m5_color;
                break;
            default:
                result = "#000000"
                break;
        }
        return result;
    }
}