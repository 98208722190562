import React from 'react';

import FeynmanDiagramSketchpad from '../../components/FeynmanDiagramSketchpad/FeynmanDiagramSketchpad';
import FeynmanDiagramQuiz from '../../components/FeynmanQuiz/FeynmanQuiz';

import style from '../../styles/ContentPages.module.css';

const panels = [
    {
        sectionTitle: "Introduction",
        content : 
            <div className={style.displayContent}>
                <p>
                    Although scattering amplitudes are one of the central objects of quantum field theory, they are notoriously difficult to calculate. For example, finding the amplitude for the simple scattering of electrons requires a number of increasingly complicated calculations. In 1948 Richard Feynman introduced a visual representation which both made these calculations easier to organise, and showed what each calculation represents physically. 
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={`${style.mediaContent} ${style.invert}`} src="/images/feynman-diagrams/equation.svg" alt=""></img>
                </div>
                <p>
                    Their simplicity was such that any physicist could understand them and implement them into their work. Julian Schwinger, one of the founders of QED, couldn’t have put it better: “Like the silicon chips of more recent years, the Feynman diagram was bringing computation to the masses.”
                </p>
                <p>
                    In this section we will explain the basics of constructing Feynman Diagrams for a given physical process, as well as the importance of combining them to get physical answers.
                </p>
            </div>
    },
    {
        sectionTitle: "Constructing Feynman diagrams",
        content : 
            <div className={style.displayContent}>
                <p>
                    The Feynman diagrams we can draw depend on which theory we are working with. Different physical theories describe the interactions of a different set of quantum particles. Each theory has its own set of rules with which one can construct consistent Feynman diagrams.
                </p>
                <p>
                    For example, Quantum Electrodynamics (QED) is the physical theory which describes the interaction of photons (light) with electrons, and its diagrams can be composed out of one interaction rule:
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={`${style.mediaContent} ${style.invert}`} src="/images/feynman-diagrams/Rules/qed/rule.svg" alt=""></img>
                </div>
                <p>
                    Physical processes are characterised by incoming and outgoing particles, which interact by exchanging “virtual” particles. A typical example is the electron-positron (e⁻e⁺) annihilation, producing muons (μ⁻μ⁺). This process is described by the following diagram in QED:
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={`${style.mediaContent} ${style.invert}`} src="/images/feynman-diagrams/electronstomuons.svg" alt=""></img>
                </div>
                <p>
                    This diagram shows the particles exchanging a virtual photon. However there are many more allowed diagrams that one needs to describe precisely this interaction. In our interactive game we explain the rules for QED and 𝝋⁴ theory and let you draw your own Feynman diagrams!
                </p>
            </div>
    },
    {
        sectionTitle: "Feynman diagram sketchpad",
        content : 
            <div className={style.displayContent}>
                <p>
                    In this game we explain the Feynman rules for QED and 𝝋⁴ theory and let you draw your own Feynman diagrams, let's get started!
                </p>
                <FeynmanDiagramSketchpad />
            </div>
    },
    {
        sectionTitle: "Combining Feynman diagrams",
        content : 
            <div className={style.displayContent}>
                <p>
                    It is not enough to construct just one diagram to be able to compute some physical quantity. To construct a scattering amplitude for a given process, one needs to combine all possible allowed Feynman diagrams compatible with the external particles of that process.
                </p>
                <p>
                    As explained in the tutorial, Feynman diagrams are usually characterised in terms of number of loops, with higher loop diagrams typically representing weaker quantum effects. Diagrams with no loops are said to be at tree level. For example:
                </p>
                <div className={`${style.mediaWrapper} ${style.mediumMediaContent}`} >
                    <img className={`${style.mediaContent} ${style.invert}`} src="/images/feynman-diagrams/phi-1-2.svg" alt=""></img>
                </div>
                <figcaption className={`${style.imageCaption} ${style.centerText}`}>
                    An example of a diagram of <b>tree level</b> (left) and a diagram with <b>one loop</b> (right)
                </figcaption>
                <p>
                    In the next panel, you can test your skills in identifying all the Feynman diagrams which contribute to a scattering process up to a given number of loops.
                </p>
            </div>
    },
    {
        sectionTitle: "Feynman diagram quiz",
        content : <FeynmanDiagramQuiz />
    }
];

const content = {
    title: "Feynman Diagrams",
    color: "rgb(255,255,255)",
    backgroundColor: "rgba(24,24,24,0.8)",
    panels: panels
}

export default content;