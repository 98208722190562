import React from 'react';

// Styles
import style from '../../styles/BeyondFeynman.module.css';
import baseStyle from '../../styles/ContentPages.module.css';


class BFPentagonImage extends React.Component {
    render() {
        const imagePath = "/images/beyond-feynman/pentagon-tesselation/"
        const visibility = (this.props.isVisible) ? style.fadeIn : style.fadeOut;
        return <img className={`${style.fillingImage} ${baseStyle.mediaContent} ${visibility}`} src={imagePath + this.props.fileName} 
            alt="Tesselated pentagon associated with a Feynman diagram"></img>;
    }
}
export default BFPentagonImage;