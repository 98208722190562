import React from 'react';

//Config
import questions from '../../config/FeynmanQuizConfig';

// Styles
import style from '../../styles/FeynmanQuiz.module.css';

class FQAnswerGridElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false
        };
    }

    componentDidUpdate(oldProps) {
        if (this.props.path !== oldProps.path) {
            this.setState({selected: false});
        }
    }

    onClick = () => {
        this.props.onSelect(this.props.id, !this.state.selected);
        this.setState({selected: !this.state.selected});
    }

    render() {
        let selected = (this.state.selected) ? style.selected : null;
        let onClickHandler = (this.props.solved) ? null : this.onClick;

        if (this.props.solved && (questions[this.props.currentQuestion].correct.indexOf(this.props.id) > -1)) {
            selected = style.selected;
        }

        return <div className={`${style.questionAnswerGridCell} ${selected}`} onClick={onClickHandler}>
            <img src={this.props.path + this.props.id + ".svg"} alt=""></img>
        </div>;
    }
}
export default FQAnswerGridElement;