import React from 'react';

// Styles
import style from '../../styles/FeynmanQuiz.module.css';

class FQAnswerButton extends React.Component {

    onClick = () => {
        let questionState = this.props.questionStates[this.props.currentQuestion];
        if (questionState === "success") {
            this.props.onNextQuestion();
        } else {
            this.props.onCheckAnswer();
        }
    }

    render() {
        let checkButtonText = "CHECK ANSWER";
        let buttonStyle = style.checkButton;
        let questionState = this.props.questionStates[this.props.currentQuestion];
        if ( questionState === "success") {
            checkButtonText = "NEXT QUESTION";
            buttonStyle = style.nextButton;
        } else if (questionState === "partly" || questionState === "wrong") {
            checkButtonText = "RETRY CHECK"
        }

        return <div className={style.quizControls}>
            <button className={`${style.quizButton} ${buttonStyle}`} onClick={this.onClick}>{checkButtonText}</button>
        </div>;
    }
}
export default FQAnswerButton;