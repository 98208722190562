import ErrorCodes from '../../config/ErrorCodes';

import {
    ExternalVertex,
    InternalVertex
    } from '../../helper/FeynmanGame/Vertex';

export default class GridController {
    constructor(canvasWidth, canvasHeight, dpr) {
        let shortSide = Math.min(canvasWidth, canvasHeight);
        this.cellSize = Math.floor(shortSide / 10);

        this.dimension = {
            x: Math.floor(canvasWidth / this.cellSize),
            y: Math.floor(canvasHeight / this.cellSize)
        };

        this.center = {
            x: Math.floor(this.dimension.x/2),
            y: Math.floor(this.dimension.y/2)
        }
        this.dpr = dpr;

        this.data = Array(this.dimension.x).fill(null).map(x => Array(this.dimension.y).fill(null));
        this.propagators = {};
        this.vertices = {};

        this.currentErrorID = null;

        this.gridOffset = {
            x: canvasWidth / (this.dimension.x + 1),
            y: canvasHeight / (this.dimension.y + 1)
        };

        this.connectionCheckID = 0;
    }

    addVertex(mousePos, vertex) {
        const gridPos = this.getGridPos(mousePos);

        this.vertices[vertex.id] = vertex;
        this.data[gridPos.x][gridPos.y] = vertex.id;
    }

    addVertexTemplate(vertexData, idOffset, theory) {
        let gridPos = {
            x: vertexData.x + this.center.x,
            y: vertexData.y + this.center.y
        };
        let pos = {
            x: (gridPos.x + 1) * this.gridOffset.x,
            y: (gridPos.y + 1) * this.gridOffset.y
        }
        
        let vertex;
        if (vertexData.type === "external") {
            vertex = new ExternalVertex(pos);
        } else {
            vertex = new InternalVertex(pos, theory);
        }
        vertex.id+= idOffset;

        this.vertices[vertex.id] = vertex;
        this.data[gridPos.x][gridPos.y] = vertex.id;
    }

    addPropagator (propagator) {
        this.propagators[propagator.id] = propagator;

        this.vertices[propagator.startVertexID].addPropagator(propagator.id, -propagator.charge);
        this.vertices[propagator.endVertexID].addPropagator(propagator.id, propagator.charge);

        // Check curvature
        this.updatePropagatorCurvature(propagator, false);
    }

    updatePropagatorCurvature = (propagator, ignoreSeed) => {
        // Check for multiple connections
        const sharedPropagators = this.vertices[propagator.startVertexID].propagatorIDs.filter(
            propagatorID => this.vertices[propagator.endVertexID].propagatorIDs.includes(propagatorID)
        );

        const loopLength = sharedPropagators.length;
        const numOfShares = loopLength - ((ignoreSeed) ? 1 : 0);
        const halfFloored = Math.floor(numOfShares / 2);
        let currentCurvature = -halfFloored;

        for (let i=0; i<loopLength; i++) {
            let currentPropagatorID = sharedPropagators[i];
            if (ignoreSeed && currentPropagatorID === propagator.id) { 
                continue; 
            }

            let directionCorrection = (propagator.startVertexID === this.propagators[currentPropagatorID].startVertexID) ? 1 : -1;
            this.propagators[currentPropagatorID].setCurvature(currentCurvature * directionCorrection);

            currentCurvature++;
            if (currentCurvature === 0 && !(numOfShares & 1)) {
                currentCurvature++;
            }
        }
    }

    checkValidityOfPosition (gridPos) {
        return gridPos.x >= 0 && gridPos.x < this.dimension.x 
            && gridPos.y >= 0 && gridPos.y < this.dimension.y;
    }

    checkFeynmanRules() {
        if (this.vertices.length === 0) {
            return {
                isValid: false,
                error: ErrorCodes.NO_STRUCTURE
            };
        }

        let externalVertCount = 0;
        // 1 & 2 - Check Valency and energy of Vertices
        for (let vertixID in this.vertices) {
            let vertex = this.vertices[vertixID];

            // Prepare Check for atleast one external vertex
            if (vertex.type === "external") {
                externalVertCount ++;
            }

            const valency = vertex.checkValency();
            if(valency !== 0) {
                this.currentErrorID = vertixID;
                return {
                    isValid: false,
                    error: (valency > 0) ? ErrorCodes.TOO_FEW_PROPAGATORS : ErrorCodes.TOO_MANY_PROPAGATORS
                };
            }
            if(!vertex.checkChargeConservation()) {
                this.currentErrorID = vertixID;
                return {
                    isValid: false,
                    error: ErrorCodes.NON_ZERO_CHARGE
                };
            }
            if(!vertex.checkChargeVariance()) {
                this.currentErrorID = vertixID;
                return {
                    isValid: false,
                    error: ErrorCodes.NO_INTERACTION
                };
            }
        };

        if (externalVertCount < 1) {
            return {
                isValid: false,
                error: ErrorCodes.NO_EXTERNAL_VERTICES
            };
        }

        // 3 - One Structure / Connection check
        // 3.0 - Increase Check ID
        this.connectionCheckID++;
        // 3.1 -  Start at first vertex
        let startVert = this.vertices[Object.keys(this.vertices)[0]];
        if (startVert) {
            // 3.2 - Recursively apply check ID to all connected vertices
            this.connectivityCheck(startVert);
        }

        for (let vertixID in this.vertices) {
            let vertex = this.vertices[vertixID];
            if (vertex.connectionCheckID !== this.connectionCheckID) {
                this.currentErrorID = vertixID;
                return {
                    isValid: false,
                    error: ErrorCodes.STRUCTURE_NOT_CONNECTED
                };
            }
        }

        return {isValid : true};
    }

    connectivityCheck = (vert) => {
        vert.connectionCheckID = this.connectionCheckID;

        vert.propagatorIDs.forEach(propID => {
            let vertID = (this.propagators[propID].startVertexID === vert.id) ? 
                this.propagators[propID].endVertexID : this.propagators[propID].startVertexID;
            
            if (this.vertices[vertID].connectionCheckID !== this.connectionCheckID) {
                this.connectivityCheck(this.vertices[vertID]);
            }
        });
    }

    getVertexIDAtGridPosition(mousePos) {
        const gridPos = this.getGridPos(mousePos);
        return this.data[gridPos.x][gridPos.y];
    }

    removeEverything() {
        this.data = Array(this.dimension.x).fill(null).map(x => Array(this.dimension.y).fill(null));
        
        this.propagators = {};
        this.vertices = {};
    }

    tryRemoveAtMousePos(mousePos) {
        const gridPos = this.getGridPos(mousePos);

        // 1 - Check for propagator
        for (let propagatorID in this.propagators) {
            let propagator = this.propagators[propagatorID];
            if (propagator.checkCenterClick(mousePos)) {
                let type = propagator.type;
                this.removePropagator(propagatorID);
                return [type];
            }
        };

        // 2 - Check for vertex
        if (this.data[gridPos.x][gridPos.y]) {
            let type = this.vertices[this.data[gridPos.x][gridPos.y]].type+"Vertex";
            let connectedPropagatorTypes = this.removeVertex(gridPos);
            return [type, ...connectedPropagatorTypes];
        }
        return false;
    }

    removeVertex(gridPos) {
        let connectedPropagatorTypes = [];
        if (this.data[gridPos.x][gridPos.y]) {
            // Remove all propagators connected to the vertex
            const id = this.data[gridPos.x][gridPos.y];
            let connectedPropagators = this.vertices[id].propagatorIDs;
            
            while (connectedPropagators.length > 0) {
                connectedPropagatorTypes.push(this.removePropagator(connectedPropagators[0]));
            }

            // Remove vertex from list of vertices
            delete this.vertices[id];

            // Reset Grid position
            this.data[gridPos.x][gridPos.y] = null;
        }
        return connectedPropagatorTypes;
    }

    removePropagator(propagatorID) {
        let propagator = this.propagators[propagatorID];
        let type = propagator.type;
        this.updatePropagatorCurvature(propagator, true);
        
        // Remove propagator from start and end vertex list of propagators
        this.vertices[propagator.startVertexID].removePropagator(propagatorID, -propagator.charge);
        this.vertices[propagator.endVertexID].removePropagator(propagatorID, +propagator.charge);

        delete this.propagators[propagatorID];

        return type;
    }

    drawBackgroundGrid = (ctx) => {
        let x = this.gridOffset.x;
        let y = this.gridOffset.y;

        ctx.fillStyle = "rgb(160, 160, 160)";
    
        for (let i=0; i < this.dimension.x; i++) {
            for (let j=0; j < this.dimension.y; j++) {
                ctx.beginPath();
                ctx.arc(x, y, this.cellSize * 0.1, 0, 2 * Math.PI);
                ctx.fill(); 
                y += this.gridOffset.y;
            }
            x += this.gridOffset.x;
            y = this.gridOffset.y;
        }
    }

    drawPropagators = (ctx, tool) => {
        ctx.lineWidth = this.cellSize * 0.04;
        for (let propagatorID in this.propagators) {
            this.propagators[propagatorID].draw(ctx, false, (tool === "delete"));
        };
    }

    drawVertices = (ctx) => {
        for (let vertixID in this.vertices) {
            this.vertices[vertixID].draw(ctx, this.cellSize);
        };

        if (this.currentErrorID && this.vertices[this.currentErrorID]) {
            this.vertices[this.currentErrorID].drawErrorHighlight(ctx, this.cellSize);
        }
    }

    drawCursor = (ctx, cursor) => {
        ctx.strokeStyle = "rgb(255,127,80)";
        ctx.lineWidth = this.cellSize * 0.075;

        if (cursor) {
            ctx.beginPath();
            ctx.arc(cursor.x, cursor.y, this.cellSize * 0.25, 0, 2 * Math.PI);
            ctx.stroke(); 
        }
    }

    draw = (ctx, tool) => {
        this.drawBackgroundGrid(ctx);
        this.drawPropagators(ctx, tool);
        this.drawVertices(ctx);
    }

    getGridPos(mousePos) {
        return {
            x: Math.round(mousePos.x / this.gridOffset.x) - 1,
            y: Math.round(mousePos.y / this.gridOffset.y) - 1
        };
    }

    getGridPixelPos(mousePos) {
        return {
            x: this.gridOffset.x * Math.round(mousePos.x / this.gridOffset.x),
            y: this.gridOffset.y * Math.round(mousePos.y / this.gridOffset.y)
        };
    }
}