import React from 'react';

import style from '../../styles/FeynmanDiagrams.module.css';

class HeaderNavigation extends React.Component {

    onTutorialClick = () => {
        this.props.onModeChange("tutorial");
    }

    onSandboxClick = () => {
        this.props.onModeChange("sandbox");
    }

    render() {
        return <div className={style.headerNavigationWrapper} >
            <div className={style.headerNavigationSpacer}></div>
            <div className={`${style.headerNavigationElement} ${(this.props.mode !== "tutorial") ? style.inactive : null}`}
                onClick={this.onTutorialClick}>Tutorial</div>
            <div className={style.headerNavigationSpacer}></div>
            <div className={`${style.headerNavigationElement} ${(this.props.mode !== "sandbox") ? style.inactive : null}`}
                onClick={this.onSandboxClick}>Sandbox</div>
            <div className={style.headerNavigationFiller}></div>
        </div>;
    }
}
export default HeaderNavigation;