import * as THREE from 'three';

const idOfFace = [
    "862", "821", "856", "815", "135", "642", "965", "953", "934", "946", "712", "731", "724", "743"
]

const flips = {
    "862" : {
        r: "642",
        y: "856",
        b: "821"
    }, 
    "821" : {
        r: "712",
        y: "862",
        b: "815"
    }, 
    "856" : {
        r: "965",
        y: "815",
        b: "862"
    }, 
    "815" : {
        r: "135",
        y: "821",
        b: "856"
    }, 
    "135" : {
        r: "953",
        y: "731",
        b: "815"
    }, 
    "642" : {
        r: "724",
        y: "946",
        b: "862"
    }, 
    "965" : {
        r: "856",
        y: "946",
        b: "953"
    }, 
    "953" : {
        r: "135",
        y: "965",
        b: "934"
    }, 
    "934" : {
        r: "743",
        y: "953",
        b: "946"
    }, 
    "946" : {
        r: "642",
        y: "934",
        b: "965"
    }, 
    "712" : {
        r: "821",
        y: "731",
        b: "724"
    }, 
    "731" : {
        r: "135",
        y: "743",
        b: "712"
    }, 
    "724" : {
        r: "642",
        y: "712",
        b: "743"
    }, 
    "743" : {
        r: "934",
        y: "724",
        b: "731"
    }
};

const positions = {
    "821": new THREE.Vector3(-3.661836444764826, 1.5989250140114297, 0.18545255825259002),
    "862": new THREE.Vector3(-1.1594891441173156, 2.863445498173286, -2.5441168077591745),
    "642": new THREE.Vector3(-0.25076650740287887, -0.10498733951946539, -3.848903914407025),
    "946": new THREE.Vector3(3.23470024063649, 0.05851153245674748, -2.351537973999046),
    "965": new THREE.Vector3(3.5525539110067266, 1.8140555333250892, 0.3200694641076831),
    "856": new THREE.Vector3(0.45825615322975105, 3.9335585195623, 0.559963177869544),
    "712": new THREE.Vector3(-3.4415633422871195, -2.119000235367518, 0.27819805511719786),
    "724": new THREE.Vector3(-1.955920905411986, -2.633105943751817, -3.025512320262784),
    "743": new THREE.Vector3(-0.38809718169685137, -4.805719169769113, -0.03927305109011441),
    "731": new THREE.Vector3(-1.870098861704588, -3.344236295855421, 2.926757997956796),
    "135": new THREE.Vector3(0.6804254901447021, -0.18857447972539426, 4.769544983709402),
    "815": new THREE.Vector3(-1.8804145213177956, 3.615433773310448, 2.481525297718903),
    "953": new THREE.Vector3(2.596454547747578, 0.25780650575261416, 2.558973861396887),
    "934": new THREE.Vector3(3.140597835368368, -2.2004126336517076, 0.07554585868376967)
};

const quaternions = {
    "821": new THREE.Quaternion(0.5130111444356036, -0.4622099037540384, -0.6862155778629181, 0.22866952410589436),
    "862": new THREE.Quaternion(-0.8600715743904939, 0.27951737200922916, 0.27460821870229285, 0.32670667561539724),
    "642": new THREE.Quaternion(-0.6999570773399961, 0.7133144515533056, 0.01305717861533644, -0.03274283392536685),
    "946": new THREE.Quaternion(-0.2618814246607702, 0.8654719697294067, -0.1448387527373272, 0.40174385463357354),
    "965": new THREE.Quaternion(0.21511143956758239, 0.6454807027458329, 0.520066561526886, 0.516345332632638),
    "856": new THREE.Quaternion(0.6456996605705692, 0.11431865960874932, 0.21676263858030145, -0.7231992470405482),
    "712": new THREE.Quaternion(0.11833776272430975, 0.6717692966297996, -0.488502245376142, -0.5441394511348868),
    "724": new THREE.Quaternion(-0.661107208629965, -0.6339884557757237, 0.39563105964205675, -0.06687272453783048),
    "743": new THREE.Quaternion(-0.6851045722528704, -0.18629117642570292, 0.23888060296805408, -0.6624676446361413),
    "731": new THREE.Quaternion(-0.32655384466452525, 0.29975485959423825, -0.2067606174829663, -0.8722153734843918),
    "135": new THREE.Quaternion(0.0019729829455551306, -0.07339255851780685, 0.2920849194830461, -0.9535701544737486),
    "815": new THREE.Quaternion(0.1577544067378437, 0.4324274042362781, 0.6124788601034419, -0.6426427725779331),
    "953": new THREE.Quaternion(0.2659784482869306, 0.11225467169362942, 0.9343185429444915, 0.20905313688130459),
    "934": new THREE.Quaternion(0.4304072623310182, -0.5663722942185184, 0.697933750998848, -0.08282808757328412)
}

const conf = {
    ids: idOfFace,
    flips: flips,
    positions: positions,
    quaternions: quaternions
};

export default conf;