import * as THREE from "three";

class SceneManager {
    constructor (mount) {
        this.threeMount = mount;

        this.width = this.threeMount.clientWidth;
        this.height = this.threeMount.clientHeight;

        this.camWidth = 9;
        this.camHeight =  this.camWidth / (this.width / this.height);

        this.scene = new THREE.Scene();
        this.camera = new THREE.OrthographicCamera(
            this.camWidth / - 2, 
            this.camWidth / 2, 
            this.camHeight / 2, 
            this.camHeight / - 2, 
            0.1, 1000
        );
        
        this.camera.position.z = 4; 
        
        this.camera.add(new THREE.DirectionalLight(0xffffff,1.25,0));
        this.scene.add(this.camera);

        this.renderer = new THREE.WebGLRenderer({ 
            antialias: true,
            alpha: true
        });

        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setSize( this.width, this.height );
        this.renderer.sortObjects = false;
        this.threeMount.appendChild( this.renderer.domElement ); // mount using React ref
    }

    add(obj) {
        this.scene.add(obj);
    }

    render = () => {
        this.renderer.render( this.scene, this.camera );
    }

    handleResize = () => {
        // -10 because of weird bug on resize because of rounding (?) errors that increase the height on resize
        this.width = this.threeMount.clientWidth - 10;
        this.height = this.threeMount.clientHeight - 10;

        this.camHeight =  this.camWidth / (this.width / this.height);

        this.camera.bottom = this.camHeight / - 2; 
        this.camera.top = this.camHeight / 2; 
        this.camera.right = this.camWidth / 2; 
        this.camera.left = this.camWidth / - 2; 
        this.camera.updateProjectionMatrix();

        this.renderer.setSize( this.width, this.height, true );
        this.renderer.setPixelRatio( window.devicePixelRatio );
    }  
}

export default SceneManager;