import React from 'react';
import TeamMemberProfile from '../../components/TeamMemberProfile';

import baseStyle from '../../styles/ContentPages.module.css';
import style from '../../styles/Team.module.css';

const panels = [
    {
        sectionTitle: "Team",
        content: 
            <div className={baseStyle.displayContent}>
                <h3 className={baseStyle.pageTitle}>Physical Basics</h3>
                <div className={style.teamWrapper}>
                    <TeamMemberProfile name="Ingrid Holm" url="Ingrid_Holm.jpg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Manuel Accettulli Huber" url="Manuel_Accettulli_Huber.jpg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Marco Saragnese" url="Marco_Saragnese.jpg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Emil Bjerrum-Bohr" url="Emil_Bjerrum_Bohr.jpg" role="Advisor"/>
                </div>
                <h3 className={baseStyle.pageTitle}>Gravity</h3>
                <div className={style.teamWrapper}>
                    <TeamMemberProfile name="Andrea Cristofoli" url="Andrea_Cristofoli.jpg" role="Early Stage Researcher"/>          
                    <TeamMemberProfile name="Stefano De Angelis" url="Stefano_De_Angelis.jpg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Nikolai Fadeev" url="Nikolai_Fadeev.jpg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Kays Haddad" url="Kays_Haddad.jpg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Emil Bjerrum-Bohr" url="Emil_Bjerrum_Bohr.jpg" role="Advisor"/>
                </div>
                <h3 className={baseStyle.pageTitle}>Solitons</h3>
                <div className={style.teamWrapper}>
                    <TeamMemberProfile name="Canxin Shi" url="Canxin_Shi.jpg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Anne Spiering" url="Anne_Spiering.jpg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Matthias Staudacher" url="Matthias_Staudacher.jpg" role="Advisor"/>
                </div>
                <h3 className={baseStyle.pageTitle}>Feynman Diagrams</h3>
                <div className={style.teamWrapper}>
                    <TeamMemberProfile name="Luke Corcoran" url="Luke_Corcoran.jpg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Riccardo Gonzo" url="Riccardo_Gonzo.jpg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Lorenzo Quintavalle" url="Lorenzo_Quintavalle.jpg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Ricardo Monteiro" url="Ricardo_Monteiro.png" role="Advisor"/>
                </div>
                <h3 className={baseStyle.pageTitle}>Beyond Feynman Diagrams</h3>
                <div className={style.teamWrapper}>
                    <TeamMemberProfile name="Davide Polvara" url="Davide_Polvara.jpeg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Gabriele Dian" url="Gabriele_Dian.jpg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Sebastian Poegel" url="Sebastian_Poegel.jpg" role="Early Stage Researcher"/>
                    <TeamMemberProfile name="Ricardo Monteiro" url="Ricardo_Monteiro.png" role="Advisor"/>
                </div>
                <h3 className={baseStyle.pageTitle}>Exhibition Development and Realisation</h3>
                <div className={style.teamWrapper}>
                    <TeamMemberProfile name="Susanne Milde" url="Susanne_Milde.png" />
                    <TeamMemberProfile name="Sascha Rieger" url="Sascha_Rieger.jpg" />
                </div>
                {/*<h3 className={baseStyle.pageTitle}>SAGEX Outreach Task Group</h3>
                <div className={style.teamWrapper}>
                    <TeamMemberProfile name="Ekaterina Eremenko" url="Ekaterina_Emerenko.jpg" />
                    <TeamMemberProfile name="Susanne Milde" url="Susanne_Milde.png" />
                    <TeamMemberProfile name="Ricardo Monteiro" url="Ricardo_Monteiro.png" />
                    <TeamMemberProfile name="Matthias Staudacher" url="Matthias_Staudacher.jpg" />
                    <TeamMemberProfile name="Chris White" url="Chris_White.jpg" />
                </div>*/}
                <h3 className={baseStyle.pageTitle}>Coordination and Organisation</h3>
                <div className={style.teamWrapper}>
                    <TeamMemberProfile name="Jenna Lane" url="Jenna_Lane.jpg" />
                    <TeamMemberProfile name="Mary Thomas" url="Mary_Thomas.jpg" />
                </div>
                <h3 className={baseStyle.pageTitle}>Voice Over</h3>
                <div className={style.teamWrapper}>
                    <TeamMemberProfile name="Elena Kaufman" url="Elena_Kaufman.jpg" />
                </div>
                <h3 className={baseStyle.pageTitle}>Programming</h3>
                <div className={style.teamWrapper}>
                    <TeamMemberProfile name="Michael Droste" role="Developer" url="Michael_Droste.jpg" />
                </div>
            </div>
    }
];

const content = {
    title: "Team",
    color: "rgb(255,255,255)",
    backgroundColor: "rgba(24,24,24,0.8)",
    panels: panels
}

export default content;