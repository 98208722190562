// Config
import conf from '../../config/PBForceGameConfig';

// Helper
import ForceParticle from './ForceParticle';
import ForceRowController from './ForceRowController';
import ColumnExitController from './ColumnExitController';

export default class ForceGridController {
    constructor(stage, starttime) {
        this.stage = conf.stages[stage];
        
        // Prepare Timing variables
        this.stageStart = starttime;
        this.secondIntervalProgress = 0;

        // Prepare points
        this.points = 0;

        // Prepare Rows (distribute, set state and determine locked rows)
        this.generateRows();

        // Prepare Exits
        this.generateExits();

        // Prepare Particles
        this.generateParticleInfo();
    }

    generateRows() {
        this.rows = [];
        let rForceRows = Math.floor(this.stage.dim / 2);
        let mForceRows = Math.floor(this.stage.dim / 2);
        if (this.stage.dim & 1) {
            (Math.random() > 0.5) ? rForceRows++ : mForceRows++;
        }

        let rowIndexes = Array.from(Array(this.stage.dim).keys());
        for (let i=0; i< this.stage.dim; i++) {
            let force = ((Math.random() > 0.5 && rForceRows > 0) || mForceRows === 0) ? "r" : "m";
            (force === "r" ? rForceRows-- : mForceRows--);
            this.rows.push(new ForceRowController(force, (Math.random() > 0.5) ? -1 : 1, i));
        }

        this.stage.lockedRows = Math.min(this.stage.lockedRows, Math.floor(this.stage.dim / 2));
        for (let i=0; i<this.stage.lockedRows; i++) {
            let rIndex = Math.floor(Math.random() * rowIndexes.length);
            this.rows[rowIndexes.splice(rIndex, 1)[0]].isLocked = true;
        }

        // Calculate Forces in stage
        this.stage.forceField = {
            locked: { r: 0, m: 0 },
            variable: { r: 0, m: 0 }
        };

        for (let i=0; i< this.stage.dim; i++) {
            let row = this.rows[i];
            if (row.isLocked) {
                this.stage.forceField.locked[row.force]++;
            } else {
                this.stage.forceField.variable[row.force]++;
            }
        }
    }

    generateExits() {
        let columnIndexes = Array.from(Array(this.stage.dim).keys());
        
        this.stage.exits = Math.max(this.stage.exits, 2);
        this.exits = [];
        
        let rForceExits = Math.floor(this.stage.exits / 2);
        let mForceExits = Math.floor(this.stage.exits / 2);
        if (this.stage.exits & 1) {
            (Math.random() > 0.5) ? rForceExits++ : mForceExits++;
        }

        for (let i=0; i<this.stage.exits; i++) {
            let force = ((Math.random() > 0.5 && rForceExits > 0) || mForceExits === 0) ? "r" : "m";
            (force === "r" ? rForceExits-- : mForceExits--);
            this.exits.push(new ColumnExitController(force, columnIndexes.splice(Math.floor(Math.random() * columnIndexes.length), 1)[0]));   
        }
    }

    generateParticleInfo() {
        this.particleInfo = [];
        this.particles = [];
        let particleForces = [ "r", "m", "b" ];

        for (let i=0; i<this.stage.particles; i++) {
            let force = particleForces[Math.floor(Math.random() * 3 )];
            this.particleInfo.push({
                force: force, 
                spawnTime: this.stage.particleInterval*i+500, 
                column: this.calculateSolvableColumn(force)
            });
        }
    }

    calculateSolvableColumn(force) {
        let fixedDrift = 0; let variableRows = 0;
        if (force === "r" || force === "b") {
            fixedDrift += this.stage.forceField.locked.r;
            variableRows += this.stage.forceField.variable.r;
        } 
        if (force === "m" || force === "b") {
            fixedDrift += this.stage.forceField.locked.m;
            variableRows += this.stage.forceField.variable.m;
        }

        let possibleSlots = [];
        for (let i=0; i<this.stage.exits; i++) {
            let exit = this.exits[i];
            if (exit.force === force || force === "b") {
                for (let j=0; j<(variableRows+1); j++) {
                    let slot = Math.min(Math.max(0, exit.column - variableRows + (j*2) - fixedDrift), this.stage.dim-1);
                    if (possibleSlots.indexOf(slot) === -1) {
                        possibleSlots.push(slot);
                    }
                }
            } 
        }

        return possibleSlots[Math.floor(Math.random()*possibleSlots.length)];
    }


    checkButtonClicks (mousePos, ctx) {
        this.rows.forEach(row => {
            if (row.checkClick(mousePos, ctx, this.cellSize, this.stage.dim)) {
                return;
            }
        });
    }

    updateCellSize(refLength) {
        // Set Cell Size
        this.cellSize = refLength / (this.stage.dim + 2);
    }

    update (timeSinceStartUp, deltaT) { // in ms
        let timesinceStartUpInSeconds = timeSinceStartUp / 1000;
        let completeSeconds = Math.floor(timesinceStartUpInSeconds);
        this.secondIntervalProgress = (timesinceStartUpInSeconds - completeSeconds);

        this.checkParticleSpawn(timeSinceStartUp);

        this.particles.forEach((particle, index) => {
            particle.update(deltaT, this.stage.gForce, this.rows, this.stage.dim);
            if(!particle.inFall && !particle.countdown && !particle.checked) {
                particle.checked = true;
                particle.despawnTime = timeSinceStartUp + 500;

                let endColumn = Math.floor(particle.column + particle.x);
                for (let i=0; i<this.stage.exits; i++) {
                    let exit = this.exits[i];
                    if (exit.column === endColumn && (exit.force === particle.force || particle.force === "b")) {
                        particle.success = true;
                        break;
                    }
                }
            } else if (particle.checked && timeSinceStartUp > particle.despawnTime) {
                if (particle.success) {
                    this.points++;
                }
                this.particles.splice(index, 1);

                // Check End
                if (!this.particles.length && this.onStageFinished) {
                    this.onStageFinished({points: this.points, max: this.stage.particles});
                } 
            }
        });
    }

    checkParticleSpawn(timeSinceStartUp) {
        if(this.particleInfo.length && this.particleInfo[0].spawnTime < timeSinceStartUp) {
            let pInfo = this.particleInfo.splice(0,1)[0];
            this.particles.push(new ForceParticle(
                pInfo.force, 
                pInfo.column,
                3000 // Spawn Countdown (Fall Offset)
            ));
        }
    }

    draw (ctx) {
        this.exits.forEach(exit => {
            exit.draw(ctx, this.cellSize, this.stage.dim);
        });

        this.rows.forEach(row => {
            row.draw(ctx, this.cellSize, this.stage.dim, this.secondIntervalProgress);
        });

        this.particles.forEach(particle => {
            particle.draw(ctx, this.cellSize);
        });
    }
}