// Helper
import DrawUtil from '../DrawUtil';

// Config
import conf from '../../config/PBForceGameConfig';

export default class ForceRowController {
    constructor(force, state, row, locked) {
        this.force = force;
        this.row = row;
        this.state = state;
    }

    checkClick (mousePos, ctx, cellSize, dim) {
        let buttons = new Path2D();
        buttons.rect(0, cellSize*(this.row+1), cellSize, cellSize);
        buttons.rect(cellSize*(dim+1), cellSize*(this.row+1), cellSize, cellSize);
        if (ctx.isPointInPath(buttons, mousePos.x, mousePos.y) && !this.isLocked) {
            this.state *= -1;
            return true;
        }
        return false
    }

    draw (ctx, cellSize, dim, secondIntervalProgress) {
        ctx.save();
        ctx.translate(0, cellSize*(this.row+1));

        // Draw Animated Forcefield
        this.drawForceField(ctx, cellSize, dim, secondIntervalProgress);

        // Left Button
        this.drawButton(ctx, cellSize);

        // Right Button
        ctx.translate((dim+1)*cellSize, 0);
        this.drawButton(ctx, cellSize);

        ctx.restore();
    }

    drawButton(ctx, cellSize) {
        ctx.save();
        ctx.globalAlpha = (this.isLocked) ? 0.25 : 1;
        DrawUtil.drawRectWithOutline(
            ctx,
            0, 0, 
            cellSize, cellSize, 
            "#fff", conf.colors[this.force]
        );
        ctx.restore();

        this.drawArrow(ctx, cellSize, "#fff");
        if (this.isLocked) {
            this.drawLock(ctx, cellSize, "#fff");
        }
    }

    drawForceField (ctx, cellSize, dim, p) {
        ctx.save();
        ctx.globalAlpha = 0.25;
        ctx.translate(cellSize * this.state * p, 0);
        for (let i=0; i<=dim + 1; i++) {
            if (!(this.state === 1 && i === dim + 1) && !(this.state === -1 && i === 0)) {
                this.drawArrow(ctx, cellSize, conf.colors[this.force]);
            }
            
            ctx.translate(cellSize, 0);
        }
        ctx.restore();
    }

    drawArrow(ctx, cellSize, fillColor) {
        let halfCell = cellSize/2;
        ctx.fillStyle = fillColor;
        ctx.beginPath();
        ctx.moveTo(halfCell + this.state * halfCell/2, halfCell);
        ctx.lineTo(halfCell - this.state * halfCell/2, halfCell-(halfCell/2));
        ctx.lineTo(halfCell - this.state * halfCell/2, halfCell+(halfCell/2));
        ctx.fill();
    }

    drawLock(ctx, cellSize, color) {
        let halfCell = cellSize/2;

        ctx.fillStyle = color;
        ctx.strokeStyle = color;
        ctx.beginPath();
        ctx.rect(halfCell + 0.1*cellSize* this.state, halfCell+0.2*cellSize, 0.2*cellSize*this.state, 0.15*cellSize);
        ctx.fill();

        ctx.beginPath();
        ctx.arc(halfCell + 0.2*cellSize* this.state, halfCell+0.2*cellSize, 0.07 * cellSize, Math.PI, 2*Math.PI);
        ctx.stroke();
    }
}