const errorCodes = {
    NON_EMPTY_VERTEX_POSITION : "Vertices may only be placed on free positions!",
    EMPTY_PROPAGATOR_POSITION : "Propagators need to start and end at a vertex position!",
    ZERO_LENGTH_PROPAGATOR : "Propagators may not start and end at the same vertex!",
    NON_ZERO_CHARGE : "The highlighted vertex violates the charge conservation law!",
    NO_INTERACTION : "The highlighted vertex does have no interacting particles, use both kinds of propagators!",
    TOO_MANY_PROPAGATORS : "The highlighted vertex has too many propagators!",
    TOO_FEW_PROPAGATORS : "The highlighted vertex has too few propagators!",
    NO_EXTERNAL_VERTICES : "There needs to be atleast one external vertex in the diagram!",
    NO_STRUCTURE: "No structure in the sketchpad, draw a diagram first!",
    STRUCTURE_NOT_CONNECTED: "The highlighted vertex is not connected to the main structure!",
    NO_SELECT_DELETE: "Nothing to delete in this position! Select either a vertex or the square in the middle of a propagator!",
    NO_TOOL_SELECTED: "No tool selected, please select a tool to use at the top."
}

export default errorCodes;