import React from 'react';

import SubsectionFooter from '../components/SubsectionFooter';

import baseStyle from "../styles/App.module.css";
import style from "../styles/ContentPages.module.css";

class CookiesPrivacy extends React.Component {
    componentDidMount() {
        this.props.onTitleChange("SAGEX Exhibition - Privacy & Cookies");
    }

    render() {
        return <div className={baseStyle.layoutLimiter}>
            <div className={baseStyle.layoutWrapper}>
                <h1>Use of cookies by SAGEX</h1>
                <div className={baseStyle.mainDisplay}>
                    <div className={style.displayContent}>
                        <p>
                            Cookies are small text files placed on a website visitor's computer or device, which identify it to our server. 
                            Cookies do not identify individual users, only the computer used. They are widely used in order to make websites 
                            work more efficiently, as well as to provide certain information to the owners of the site. 
                        </p>
                        <p>
                            You may delete and block all cookies from this site. Most web browsers allow some control of cookies through 
                            the browser settings. To find out more about cookies, including how to see what cookies have been set and how 
                            to manage and delete them from your computer, visit <a href="www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org</a>. 
                        </p>
                        <p>
                            This site uses cookies from Google Analytics. 
                        </p>
                        <p>
                            These cookies are used to collect information about how visitors use our site. We use the information to compile 
                            reports and to help us improve the site. The cookies collect information in an anonymous form and include the 
                            number of visitors to the site, where visitors have come to the site from, the pages they visit and length of 
                            time spent on pages. 
                        </p>
                        <p>
                            Read an overview of <a href="https://support.google.com/analytics/topic/2919631?hl=en&ref_topic=1008008" target="_blank" rel="noopener noreferrer">privacy at Google</a>. 
                        </p>
                    </div>
                </div>
                <SubsectionFooter title={"Privacy & Cookies"} page={0} 
                    numberOfPages={1} onPageUpdate={null}/>
            </div>
            
        </div>;
    }
}

export default CookiesPrivacy;
