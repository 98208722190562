import Shape from "./Shape";

class Vertex extends Shape {
    constructor(gridPos) {
        super();
        this.pos = gridPos;

        this.valency = 0;
        this.charge = 0;
        this.chargeAbs = 0; 

        this.propagatorIDs = [];
    }

    draw = (ctx, scale) => {
        ctx.fillStyle = this.fillColor;
        ctx.strokeStyle = this.strokeColor;
        ctx.beginPath();
        ctx.arc(
            this.pos.x, 
            this.pos.y, 
            0.2 * scale, 
            0, 
            2 * Math.PI
        );
        ctx.fill(); 
    }

    drawErrorHighlight = (ctx, scale) => {
        ctx.fillStyle = "rgba(0,0,0,0)";
        ctx.strokeStyle = "rgb(255, 0, 0)";
        ctx.beginPath();
        ctx.beginPath();
        ctx.arc(this.pos.x, this.pos.y, 0.3 * scale, 0, 2 * Math.PI);
        ctx.stroke(); 
    }

    addPropagator(propagatorID, charge) {
        this.valency--;
        this.charge += charge;
        this.chargeAbs += Math.abs(charge);
        this.propagatorIDs.push(propagatorID);
    }

    removePropagator(propagatorID, charge) {
        let index = this.propagatorIDs.indexOf(parseInt(propagatorID));
        if (index !== -1) {
            this.valency++;
            
            this.charge -= charge;
            this.chargeAbs -= Math.abs(charge);
        
            this.propagatorIDs.splice(index, 1);
        }
    }

    checkValency() {
        return this.valency;
    }

    checkChargeConservation() {
        return (this.ignoreCharge) ? true : (this.charge === 0);
    }

    checkChargeVariance() {
        return (this.ignoreCharge) ? true : (this.chargeAbs > 0);
    }
}

class InternalVertex extends Vertex {
    constructor(gridPos, theory) {
        super(gridPos);
        this.fillColor = 'rgb(156, 242, 148)';
        this.type = "internal";

        this.valency = (theory === "qed") ? 3 : 4;
        this.ignoreCharge = (theory === "phi_4");
    }
}

class ExternalVertex extends Vertex {
    constructor(gridPos) {
        super(gridPos);
        this.fillColor = 'rgb(255, 89, 89)';
        this.type = "external";

        this.ignoreCharge = true;

        this.valency = 1;
    }
}

export {
    ExternalVertex,
    InternalVertex
}