// Config
import conf from '../../config/PBForceGameConfig';

export default class ForceParticle {
    constructor(force, column, spawnTimeoffset) {
        this.force = force;
        this.column = column;
        this.x = 0;
        this.y = 0.5;
        this.success = false;

        if (spawnTimeoffset) {
            this.spawnTimeoffset = spawnTimeoffset;
            this.inFall = false;
            this.countdown = true;
        } else {
            this.inFall = true;
        }
    }

    update(deltaT, gForce, rows, dim) {
        if (this.inFall) {
            let preUpdateRow = Math.floor(this.y);
            this.y += (deltaT / 1000) * gForce;
            
            if (Math.floor(this.y) > preUpdateRow) {
                this.column += Math.sign(this.x);
                this.x = 0;
            }

            let currentRow = rows[Math.floor(this.y-1)];
            if (currentRow && (currentRow.force === this.force || this.force === "b")) {
                this.x = (this.y - Math.floor(this.y)) * currentRow.state;
                if ((this.column === dim-1 && this.x > 0) || (this.column === 0 && this.x < 0)) {
                    this.x = 0;
                }
            }

            if (this.y >= dim+1.5) {
                this.inFall = false;
            }
        } else if (this.countdown) {
            this.spawnTimeoffset -= deltaT;
            if (this.spawnTimeoffset <= 0) {
                this.inFall = true; this.countdown = false;
            }
        }
    }

    draw(ctx, cellSize) {
        let halfCell = cellSize / 2;

        ctx.save();
        ctx.fillStyle = conf.colors[this.force];
        ctx.strokeStyle = "#fff";

        ctx.translate(cellSize * (this.column + 1 + this.x) + halfCell, this.y * cellSize);

        if (this.force === "b") {
            ctx.fillStyle = conf.colors["r"];
            ctx.beginPath();
            ctx.arc(0, 0, halfCell * 0.5, Math.PI, 2 * Math.PI);
            ctx.fill();
            ctx.stroke();
            ctx.fillStyle = conf.colors["m"];
            ctx.beginPath();
            ctx.arc(0, 0, halfCell * 0.5, 0, Math.PI);
            ctx.fill();
            ctx.stroke();
        } else {
            ctx.fillStyle = conf.colors[this.force];
            ctx.beginPath();
            ctx.arc(0, 0, halfCell * 0.5, 0, 2 * Math.PI);
            ctx.fill();
            ctx.stroke();
        }

        if (this.countdown) {
            let seconds = Math.floor(this.spawnTimeoffset / 1000)+1;
            ctx.font = (halfCell*0.5)+"px Arial";
            ctx.textBaseline = "middle";
            ctx.textAlign = "center";
            ctx.fillStyle = "#fff";
            ctx.fillText(seconds, 0, 0, halfCell);
        }

        ctx.restore();

        // Draw Success / Error
        if (!this.inFall && !this.countdown) {
            ctx.save();
            ctx.translate(cellSize * (this.column + 1 + this.x), (this.y) * cellSize - halfCell);
            ctx.lineWidth = halfCell * 0.1;

            let offset = 0.2*cellSize;
            if (this.success) {
                ctx.strokeStyle = "#00ff00";
                ctx.beginPath();
                ctx.moveTo(halfCell - offset, halfCell);
                ctx.lineTo(halfCell, halfCell+offset);
                ctx.lineTo(cellSize-offset, offset);
                ctx.stroke();
            } else {
                ctx.strokeStyle = "#ff0000";
                ctx.beginPath();
                ctx.moveTo(offset, offset);
                ctx.lineTo(cellSize-offset, cellSize-offset);
                ctx.moveTo(cellSize-offset, offset);
                ctx.lineTo(offset, cellSize-offset);
                ctx.stroke();
            }

            ctx.restore();
        }
    }
}