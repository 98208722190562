import React from 'react';

import style from '../../styles/FeynmanDiagrams.module.css';

import tutorialConf from '../../config/FeynmanSketchTutorial';

class SketchpadTools extends React.Component {
    constructor(props) {
        super(props);

        if (this.props.mode === "tutorial") {
            let parts = tutorialConf[this.props.tutorialStep].parts;
            this.state = {...parts};
            this.universalPropagatorUsed = (parts.universalPropagator > 0);
        } else {
            this.state = {};
        }
    }

    componentDidUpdate(oldProps) {
        if (this.props.mode === "tutorial") {
            if (oldProps.tutorialStep !== this.props.tutorialStep || oldProps.mode === "sandbox") {
                this.resetToolAvailability();
            }
            
            if (this.props.toolAvailabilityChangeFlag !== oldProps.toolAvailabilityChangeFlag) {
                if (this.props.toolAvailabilityChange.tools) { // Apply tool change
                   this.alterToolAvailability();
                } else { // Apply reset
                    this.resetToolAvailability();
                }
            }

            if (this.props.tool && this.activeToolUnavailable()) {
                this.props.onToolChange(this.getFirstAvailableTool());
            }

            if (this.props.theory !== oldProps.theory) {
                this.resetToolAvailability();
            }
        }
    }

    alterToolAvailability() {
        let stateUpdate = {};
        
        let modifier = (this.props.toolAvailabilityChange.isDecreasing) ? -1 : 1;
        let tools = this.props.toolAvailabilityChange.tools;
        for (let i=0; i< tools.length; i++) {
            let tool = tools[i];
            if (this.universalPropagatorUsed && tool.endsWith("Propagator")) {
                tool = "universalPropagator";
            }

            if (stateUpdate[tool]) {
                stateUpdate[tool] += modifier;
            } else {
                stateUpdate[tool] = modifier + this.state[tool];
            }
        }
        this.setState(stateUpdate);
    }

    activeToolUnavailable = () => {
        let count = this.state[this.props.tool];
        if ((this.props.theory === "qed" && (this.props.tool === "photonPropagator" || this.props.tool === "electronPropagator"))
            || (this.props.theory === "phi_4" && this.props.tool === "dashedLinePropagator")) {
            count += this.state.universalPropagator;
        }
        return count === 0;
    }

    getFirstAvailableTool = () => {
        let keys = Object.keys(this.state);
        for (let i=0; i<keys.length; i++) {
            if (this.state[keys[i]] > 0) {
                if (keys[i] === "universalPropagator") { // Universal propagator available
                    if (this.props.theory === "qed") {
                        return "electronPropagator";
                    } else {
                        return "dashedLinePropagator";
                    }
                }
                return keys[i];
            }
        }
        return "delete";
    }

    handleToolChange = (ev) => {
        this.props.onToolChange(ev.target.value)
    }

    resetToolAvailability = () => {
        let parts = tutorialConf[this.props.tutorialStep].parts;
        this.universalPropagatorUsed = (parts.universalPropagator > 0);
        this.setState({...parts});
    }

    render() {
        let tools;
        
        if (this.props.mode === "sandbox") {
            // Non limited tools for Sandbox
            tools = (this.props.theory === "qed") ? this.renderQEDTools() : this.renderPhi4Tools();
        } else {
            tools = (this.props.theory === "qed") ? this.renderLimitedQEDTools() : this.renderLimitedPhi4Tools();
        }
        return tools;
    }

    renderPhi4Tools() {
        return <div className={style.toolSelector}>
            <label>Tool</label>
            <select className={style.toolList} name="tool" value={this.props.tool} onChange={this.handleToolChange}>
                <option value={"externalVertex"}>External Vertex</option>
                <option value={"internalVertex"}>Internal Vertex</option>
                <option value={"dashedLinePropagator"}>Scalar Propagator</option>
                <option value={"delete"}>Delete Item</option>
            </select>
        </div>;
    }

    renderQEDTools() {
        return <div className={style.toolSelector}>
            <label>Tool</label>
            <select className={style.toolList} name="tool" value={this.props.tool} onChange={this.handleToolChange}>
                <option value={"externalVertex"}>External Vertex</option>
                <option value={"internalVertex"}>Internal Vertex</option>
                <option value={"photonPropagator"}>Photon Propagator</option>
                <option value={"electronPropagator"}>Electron / Positron Propagator</option>
                <option value={"delete"}>Delete Item</option>
            </select>
        </div>;
    }

    renderLimitedPhi4Tools() {
        return <div className={style.toolSelector}>
            <label>Tool</label>
            <select className={style.toolList} name="tool" value={this.props.tool} onChange={this.handleToolChange}>
                <option value={"externalVertex"} disabled={this.state.externalVertex === 0}>
                    {this.state.externalVertex}x External Vertex</option>
                <option value={"internalVertex"} disabled={this.state.internalVertex === 0}>
                    {this.state.internalVertex}x Internal Vertex</option>
                <option value={"dashedLinePropagator"} disabled={this.state.dashedLinePropagator+this.state.universalPropagator === 0}>
                    {this.state.dashedLinePropagator+this.state.universalPropagator}x Scalar Propagator</option>
                <option value={"delete"}>Delete Item</option>
            </select>
        </div>;
    }

    renderLimitedQEDTools() {
        return <div className={style.toolSelector}>
            <label>Tool</label>
            <select className={style.toolList} name="tool" value={this.props.tool} onChange={this.handleToolChange}>
                <option value={"externalVertex"} disabled={this.state.externalVertex === 0}>
                    {this.state.externalVertex}x External Vertex</option>
                <option value={"internalVertex"} disabled={this.state.internalVertex === 0}>
                    {this.state.internalVertex}x Internal Vertex</option>
                <option value={"photonPropagator"} disabled={this.state.photonPropagator+this.state.universalPropagator === 0}>
                    {this.state.photonPropagator+this.state.universalPropagator}x Photon Propagator</option>
                <option value={"electronPropagator"} disabled={this.state.electronPropagator+this.state.universalPropagator === 0}>
                    {this.state.electronPropagator+this.state.universalPropagator}x Electron / Positron Propagator</option>
                <option value={"delete"}>Delete Item</option>
            </select>
        </div>;
    }
}
export default SketchpadTools;