const stages = [
    {
        dim: 7,
        particles: 5,
        particleInterval: 5000, // in ms
        gForce: 0.75,
        lockedRows: 1,
        exits: 5
    },
    {
        dim: 9,
        particles: 7,
        particleInterval: 4500, // in ms
        gForce: 1.25,
        lockedRows: 2,
        exits: 5
    },
    {
        dim: 9,
        particles: 9,
        particleInterval: 3500, // in ms
        gForce: 2,
        lockedRows: 3,
        exits: 4
    }
];

const colors = {
    m: "#fcba03", // orange
    r: "#03d7fc", // light blue
    particle: "#fcfc03"
}; 

const conf = {
    stages: stages,
    colors: colors
}

export default conf;