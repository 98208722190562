import style from '../../styles/ContentPages.module.css';

const panels = [
    {
        sectionTitle : "Acknowledgements",
        content : 
            <div className={style.displayContent}>
                <h3 className={style.pageTitle}>Funding</h3>
                <p>
                    This project has received funding from the European Union’s Horizon 2020 research and innovation programme under 
                    the Marie Skłodowska-Curie grant agreement No. 764850 (SAGEX).
                </p>
                <div className={`${style.mediaWrapper} ${style.smallMediaContent}`} >
                    <img className={style.mediaContent} src="/images/EU.jpg" alt="Logo of the EU flag"></img>
                </div>
                <h3 className={style.pageTitle}>Beneficiary Institutes of SAGEX</h3>
                <ul>
                    <li><a href="https://www.cea.fr/" target="_blank" rel="noopener noreferrer">CEA Saclay</a></li>
                    <li><a href="https://www.desy.de/" target="_blank" rel="noopener noreferrer">Deutsches Elektronen-Synchrotron (DESY) </a></li>
                    <li><a href="https://www.durham.ac.uk/" target="_blank" rel="noopener noreferrer">Durham University </a></li>
                    <li><a href="https://www.hu-berlin.de/de" target="_blank" rel="noopener noreferrer">Humboldt-Universität zu Berlin </a></li>
                    <li><a href="https://www.qmul.ac.uk/" target="_blank" rel="noopener noreferrer">Queen Mary University of London</a></li>
                    <li><a href="https://www.tcd.ie/" target="_blank" rel="noopener noreferrer">Trinity College Dublin </a></li>
                    <li><a href="https://risc.jku.at/" target="_blank" rel="noopener noreferrer">RISC/RISC GmbH</a></li>
                    <li><a href="https://www.ku.dk/english/" target="_blank" rel="noopener noreferrer">University of Copenhagen </a></li>
                </ul>
                <h3 className={style.pageTitle}>Cluster of Excellence »Matters of Activity. Image Space Material«</h3>
                <p>
                    This project was also supported by members of the Cluster of Excellence »Matters of Activity. Image Space Material« funded by the 
                    Deutsche Forschungsgemeinschaft (DFG, German Research Foundation) under Germany's Excellence Strategy – EXC 2025 – 390648296.
                </p>
                <div className={`${style.mediaWrapper} ${style.smallMediaContent}`} >
                    <img className={style.mediaContent} src="/images/moa_logo_white.png" alt="Logo of the cluster of excellence »Matters of Activity. Image Space Material«"></img>
                </div>
            </div>
    }
];

const content = {
    title: "Acknowledgements",
    color: "rgb(255,255,255)",
    backgroundColor: "rgba(24,24,24,0.8)",
    panels: panels
}

export default content;