import Shape from "./Shape";

class Propagator extends Shape {

    constructor(startPos, tool, startVertexID, scale) {
        super();

        this.startPos = startPos;
        this.type = tool;

        this.scale = scale;

        this.charge = (this.type === "electronPropagator") ? 1 : 0;

        this.curvature = 0;

        this.startVertexID = startVertexID;

        this.path = new Path2D();
        this.path.moveTo(this.startPos.x, this.startPos.y);
        
        this.strokeColor = 'rgb(255,255,255)';
        this.fillColor = 'rgb(255,255,255)';
    }

    setCurvature = (curvature) => {
        this.curvature = curvature;
        this.calcPath();
    }

    setEndPoint = (gridPos, endVertexID) => {
        this.endPos = gridPos;
        if (endVertexID) {
            this.endVertexID = endVertexID;
        }
        this.calcPath();
    }

    calcPath = () => {
        this.path = new Path2D();
        this.path.moveTo(this.startPos.x, this.startPos.y);

        switch (this.type) {
            case "electronPropagator":
                this.calcArrowPath();
                break;
            case "photonPropagator":
                this.calcWavyCurvePath();
                break;
            case "dashedLinePropagator":
                this.calcDashedLinePath();
                break;
            default: 
                break;
        }
        this.path.lineTo(this.endPos.x, this.endPos.y);

        if (this.center) {
            this.center.path = new Path2D();
            this.center.path.ellipse(
                this.center.x, 
                this.center.y, 
                this.scale*0.4, 
                this.scale*0.4, 
                0, 
                0, 
                2 * Math.PI
            );
        }
    }

    checkCenterClick = (mousePos) => {
        return Math.sqrt((mousePos.x - this.center.x)*(mousePos.x - this.center.x)+(mousePos.y - this.center.y)*(mousePos.y - this.center.y)) < this.scale * 0.3;
    }

    draw = (ctx, isUnfinished, showCenter) => {
        ctx.fillStyle = (isUnfinished) ? "rgb(160,160,160)" : this.fillColor;
        ctx.strokeStyle = (isUnfinished) ? "rgb(160,160,160)" : this.strokeColor;
        ctx.stroke(this.path);
        
        if (this.arrow) {
            ctx.fill(this.arrow);
        }

        if (showCenter) {
            ctx.save();
            ctx.strokeStyle = "rgb(180, 0, 0)";
            ctx.stroke(this.center.path);
            ctx.restore();
        }
    }

    calcArrowPath = () => {
        this.arrow = new Path2D();

        let direction = {
            x: this.endPos.x - this.startPos.x,
            y: this.endPos.y - this.startPos.y
        }

        const l = Math.sqrt(direction.x * direction.x + direction.y * direction.y);
        direction.x /= l;
        direction.y /= l;

        const stepSize = 2;
        const step = {
            x: direction.x * stepSize,
            y: direction.y * stepSize
        };
        const steps = Math.floor(l / stepSize);
        const middleElement = Math.round(steps/2);

        const normalDirection = {
            x: direction.y,
            y: direction.x
        }

        let currPos = {
            x: this.startPos.x,
            y: this.startPos.y
        };

        let curveOffset = {
            x: 0,
            y: 0
        }

        for (let i = 1; i < steps; i++) {
            if (this.curvature !== 0) {
                let p = i / (steps);
                curveOffset.x = normalDirection.x * Math.sin(p*Math.PI) * this.scale * 0.5 * this.curvature;
                curveOffset.y = normalDirection.y * Math.sin(p*Math.PI) * this.scale * 0.5 * this.curvature;
            }

            this.path.lineTo(
                currPos.x + step.x + curveOffset.x,
                currPos.y + step.y + curveOffset.y
            );

            if (i === middleElement) {
                this.center = { 
                    x: currPos.x + step.x + curveOffset.x, 
                    y: currPos.y + step.y + curveOffset.y 
                };
            }
        
            currPos.x += step.x;
            currPos.y += step.y;
        }

        if (this.curvature !== 0) {
            curveOffset.x = normalDirection.x * Math.sin(Math.PI/2) * this.scale * 0.5 * this.curvature;
            curveOffset.y = normalDirection.y * Math.sin(Math.PI/2) * this.scale * 0.5 * this.curvature;
        }

        if (this.center && this.center.x && this.center.y) {
            const faceLength = this.scale * 0.35;
            const latitude = Math.sqrt(3) * 0.5 * faceLength;
            
            this.arrow.moveTo(
                this.center.x + 1.25 * latitude * direction.x, 
                this.center.y + 1.25 * latitude * direction.y
            );
            this.arrow.lineTo(
                this.center.x - latitude * direction.x - 0.5 * faceLength * direction.y, 
                this.center.y - latitude * direction.y + 0.5 * faceLength * direction.x
            );
            this.arrow.lineTo(
                this.center.x - latitude * direction.x + 0.5 * faceLength * direction.y, 
                this.center.y - latitude * direction.y - 0.5 * faceLength * direction.x
            );

            this.arrow.closePath();
        }
    }

    calcWavyCurvePath() {
        const wavyness = this.scale * 0.1;

        let direction = {
            x: this.endPos.x - this.startPos.x,
            y: this.endPos.y - this.startPos.y
        }

        const l = Math.sqrt(direction.x * direction.x + direction.y * direction.y);
        direction.x /= l;
        direction.y /= l;

        const normalDirection = {
            x: direction.y,
            y: direction.x
        }

        let currPos = {
            x: this.startPos.x,
            y: this.startPos.y
        };

        let curveOffset = {
            x: 0,
            y: 0
        }

        const steps = l / wavyness;
        const middleElement = Math.round(steps/2);

        const stepSize = {
            x: direction.x * l / steps,
            y: direction.y * l / steps
        };

        let up = true;

        for (let i=0; i<steps; i+=2) {
            up = !up;

            let p = i / (steps);
            curveOffset.x = normalDirection.x * Math.sin(p*Math.PI) * this.scale * 0.5 * this.curvature;
            curveOffset.y = normalDirection.y * Math.sin(p*Math.PI) * this.scale * 0.5 * this.curvature;

            let currentCurvedPos = {
                x: currPos.x + curveOffset.x,
                y: currPos.y + curveOffset.y
            };

            if (i === middleElement || i + 1 === middleElement) {
                this.center = { 
                    x: currPos.x + curveOffset.x, 
                    y: currPos.y + curveOffset.y 
                };
            }

            let nextPos = {
                x: currPos.x + stepSize.x,
                y: currPos.y + stepSize.y
            }

            p = (i + 1) / (steps);
            curveOffset.x = normalDirection.x * Math.sin(p*Math.PI) * this.scale * 0.5 * this.curvature;
            curveOffset.y = normalDirection.y * Math.sin(p*Math.PI) * this.scale * 0.5 * this.curvature;

            let nextCurvedPos = {
                x: nextPos.x + curveOffset.x,
                y: nextPos.y + curveOffset.y
            };

            let currDirection = { 
                x: nextCurvedPos.x - currentCurvedPos.x,
                y: nextCurvedPos.y - currentCurvedPos.y
            }

            let stepL = Math.sqrt(currDirection.x * currDirection.x + currDirection.y * currDirection.y);
            currDirection.x /= stepL;
            currDirection.y /= stepL;
        
            let waveOffet = {
                x: ((up) ? (-currDirection.y) : currDirection.y) * wavyness,
                y: ((up) ? currDirection.x : (-currDirection.x)) * wavyness
            }

            this.path.quadraticCurveTo(
                currentCurvedPos.x + currDirection.x * 0.5 + waveOffet.x, // Control Point x
                currentCurvedPos.y + currDirection.y * 0.5 + waveOffet.y, // Control Point y
                nextCurvedPos.x,
                nextCurvedPos.y
            );

            currPos.x += 2*stepSize.x;
            currPos.y += 2*stepSize.y;
        }        
    }

    calcDashedLinePath() {
        let direction = {
            x: this.endPos.x - this.startPos.x,
            y: this.endPos.y - this.startPos.y
        }

        const l = Math.sqrt(direction.x * direction.x + direction.y * direction.y);
        direction.x /= l;
        direction.y /= l;

        const stepSize = this.scale * 0.1;
        const step = {
            x: direction.x * stepSize,
            y: direction.y * stepSize
        };
        const steps = Math.floor(l / stepSize);
        const middleElement = Math.round(steps/2);

        const normalDirection = {
            x: direction.y,
            y: direction.x
        }

        let currPos = {
            x: this.startPos.x,
            y: this.startPos.y
        };

        let curveOffset = {
            x: 0,
            y: 0
        }

        for (let i = 1; i < steps; i++) {
            if (this.curvature !== 0) {
                let p = i / (steps);
                curveOffset.x = normalDirection.x * Math.sin(p*Math.PI) * 20 * this.curvature;
                curveOffset.y = normalDirection.y * Math.sin(p*Math.PI) * 20 * this.curvature;
            }

            if (i === middleElement) {
                this.center = { 
                    x: currPos.x + step.x + curveOffset.x, 
                    y: currPos.y + step.y + curveOffset.y 
                };
            }

            if (i & 1) {
                this.path.moveTo(
                    currPos.x + step.x + curveOffset.x,
                    currPos.y + step.y + curveOffset.y
                );
            } else {
                this.path.lineTo(
                    currPos.x + step.x + curveOffset.x,
                    currPos.y + step.y + curveOffset.y
                );
            }
            
            currPos.x += step.x;
            currPos.y += step.y;
        } 
    }
}

export default Propagator;