import * as THREE from "three";
import {OBJLoader2} from "three/examples/jsm/loaders/OBJLoader2";

class OBJModelController {
    constructor (objUrl, texUrl) {
        this.visible = true;

        this.raycaster = new THREE.Raycaster();

        this.tex = new THREE.TextureLoader().load(texUrl);
        this.tex.anisotropy = 8;

        this.material = new THREE.MeshPhongMaterial( { 
            map: this.tex,
            shininess: 0,
            reflectivity: 0,
            specular:0x000000,
            transparent: true,
            opacity: 1,
            flatShading: false 
        });

        this.objUrl = objUrl;
        this.scale = 1;
    }

    loadObjPromise() {
        return new Promise ((resolve, reject) => {
            const loader = new OBJLoader2();
            loader.load(this.objUrl, (obj) => {
                if (obj) { // Success!
                    // ---------- CAUTION ----------
                    // This obj.children[0] fixes a problem with the OBJs from
                    // blender, which have an unneccessary hierarchy otherwise
                    this.obj = obj.children[0];
                    this.obj.material = this.material;

                    resolve();
                } else { // Error!
                    reject();
                }
            });
        });
    }

    setHighlightHull(geometry, faceGroupMap) {
        let invisMaterial = new THREE.MeshBasicMaterial({
            opacity: 0.0,
            transparent: true
        });

        let highlightMaterial = new THREE.MeshBasicMaterial({
            color : 0xffffff,
            opacity: 0.3,
            transparent: true
        });

        this.hull = new THREE.Mesh( geometry, [invisMaterial, highlightMaterial]);//, invisMaterial, highlightMaterial, invisMaterial, highlightMaterial]);
        // Needed, as cube assigns a multi material as one material per face (and then expects 6 materials)
        geometry.faces.forEach((face) => {face.materialIndex = 0});
        this.obj.add(this.hull);

        // A cube face consists of two triangles, so to highlight the whole face, 
        // groups of faces have to be identified and changed together
        if (faceGroupMap) {
            this.hullFaceGroups = faceGroupMap;
        }
    }

    setHighlightHullVisibility(visible) {
        this.hull.visible = visible;
    }

    setScale(scale) {
        this.scale = scale;
        this.obj.scale.set(scale, scale, scale);
    }

    setAlpha(newAlpha) {
        this.material.opacity = newAlpha;
    }

    setRotation(eulerX, eulerY, eulerZ) {
        const deg2rad = Math.PI / 180;

        this.obj.rotation.set (
            eulerX * deg2rad,
            eulerY * deg2rad,
            eulerZ * deg2rad
        );
    }

    setFaceTitles(faceMap) {
        this.faceMap = faceMap;
    }
    
    setVisibility (isVisible) {
        this.visible = isVisible;
        this.obj.visible = this.visible;
    }

    toggleVisibility() {
        this.setVisibility(!this.visible);
    }

    updateFrontFace(camera) {
        this.raycaster.setFromCamera(new THREE.Vector2(0,0), camera);
        let intersection = this.raycaster.intersectObject(this.obj);

        this.frontFace = (intersection[0]) ? 
            this.faceMap[intersection[0].faceIndex] : "NONE";

        this.updateHullFace();
    }

    updateHullFace() {
        if (this.hull) {
            let hullIntersection = this.raycaster.intersectObject(this.hull)[0];
            
            if (hullIntersection && hullIntersection.face !== this.currHullFace) {
                if (this.currHullFace) {
                    this.changeHullMaterial(this.currHullFaceIndex, 0);
                }
                this.currHullFace = hullIntersection.face;
                this.currHullFaceIndex = hullIntersection.faceIndex;
                this.changeHullMaterial(hullIntersection.faceIndex, 1);
                hullIntersection.object.geometry.elementsNeedUpdate = true;
            }
        }
    }

    changeHullMaterial(faceIndex, matIndex) {
        this.currHullFace.materialIndex = matIndex;
        if (this.hullFaceGroups) {
            this.hullFaceGroups[faceIndex].forEach((face) => {
                this.hull.geometry.faces[face].materialIndex = matIndex;
            });
        }
    }

    getClickedFace(camera, mouse) {
        this.raycaster.setFromCamera(mouse, camera);
        let intersection = this.raycaster.intersectObject(this.obj);

        this.updateHullFace();

        return (intersection[0]) ? 
            this.faceMap[intersection[0].faceIndex] : null;
    }
}

export default OBJModelController;