import React from 'react';

// Styles
import style from '../../styles/FeynmanQuiz.module.css';

class FQSummaryGrid extends React.Component {
    render() {
        let gridCells = [];
        let sum = 0;
        for (let i=0; i<this.props.questionStates.length; i++) {
            gridCells.push(<div key={i*2}>Q<sub>{i+1}</sub></div>);
            let state = this.props.questionStates[i];
            if (state === "success") {
                gridCells.push(<div key={i*2+1}>{this.props.points[i]}</div>);
                sum += this.props.points[i];
            } else {
                gridCells.push(<div key={i*2+1}>Not Completed</div>);
            }
        }
        gridCells.push(<div key={(this.props.points.length+1)*2}>Sum</div>);
        gridCells.push(<div key={(this.props.points.length+1)*2+1}>{sum}</div>);

        return <div className={style.summaryGrid}>
            {gridCells}
        </div>;
    }
}
export default FQSummaryGrid;