export default class Particle {
    constructor(path) {
        this.path = path;
        this.r = 0.005;

        this.prepareWaypoint();
    }

    update(deltaT, refLength) {
        if (this.path.length) {
            let speed = refLength * deltaT * 0.4;
            
            this.x -= this.dir.x * speed;
            this.y -= this.dir.y * speed;
            this.checkProgress();
            
            return false;
        } else {
            return true;
        }
    }

    prepareWaypoint() {
        let pos = this.path.splice(0, 1)[0];
        this.x = pos.x;
        this.y = pos.y;

        if (this.path.length) {
            this.dir = this.normalize({x: this.x - this.path[0].x, y: this.y - this.path[0].y});
            this.distance = this.length({x: this.x - this.path[0].x, y: this.y - this.path[0].y});
        }
    }

    checkProgress() {
        let newDistance = this.length({x: this.x - this.path[0].x, y: this.y - this.path[0].y});
        if (newDistance > this.distance || newDistance <= this.r) {
            this.prepareWaypoint();
        } else {
            this.distance = newDistance;
        }
        
    }

    draw(ctx, refLength) {
        ctx.save();

        ctx.fillStyle = "#34eb7a";
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.r*refLength, 0, Math.PI*2);
        ctx.fill();

        ctx.restore();
    }

    normalize(vec2) {
        let l = this.length(vec2);
        vec2.x /= l; vec2.y /= l;
        return vec2;
    }

    length(vec2) {
        return Math.sqrt(vec2.x * vec2.x + vec2.y * vec2.y);
    }
}