import Particle from "./Particle";

export default class ParticleShooterLogic {
    constructor(refLength) {
        this.shooter = {
            x: refLength / 2,
            width: refLength * 0.1,
            lastShot: 0
        }

        this.debug = {
            showLine: false,
            showPolygon: false
        };

        this.gameState = "intro"; // "play", "reveal"
        this.stateStartTime = performance.now();

        this.particles = [];
    }

    draw(ctx, refLength) {
        ///////////////////////////////////////////////
        //              DEBUG INFO
        ///////////////////////////////////////////////
        if (this.intersection && this.debug.showLine) {
            // Draw intersection Point
            ctx.fillStyle = "#f00";
            ctx.beginPath();
            ctx.arc(this.intersection.x, this.intersection.y, 0.005 * refLength, 0, Math.PI * 2);
            ctx.fill();

            // Draw particle path
            ctx.strokeStyle = "#ff0";
            ctx.beginPath();
            ctx.moveTo(this.shooter.x, refLength);
            ctx.lineTo(this.intersection.x, this.intersection.y);
            ctx.lineTo(this.intersection.x + this.intersection.r.x, this.intersection.y + this.intersection.r.y);
            ctx.stroke();
        }

        ///////////////////////////////////////////////
        //              Game Elements
        ///////////////////////////////////////////////
        if (this.gameState !== "intro") {
            this.drawPolygon(ctx);
            // Draw Particles
            this.particles.forEach(particle => {
                particle.draw(ctx, refLength);
            });
        }
        this.drawShooter(ctx, refLength);
        this.drawCurtain(ctx, refLength);
    }

    drawCurtain(ctx, refLength) {
        ctx.save();

        // Debug
        if (this.polygon && this.debug.showPolygon) {
            ctx.strokeStyle = "#000";
            this.drawPolygon(ctx);
            ctx.globalAlpha = 0.2;
        } else if (this.gameState === "intro") {
            let alpha = Math.min(1, (performance.now() - this.stateStartTime) / 2000 );
            if (alpha === 1) {
                this.gameState = "play";
            }
            ctx.globalAlpha = alpha; 
        } else if (this.gameState === "reveal") {
            let alpha = Math.max(0, 1 - (performance.now() - this.stateStartTime) / 1000 );
            
            if (alpha === 0) {
                ctx.save();
                ctx.translate(refLength * 0.5, refLength*0.5);
                ctx.lineWidth = refLength * 0.01;

                let offset = 0.05 * refLength;
                if (this.isCorrect) {
                    ctx.strokeStyle = "#00ff00";
                    ctx.beginPath();
                    ctx.moveTo(-offset, 0.5*offset);
                    ctx.lineTo(-0.5*offset, offset);
                    ctx.lineTo(offset, -offset);
                    ctx.stroke();
                } else {
                    ctx.strokeStyle = "#ff0000";
                    ctx.beginPath();
                    ctx.moveTo(-offset, -offset);
                    ctx.lineTo( offset,  offset);
                    ctx.moveTo(-offset,  offset);
                    ctx.lineTo( offset, -offset);
                    ctx.stroke();
                }
                ctx.restore();
            }
            ctx.globalAlpha = alpha;
        }

        // Filled Circle 
        ctx.beginPath();
        ctx.arc(refLength*0.5, refLength*0.5, refLength*0.3, 0, Math.PI * 2);
        ctx.fillStyle="#ccc";
        ctx.fill();

        // Giant Question mark
        ctx.font=refLength*0.2+"px Arial";
        ctx.textBaseline="middle";
        ctx.textAlign="center";
        ctx.fillStyle="#fff";
        ctx.fillText("?", refLength/2, refLength/2);
        ctx.strokeText("?", refLength/2, refLength/2);

        ctx.restore();
    }

    drawPolygon(ctx) {
        ctx.save();

        ctx.stroke(this.polygonPath);
        ctx.restore();
    }

    drawShooter(ctx, refLength) {
        ctx.save();
        ctx.translate(this.shooter.x, refLength);

        ctx.fillStyle = "#7eabf2";
        ctx.strokeStyle = "#000";
        ctx.beginPath();
        ctx.arc(0, 0, this.shooter.width / 2, Math.PI, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();

        ctx.restore();
    }

    moveShooter(deltaX, refLength) {
        this.shooter.x -= deltaX;
        this.shooter.x = Math.min(Math.max(this.shooter.width / 2, this.shooter.x), refLength - (this.shooter.width / 2));
    }

    setPolygon(polygon) {
        this.resetGameState();

        this.polygon = polygon;
        this.polygonPath = new Path2D();

        this.polygonPath.moveTo(this.polygon[0].x, this.polygon[0].y);
        for(let i=1; i<polygon.length; i++) {
            this.polygonPath.lineTo(this.polygon[i].x, this.polygon[i].y);
        }
        this.polygonPath.closePath();
    }

    resetGameState() {
        this.gameState = "intro";
        this.stateStartTime = performance.now();
    }

    update(timeSinceStartUp, deltaT, ctx, refLength) {
        // Check for intersections between shooter and polygon
        if (this.polygon) {
            this.checkPolygonIntersection(refLength);
        }

        // Check if new particle should be shot
        if (this.gameState === "play" && timeSinceStartUp > this.shooter.lastShot + 250) {
            if (this.intersection) {
                this.particles.push(new Particle([
                    {x: this.shooter.x, y: refLength},
                    {x: this.intersection.x, y: this.intersection.y},
                    {
                        x: Math.min(refLength, Math.max(0, this.intersection.x + this.intersection.r.x)), 
                        y: Math.min(refLength, Math.max(0, this.intersection.y + this.intersection.r.y))
                    }
                ]));
            } else {
                this.particles.push(new Particle([
                    {x: this.shooter.x, y: refLength},
                    {x: this.shooter.x, y: -100}
                ]));
            }
            
            this.shooter.lastShot = timeSinceStartUp;
        }

        deltaT /= 1000;
        this.particles.forEach((particle, index) => {
            if (particle.update(deltaT, refLength)) {
                this.particles.splice(index, 1);
            }
        });
    }

    checkPolygonIntersection(refLength) {
        let currentIntersection = { intersects: false, x: 0, y: 0 };

        for (let i = 0; i < this.polygon.length; i++) {
            let endPoint = ((i + 1) >= this.polygon.length) ? 0 : i + 1;

            let result = this.intersectionTest(
                { x: this.shooter.x, y: 0 },
                { x: this.shooter.x, y: refLength },
                this.polygon[i],
                this.polygon[endPoint]
            );

            if (result.intersects && result.y > currentIntersection.y) {
                currentIntersection = result;

                let n = this.normalize({
                    x: -(this.polygon[i].y - this.polygon[endPoint].y),
                    y: (this.polygon[i].x - this.polygon[endPoint].x)
                });
                
                let dot = n.x * 0 + n.y * -refLength;
                let r = this.normalize({
                    x: 0 - 2 * dot * n.x,
                    y: -refLength - 2 * dot * n.y
                });

                let alpha = Math.acos(r.x*n.x+r.y*n.y) * 180 / Math.PI;
                if (alpha > 90) {
                    r.x *= -1;
                }

                currentIntersection.r = {x: r.x * refLength, y: r.y*refLength};
            }
        }

        if (currentIntersection.intersects) {
            this.intersection = currentIntersection;
        } else {
            this.intersection = null;
        }
    }

    reveal(isCorrect) {
        this.stateStartTime = performance.now();
        this.gameState = "reveal";
        this.isCorrect = isCorrect;
    }

    setDebug(config) {
        this.debug = config;
    }

    // Based on https://stackoverflow.com/a/1968345
    // (Converted to JS)
    intersectionTest(p0, p1, p2, p3) {
        let s1 = { x: p1.x - p0.x, y: p1.y - p0.y };
        let s2 = { x: p3.x - p2.x, y: p3.y - p2.y };

        let s = (-s1.y * (p0.x - p2.x) + s1.x * (p0.y - p2.y)) / (-s2.x * s1.y + s1.x * s2.y);
        let t = (s2.x * (p0.y - p2.y) - s2.y * (p0.x - p2.x)) / (-s2.x * s1.y + s1.x * s2.y);

        if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
            // Intersection detected
            return {
                intersects: true,
                x: p0.x + (t * s1.x),
                y: p0.y + (t * s1.y)
            }
        }

        // No Intersection
        return { intersects: false };
    }

    normalize(vec2) {
        let l = this.length(vec2);
        vec2.x /= l; vec2.y /= l;
        return vec2;
    }

    length(vec2) {
        return Math.sqrt(vec2.x * vec2.x + vec2.y * vec2.y);
    }
}