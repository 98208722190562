import React from 'react';

// Components
import FQAnswerGridElement from './FQAnswerGridElement';

// Styles
import style from '../../styles/FeynmanQuiz.module.css';

class FQAnswerGrid extends React.Component {
    constructor(props) {
        super (props);
        this.state = {
            updateIconOrder: 0
        }

        this.iconOrder = this.getRandomIconOrder();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.path !== this.props.path) {
            this.iconOrder = this.getRandomIconOrder();
            this.setState({updateIconOrder: this.state.updateIconOrder+1});
        }
    }

    onAnswerSelectToggle = (id, isSelected) => {
        this.props.onAnswerSelectToggle(id, isSelected);
    }

    getRandomIconOrder() {
        let answers = [1,2,3,4,5,6];
        let indices = [];
        for (let i=1; i<=6; i++) {
            let rIndex = Math.floor(Math.random()*answers.length);
            indices.push(answers.splice(rIndex, 1)[0]);
        }
        return indices;
    }

    render() {
        let cells = [];
        for (let i=0; i<6; i++) {
            let index = this.iconOrder[i];
            cells.push(<FQAnswerGridElement currentQuestion={this.props.currentQuestion} path={this.props.path} 
                id={index} key={index} onSelect={this.onAnswerSelectToggle} solved={this.props.solved}/>);
        }
        return <div className={style.questionAnswerGrid}>
            {cells}
        </div>;
    }
}
export default FQAnswerGrid;