// Config
import conf from '../../config/PBForceGameConfig';

export default class ColumnExitController {
    constructor(force, column) {
        this.force = force;
        this.column = column;
    }

    draw(ctx, cellSize, dim) {
        let halfCell = cellSize/2;
        
        ctx.save();
        ctx.fillStyle = conf.colors[this.force];
        ctx.strokeStyle = "#fff";
        ctx.translate(cellSize*(this.column+1)+halfCell, cellSize*(dim+1)+halfCell);

        ctx.beginPath();
        ctx.ellipse(0, 0, halfCell*0.8, halfCell*0.5, 0, 0, 2*Math.PI);
        ctx.fill();
        ctx.beginPath();
        ctx.ellipse(0, 0, halfCell*0.8, halfCell*0.5, 0, 0, Math.PI, 2*Math.PI);
        ctx.stroke();

        ctx.restore();
    }
}