import React from 'react';
import { withRouter, Redirect } from "react-router-dom";

import AmplituhedronTopicList from '../components/AmplituhedronTopicList';
import AmplituhedronExplorer from '../components/AmplituhedronExplorer';
import ContentPageHeader from '../components/ContentPageHeader';

import style from '../styles/App.module.css';
import menuStyle from '../styles/ExhibitionHub.module.css';

class ExhibitionHub extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTopic: null,
            currentListTopic: null,
            redirectToSubpage: false
        }
    }

    componentDidMount() {
        this.props.onTitleChange("SAGEX Exhibition - Hub");
    }

    componentDidUpdate() {
        if (this.state.redirectToSubpage) {
            this.setState({
                redirectToSubpage: false
            });
        }
    }

    onTopicChange = (newTopic, fromList) => {
        if (fromList) {
            this.setState({currentListTopic: newTopic});
        } else {
            this.setState({ currentTopic: newTopic });
        }
    }

    onSelectTopic = (topic = this.state.currentTopic) => {
        if (topic === "About SAGEX") {
            window.open("https://sagex.org/", "_blank", 'noopener');
        } else if (topic != null) {
            this.setState({
                currentTopic: topic,
                redirectToSubpage: true
            });
        }
    }

    render() {
        if (this.state.redirectToSubpage) {
            return <Redirect push to={"/exhibition-hub/" + this.state.currentTopic.replace(/ /g, "")} />
        }

        return (
            <div className={style.layoutLimiter}>
                <div className={style.layoutWrapper}>
                    <ContentPageHeader title="SAGEX - At the Frontier of Physics" />
                    <div className={`${style.mainDisplay} ${menuStyle.menu}`}>
                        <AmplituhedronTopicList currentTopic={this.state.currentTopic} currentListTopic={this.state.currentListTopic} onSelectTopic={this.onSelectTopic} onTopicChange={this.onTopicChange} />
                        <AmplituhedronExplorer currentTopic={this.state.currentTopic} currentListTopic={this.state.currentListTopic} onTopicChange={this.onTopicChange} onSelectTopic={this.onSelectTopic} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ExhibitionHub);
