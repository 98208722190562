

import React from 'react';

import style from "../styles/ContentPages.module.css";

class PBHeisenberg extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            slider: 0,
            grayscale: 100,
            blur: 0
        };

        this.maxBlur = 5; // px
    }

    sliderUpdate = (ev) => {
        const value = ev.target.value;
        this.setState({
            slider: value,
            grayscale: (1 - value) * 100, 
            blur: value * this.maxBlur
        });
    }

    render() {
        let filter = { filter: "blur("+this.state.blur+"px) grayscale("+this.state.grayscale+"%)"}
        return <div className={`${style.mediaWrapper} ${style.largeMediaContent}`} >
            <img className={style.mediaContent} alt="A tree in blossom" src="/images/physical-basics/pb-blossom.jpg" style={filter} />
            <div className={style.overlaySliderBox}>
                <input type="range" min="0" max="1" step="any" value={this.state.slider} onChange={this.sliderUpdate}></input>
            </div>
        </div>;
    }
}
export default PBHeisenberg;