export default class DrawUtil {
    static drawRectWithOutline = (ctx, x, y, w, h, outlineColor = "#000", fillColor = "#fff") => {
        ctx.save();
        ctx.fillStyle = fillColor;
        ctx.strokeStyle = outlineColor;

        ctx.beginPath();
        ctx.rect(x, y, w, h);
        ctx.fill();
        ctx.stroke();

        ctx.restore();
    }

    static drawCircleWithOutline = (ctx, x, y, r, outlineColor = "#000", fillColor = "#fff") => {
        ctx.save();
        ctx.fillStyle = fillColor;
        ctx.strokeStyle = outlineColor;

        ctx.beginPath();
        ctx.arc(x, y, r, 0, 2 * Math.PI);
        ctx.fill();
        ctx.stroke();

        ctx.restore();
    }
}