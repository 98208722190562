import React from 'react';

// Components
import FQQuestionNavigator from './FQQuestionNavigator';
import FQQuestionWrapper from './FQQuestionWrapper';
import FQAnswerButton from './FQAnswerButton';
import FQCheckPopup from './FQCheckPopUp';
import FQRulesBox from './FQRulesBox';

// Config
import questions from '../../config/FeynmanQuizConfig';

// Styles
import baseStyle from '../../styles/ContentPages.module.css';
import style from '../../styles/FeynmanQuiz.module.css';

class FeynmanQuiz extends React.Component {
    constructor(props) {
        super(props);

        this.initQuizData();
        this.resetAnswerState();

        this.state = {
            numberOfQuestions: questions.length,
            points: 0,
            currentQuestion: 0,
            questionStatesUpdated: 0,
            showCheckPopup: false
        }
    }

    initQuizData() {
        this.questionStates = []; // possible states: unanswered, partly, false, correct
        this.points = [];
        for (let i=0; i<questions.length; i++) {
            this.questionStates.push("unanswered");
            this.points.push(5);
        }
    }

    resetAnswerState() {
        this.answerState = [null, false, false, false, false, false];
    }

    handleQuestionNavClick = (newQuestion) => {
        if (newQuestion !== this.state.currentQuestion) {
            this.setState({ currentQuestion: newQuestion });
            this.resetAnswerState();
        }
    }

    handleAnswerUpdate = (id, isSelected) => {
        this.answerState[id] = isSelected;
    }

    handleNextQuestionClick = () => {
        this.setState({ 
            currentQuestion: (this.state.currentQuestion + 1) % (questions.length + 1),
            showCheckPopup: false 
        });
        this.resetAnswerState();
    }

    handleCheckAnswer = () => {
        let qIndex = this.state.currentQuestion;
        let correctAnswers = 0;
        let errorID = 0;
        // Check for errors
        for (let i=1; i<7; i++) {
            if (this.answerState[i]) { // Answer is selected
                if (questions[qIndex].correct.indexOf(i) > -1) { // ... and correct
                    correctAnswers ++;
                } else { // ... and wrong
                    errorID = i;
                    break;
                }
            }
        }

        // Check for error
        if (errorID > 0) {
            // Report errorID
            this.errorID = errorID;
            this.questionStates[qIndex] = "wrong";
            this.points[qIndex] = Math.max(0, this.points[qIndex]-1);
        } else if (correctAnswers !== questions[qIndex].correct.length) {
            // Report to few answers selected
            this.questionStates[qIndex] = "partly";
            this.points[qIndex] = Math.max(0, this.points[qIndex]-1);
        } else {
            // Report success
            this.points[qIndex] = Math.min(6, this.points[qIndex]+1);
            this.questionStates[qIndex] = "success";
        }
        this.setState({
            questionStatesUpdated: this.state.questionStatesUpdated+1,
            showCheckPopup: true
        });
    }

    handleQuizReset = () => {
        this.initQuizData();
        this.setState({
            currentQuestion: 0,
            questionStatesUpdated: 0
        });
    }

    handleCloseOverlay = () => {
        this.setState({
            showCheckPopup: false
        });
    }

    render() {
        let controls = (this.state.currentQuestion === questions.length) ? null : 
            <FQAnswerButton currentQuestion={this.state.currentQuestion} onCheckAnswer={this.handleCheckAnswer}
                questionStates={this.questionStates} questionStatesUpdated={this.state.questionStatesUpdated} onNextQuestion={this.handleNextQuestionClick}/>;
        
        let checkPopup = null;
        if (this.state.showCheckPopup) {
            checkPopup = <FQCheckPopup currentQuestion={this.state.currentQuestion} errorID={this.errorID} 
                qState={this.questionStates[this.state.currentQuestion]} onCloseOverlay={this.handleCloseOverlay} onNextQuestion={this.handleNextQuestionClick}/>
        }

        let rules = (this.state.currentQuestion >= this.state.numberOfQuestions) ? null : <FQRulesBox currentQuestion={this.state.currentQuestion} path={questions[this.state.currentQuestion]?.path} />;
    
        return <div className={`${baseStyle.displayContent} ${style.quizWrapper}`}>
            <FQQuestionNavigator currentQuestion={this.state.currentQuestion}
                onQuestionNavClick={this.handleQuestionNavClick} numberOfQuestions={this.state.numberOfQuestions} 
                questionStates={this.questionStates} questionStatesUpdated={this.state.questionStatesUpdated}/>
            <FQQuestionWrapper currentQuestion={this.state.currentQuestion} questionStates={this.questionStates} questionStatesUpdated={this.questionStatesUpdated}
                points={this.points} onAnswerSelectToggle={this.handleAnswerUpdate} onReset={this.handleQuizReset}/>
            {controls}
            {rules}
            {checkPopup}
        </div>;
    }
}
export default FeynmanQuiz;