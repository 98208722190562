import React from 'react';
import * as THREE from 'three';
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";

// Styles
import baseStyle from '../../styles/ContentPages.module.css';
import style from '../../styles/BeyondFeynman.module.css';

// Helper
import OBJModelController from '../../helper/OBJModelController';

// Config
import conf from '../../config/BFHexagonFlips';

class PBForceGame extends React.Component {
    constructor(props) {
        super(props);

        this.faceIndex = conf.ids[Math.floor(Math.random()*conf.ids.length)];
        this.animationTime = 3000; // in ms
        this.zoomOut = 1.9;

        this.state = {
            inAnimation: true
        };

        this.debugControls = false;
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        
        // Prepare Scene
        this.width = this.threeMount.clientWidth;
        this.height = this.threeMount.clientHeight;

        this.scene = new THREE.Scene(this.threeMount);
        let n = 6;
        let sceneWidth = (this.width <= this.height) ? n : n / (this.height / this.width); 
        let sceneHeight = (this.width >= this.height) ? n : n / (this.width / this.height);
        this.camera = new THREE.OrthographicCamera( 
            sceneWidth / - 2, sceneWidth / 2, sceneHeight / 2, sceneHeight / - 2, 0.1, 1000);
        
        // is used here to set some distance from a cube that is located at z = 0
        this.camera.position.z = 4; 
        
        this.camera.add(new THREE.DirectionalLight(0xffffff,1.25,0));
        this.scene.add(this.camera);

        this.renderer = new THREE.WebGLRenderer({ 
            antialias: true,
            alpha: true
        });

        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setSize( this.width, this.height );
        this.renderer.sortObjects = false;
        this.threeMount.appendChild( this.renderer.domElement ); // mount using React ref

        if (this.debugControls) {
            this.controls = new TrackballControls(this.camera, this.threeMount);
            this.controls.rotateSpeed = 10.0;
            this.controls.panSpeed = 2.0;
            this.controls.noPan = false;
            this.controls.noZoom = false;
            this.controls.noRoll = false;
            this.controls.staticMoving = true;
        }
        
        //document.addEventListener('keyup', this.spaceBarTest);
        
        // Prepare Tetradecahedron
        this.polygon = new OBJModelController(
            "/meshes/SAGEX_Tetradecahedron.obj",
            "/textures/tetradecahedron_yellow_4k_tex.png"
        );

        // Load Object
        this.polygon.loadObjPromise().then(() => {
            // All Objects loaded, set values
            this.polygon.setScale(0.3);
            this.polygon.setRotation(90, 180, 0);
            
            this.scene.add(this.polygon.obj);
            this.animationStart = performance.now();//this.updateFace();

            this.update();
        }, () => {
            console.log("Some objects could not be loaded!");
        });
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.requestID);
        window.removeEventListener('resize', this.onWindowResize);

        if (this.debugControls) {
            this.controls.dispose();
        }
    }

    update = () => {
        this.renderer.render( this.scene, this.camera );
        this.requestID = window.requestAnimationFrame(this.update);
        
        if (this.debugControls) {
            this.controls.update();
        }

        let now = performance.now();
        if (this.state.inAnimation) {
            // Determine Phase of animation
            let animationProgress = (now - this.animationStart) / this.animationTime;
            if (animationProgress < 0.25) { // Zoom out
                this.camera.zoom = this.zoomOut - (animationProgress / 0.25); 
                this.camera.updateProjectionMatrix();
            } else if (animationProgress >= 0.25 && animationProgress < 0.75) { // Rotate and translate
                let p = (animationProgress - 0.25) / 0.5;
                this.camera.quaternion.slerp(conf.quaternions[this.faceIndex], p);
                this.camera.position.lerp(conf.positions[this.faceIndex], p);
            } else if (animationProgress >= 0.75 && animationProgress < 1) { // Zoom In
                this.camera.zoom = 1 + (this.zoomOut - 1) * (animationProgress - 0.75) / 0.25;
                this.camera.updateProjectionMatrix();
            } else { // Animation Finished
                this.animationStart = null;
                this.setState({inAnimation: false});
            }
        }
    }

    onWindowResize = () => {
        // Prepare Scene
        this.width = this.threeMount.clientWidth;
        this.height = this.threeMount.clientHeight;

        let n = 6;
        let sceneWidth = (this.width <= this.height) ? n : n / (this.height / this.width); 
        let sceneHeight = (this.width >= this.height) ? n : n / (this.width / this.height);
        
        this.camera.left = sceneWidth / - 2; 
        this.camera.right = sceneWidth / 2;
        this.camera.top = sceneHeight / 2; 
        this.camera.bottom = sceneHeight / -2;

        this.camera.updateProjectionMatrix();

        this.renderer.setSize( this.width, this.height );
    };

    flipRed = () => {
        this.flip("r");
    }

    flipYellow = () => {
        this.flip("y");
    }

    flipBlue = () => {
        this.flip("b");
    }

    flip = (color) => {
        this.faceIndex = conf.flips[this.faceIndex][color];
        this.animationStart = performance.now();
        if (!this.state.inAnimation) {
            this.setState({inAnimation: true});
        }
    }

    render() {
        return <div className={`${baseStyle.mediaWrapper} ${baseStyle.largeMediaContent}`}>
            <div className={baseStyle.canvasContent} ref={(threeMount) => { this.threeMount = threeMount }}></div>
            <div className={`${style.tesselationControls} ${style.hexTesselationControlPosition}`}>
                <button className={baseStyle.gameButton} onClick={this.flipRed} disabled={this.state.inAnimation}>Flip Red</button>
                <button className={baseStyle.gameButton} onClick={this.flipYellow} disabled={this.state.inAnimation}>Flip Yellow</button>
                <button className={baseStyle.gameButton} onClick={this.flipBlue} disabled={this.state.inAnimation}>Flip Blue</button>
            </div>
        </div>;
    }
}
export default PBForceGame;