import Video from '../../components/Video';
import style from '../../styles/ContentPages.module.css';

const panels = [
    {
        sectionTitle: "Introduction",
        content:
            <div className={style.displayContent}>
                <p>
                    Einstein's theory of General Relativity gave us a new
                    understanding of gravity, space, and time on large scales.
                    Gravitational waves are one of the fascinating predictions of General
                    Relativity, and they were only proven to exist in 2015, 100 years after
                    Einstein proposed his theory! 
                </p>
                <p>
                    Recently, particle interactions on 
                    quantum scales have hinted at an alternative description of this 
                    classical phenomenon using <a href="/#/exhibition-hub/PhysicalBasics/0">scattering amplitudes</a>. In this section of 
                    the exhibit, we'll explore the exciting and unexpected connection between
                    gravitational waves and scattering amplitudes.
                </p>
                <p>
                    Let's jump right in!
                </p>
            </div>
    },
    {
        sectionTitle: "Theories of Gravity",
        content:
            <div className={style.displayContent}>
                <Video name="gravity-1" />
                <h3 className={style.pageTitle}>What is spacetime?</h3>
                <p>
                    From our everyday lives we know that one can move in three different directions in space: forward and back, 
                    side-to-side, or up and down. But we usually don’t think that we also “move” forward in time. Taking space 
                    and time as the collection of directions where motion is possible, we get what is called the “spacetime”.
                </p>
                <p>
                    Counter to our everyday experience, Einstein’s equations tell us that spacetime should not be seen as a fixed 
                    structure on which physical processes happen, but as being dynamical itself. Its dynamics affect and are 
                    affected by the objects moving on it.
                </p>
                <h3 className={style.pageTitle}>What are the types of black holes?</h3>
                <p>
                    A “black hole” is any solution to Einstein’s equations that possesses a region from which nothing can escape 
                    once it enters. Black holes are fully described by three quantities: their mass, angular momentum, and electric charge. 
                    While all black hole solutions have a mass, they don’t all have angular momentum or electric charge. A black hole 
                    with no angular momentum and no electric charge is called a Schwarzschild black hole. One with only angular momentum 
                    is called a Kerr black hole. Reissner-Nordstrom black holes have only electric charge, and Kerr-Newman black holes 
                    are those with both angular momentum and an electric charge. In nature, all black holes have angular momentum but 
                    no electric charge.
                </p>
                <h3 className={style.pageTitle}>Inside a black hole</h3>
                <p>
                    What does the inside of a black hole look like? This question has probably crossed the mind of every physicist and 
                    sci-fi afficionado out there. While there is no way to know for sure other than actually entering one – meaning 
                    crossing the black hole horizon – the solutions to Einstein’s equations give us some clues. First, time and space 
                    become irreversibly intertwined in a way that makes it impossible to stay still. Concretely, as time passes, an 
                    object inside a black hole unavoidably approaches the singularity at the center of the black hole. Second, the 
                    strength of gravity is so drastically different at two points at different distances from the center that an object 
                    inside the horizon gets elongated to the point of being pulled apart entirely, in a process euphemistically called 
                    “spaghettification.” 
                </p>
                <p>
                    It certainly doesn’t seem as fun as some movies would have us believe...
                </p>
            </div>
    },
    {
        sectionTitle: "Gravitational Waves",
        content:
            <div className={style.displayContent}>
                <Video name="gravity-2" />
                <h3 className={style.pageTitle}>What is a non-static spacetime?</h3>
                <p>
                    To get an idea, let’s start with a more familiar example of evolving energy distributions: a frog jumping on a 
                    waterlily. As the frog lands, its energy is dissipated into the water, perturbing its shape and generating a 
                    wave as the energy is carried away from the frog. For a tadpole sunbathing in the pond as the wave passes, it 
                    will experience the passing wave as changing the height of the surface of the water. A wave can be thought of 
                    as the propagation of a perturbation – in this case, a perturbation of the waterlily – in some medium – the 
                    water surrounding the waterlily.
                </p>
                <p>
                    In general relativity, when studying a system of binary black holes orbiting one another, one arrives at the 
                    conclusion that the spacetime surrounding them obeys the same equation as the water around the perturbed waterlily. 
                    That is to say, the perturbation is this time caused by the merging of the black holes and the medium in which it 
                    propagates is spacetime itself, which is therefore no longer “static”.
                </p>
                <h3 className={style.pageTitle}>How does laser interferometry work?</h3>
                <p>
                    A laser interferometer is a device that measures distances extremely accurately by measuring an interference pattern 
                    between two overlapping waves. The setup is very easy to visualize: first, we need two long tunnels – or “arms” – with 
                    the same length for a laser to travel through. The arms meet at the point where light enters the interferometer and 
                    have a 90-degree angle between them. At the point where they meet there is a semi-reflecting mirror, and at the ends 
                    of each arm are fully reflecting mirrors. The laser is emitted from a source towards the semi-reflecting mirror, is 
                    split into both arms, travels the length of both arms, and is reflected back towards the semi-reflecting mirror where 
                    the split beams recombine. The recombined beam forms an interference pattern which is observed by a detector. If the 
                    two beams travelled the same length, the interference pattern will be very bright, as the beams will combine 
                    constructively. If their path lengths differed for whatever reason – even by atomically small distances – the two beams 
                    will be slightly out of tune with each other, and the interference pattern will show this. This latter scenario is 
                    exactly what happens when a gravitational wave passes through the interferometer.
                </p>
                <div className={`${style.mediaWrapper} ${style.largeMediaContent}`}>
                    <img className={style.mediaContent} src="/images/gravity_4.png" alt="an schematic of the functionality of laser interferometry"></img>
                </div>
                <h3 className={style.pageTitle}>Direct versus indirect measurement</h3>
                <p>
                    In some cases, instead of directly observing a physical phenomenon in an experiment, it is easier to measure it indirectly 
                    through the dynamics of other systems. Gravitational waves are one of the cases where the evidence for their existence was 
                    first indirect. It came through the study of a binary neutron star system known as the Hulse-Taylor binary pulsar, whose 
                    orbit is shrinking at the rate predicted by General Relativity if the system is losing energy by radiating gravitational 
                    waves. As for the first direct observation of gravitational waves, this was not made until 2015 by the LIGO gravitational 
                    wave detectors in Livingston and Hanford in the USA.
                </p>
            </div>
    },
    {
        sectionTitle: "Black Holes as Quantum Particles",
        content:
            <div className={style.displayContent}>
                <Video name="gravity-3" />
            </div>
    },
    {
        sectionTitle: "Gravitational Scattering Amplitudes",
        content:
            <div className={style.displayContent}>
                <Video name="gravity-4" />
                <h3 className={style.pageTitle}>What is colour charge?</h3>
                <p>
                    At the most elementary scales known, the energy in the universe can be sorted into two categories of particles: 
                    force carrying particles – such as photons for the electromagnetic force, gluons for the strong nuclear force, 
                    and gravitons for gravity – also known as bosons, and matter particles – such as electrons and quarks – also
                    known as fermions. Bosons can only interact with other particles if those other particles carry the right type 
                    of charge. In electromagnetism, the concept of electric charge is familiar to everyone, and an electrical 
                    charge on a particle fundamentally means that photons can interact with that particle.
                </p>
                <p>
                    The analogue to the electric charge for the strong nuclear force is called the colour charge, and it comes in 
                    six types: (anti-)red, (anti-)blue, and (anti-)green. The known matter particles that carry a colour charge 
                    are the quarks. Another twist as compared to electromagnetism is that gluons carry colour charge themselves, 
                    which means that gluons can interact with each other, much like gravitons and unlike photons!
                </p>
                <h3 className={style.pageTitle}>How to square an amplitude</h3>
                <p>
                    Two remarkable ideas that have been explored in the last 15 years are the colour-kinematics duality and the 
                    double copy as its consequence. Amplitudes involving gluons and quarks can be expressed as a sum of terms, 
                    each of which can be decomposed into two parts: one carrying information about the colour <i>c<sub>i</sub></i> of the 
                    particles involved and the other about the kinematics <i>k<sub>i</sub></i> (the energies, the angles between the incoming 
                    and outgoing particles in the scattering, etc.). For example, an amplitude describing the scattering of four 
                    particles can be written as
                </p>
                <div className={`${style.mediaWrapper} ${style.smallMediaContent}`}>
                    <img className={style.mediaContent} src="/images/gravity_1.png" alt=""></img>
                </div>
                <p>
                    The <i>d<sub>i</sub></i> are called propagators, but you don’t need to worry about them now. The colour-kinematics duality tells us 
                    that the colour and kinematic parts can be made to satisfy similar equations:
                </p>
                <div className={`${style.mediaWrapper} ${style.smallMediaContent}`}>
                    <img className={style.mediaContent} src="/images/gravity_2.png" alt=""></img>
                </div>
                <p>
                    When both relations are true, the double copy allows us to obtain a gravity amplitude by simply replacing colour by kinematics:
                </p>
                <div className={`${style.mediaWrapper} ${style.smallMediaContent}`}>
                    <img className={style.mediaContent} src="/images/gravity_3.png" alt=""></img>
                </div>
                <p>
                    Indeed, while the charge associated to the gluons is the colour charge, for the gravitons it is the kinematics of the process itself.
                </p>
            </div>
    },
    {
        sectionTitle: "Classical Physics from Amplitudes",
        content:
            <div className={style.displayContent}>
                <Video name="gravity-5" />
                <h3 className={style.pageTitle}>Relating quantum and classical physics</h3>
                <p>
                    Maybe you were surprised to hear that scattering amplitudes describe physics on a vast range of scales, but this is not 
                    all that surprising from a physicist’s perspective! All our physical theories have a certain regime of validity; there 
                    are certain conditions that must be met before we can trust the predictions of a theory. Every so often a more general 
                    theory comes along that broadens this regime of validity – like general relativity does for Newtonian gravity when 
                    gravity is strong, or objects move fast – but it must still agree with the original theory where that theory is 
                    valid. Quantum physics is exactly this sort of generalization of classical physics, describing physics on scales 
                    too small for classical physics. Therefore, it must also contain information about the classical, large scale 
                    aspects of a system. The hard part is knowing how to extract that information from the equations!
                </p>
                <h3 className={style.pageTitle}>Gravitational wave templates and amplitudes</h3>
                <p>
                    Gravitational wave signals are extremely weak, so knowing what to look for is crucial for sifting through the 
                    experimental noise. Calculations and simulations are used to build gravitational wave templates, which are the 
                    predictions against which experimental data are compared. These templates are split into three parts, representing 
                    the three stages of the collision of black holes or neutron stars: the inspiral, merger, and ringdown. In the 
                    inspiral stage, the two objects are very far apart, meaning the gravitational attraction between them is weak 
                    and so amplitudes can help with predictions. Whereas General Relativistic methods for understanding the inspiral 
                    typically assume the objects move slowly, scattering amplitudes complement these methods by easily allowing for 
                    large black hole velocities. As for the generation of the remaining parts of the template, they require numerical 
                    solutions to Einstein’s equations; due to strong gravitational fields during the merger and ringdown, current 
                    techniques for computing scattering amplitudes are no longer applicable.
                </p>
            </div>
    },
    {
        sectionTitle: "Future of Gravitational Scattering",
        content:
            <div className={style.displayContent}>
                <Video name="gravity-6" />
                <h3 className={style.pageTitle}>A look to the future of gravitational wave experiments</h3>
                <p>
                    The numerically generated part of the gravitational wave signal from merging black holes includes the final 
                    stage of the coalescence, when a single black hole is formed, and its horizon rapidly stabilizes through the 
                    emission of gravitational waves. The detectable part of this ringdown is rather short so some features of 
                    the ringdown escape the sensitivity of current ground-based observatories. This won't be an issue for the
                    Laser Interferometer Space Antenna (LISA), a planned space-borne gravitational wave observatory. LISA 
                    will be better able to detect the gravitational wave modes emitted during the ringdown phase. 
                    Consequently, LISA will be able to test the `no hair' theorem, which states that black holes are completely 
                    described by their mass, electric charge, and angular momentum.
                </p>
                <h3 className={style.pageTitle}>Theories of Quantum Gravity</h3>
                <p>
                    One of the “holy grails” of theoretical high-energy physics is a theory of quantum gravity – a description 
                    of gravity valid at all scales. Two attempts at such a theory are Loop Quantum Gravity and Causal Dynamical 
                    Triangulations. These take approaches that assume that spacetime is discrete, meaning there is a shortest 
                    possible distance under which nothing exists.
                </p>
                <p>
                    Another perhaps more familiar theory is String Theory, which expands upon the wisdom gained from quantum 
                    field theory. It predicts that every particle in the universe is just a particular vibration mode of very 
                    little strings.
                </p>
                <p>
                    String Theory itself has given rise to even more theories of quantum gravity. Perhaps the best-known example 
                    is the AdS/CFT correspondence, which gives a full definition of quantum gravity in terms of a “holographic” 
                    quantum field theory in one less dimension. Think of the 3D holograms in Star Wars (gravity) that are produced 
                    by 2D screens (the holographic quantum field theory). This means that in some cases quantum gravity can be 
                    described in terms of a quantum field theory without gravity, which we understand much better. 
                </p>
                <p>
                    String theory and AdS/CFT are the most popular descriptions amongst physicists at the moment, but there are 
                    many other points of view and problems that remain to be solved. However, at the end of the day, no theory 
                    of quantum gravity has been experimentally verified… yet!
                </p>
                <h3 className={style.pageTitle}>We don’t know what we don’t know</h3>
                <p>
                    Even though there are many models that describe possible extensions to our descriptions of gravity, there 
                    is no guarantee that any of those hypotheses are correct. There are several theories that attempt to explain 
                    gravitation on very small scales, but the true theory of quantum gravity could feasibly end up being something 
                    that looks very different from what those theories expect. That’s part of the fun of physics and science as 
                    a whole: we can never be certain until we do experiments, and those experiments might yield astonishing results!
                </p>
            </div>
    }
];

const content = {
    title: "Gravity",
    color: "rgb(255,255,255)",
    backgroundColor: "rgba(24,24,24,0.8)",
    panels: panels
}

export default content;