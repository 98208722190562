import React from 'react';

// Components
import ParticleShooter from './PBParticleShooter';
import ParticleShooterControls from './PBParticleShooterControls';

// Config
import config from "../../config/PBParticleShooterConfig";

class ParticleShooterPanel extends React.Component {
    constructor(props) {
        super(props);

        this.unusedPolygonIds = Array.from(Array(config.polygons.length).keys());
        
        this.state = {
            screen: "start",  // "game", "end"
            stage: 0,
            polygonIndex: this.getUnusedPolygon()
        }

        this.maxStage = 5;
    }

    componentDidUpdate () {
        if (this.state.stage === this.maxStage) {
            setTimeout(()=> {
                this.setState({screen: "end", stage: 0});
            }, 2500);
        }
    }

    getUnusedPolygon() {
        let index = Math.floor(Math.random()*this.unusedPolygonIds.length);
        return this.unusedPolygonIds.splice(index, 1)[0];
    }

    onUserSelection = (isCorrect) => {
        let newPoly = this.getUnusedPolygon();
        this.setState({
            stage: this.state.stage+1, 
            selectionCorrect: isCorrect,
            polygonIndex: newPoly
        });
    }

    onScreenChange = () => {
        this.setState({screen: "game", stage: 0});
    }

    render() {
        return <div>
                <ParticleShooter screen={this.state.screen} polygonIndex={this.state.polygonIndex} onScreenChange={this.onScreenChange} 
                    stage={this.state.stage} selectionCorrect={this.state.selectionCorrect}/>
                <ParticleShooterControls screen={this.state.screen} polygonIndex={this.state.polygonIndex} onUserSelection={this.onUserSelection} />
            </div>;
    }
}
export default ParticleShooterPanel;