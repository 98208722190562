import React from 'react';
import { ReactTypeformEmbed } from 'react-typeform-embed';

import {withRouter, Link} from 'react-router-dom';

import style from '../styles/SideMenu.module.css';

class SideMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            highlightFeedback: false
        };

        this.form = React.createRef();

        let dateObj = new Date();
        let month = dateObj.getUTCMonth() + 1; //months from 1-12
        let year = dateObj.getUTCFullYear();

        this.showFeedbackButton = (year >= 2022 && month >= 11) ? false : true;
    }
    
    componentDidMount() {
        if (this.showFeedbackButton) {
            this.feedbackHighlightTimeoutId = window.setInterval(() => {
                this.setState({highlightFeedback: true});
                window.setTimeout(() => {
                    this.setState({highlightFeedback: false});
                }, 1000);
            }, 300000); // every 5 minutes the feedback button will wiggle (stops when the user opened it)
        }
    }

    componentWillUnmount() {
        if (this.showFeedbackButton) {
            window.clearInterval(this.feedbackHighlightTimeoutId);
        }
    }

    openFeedbackForm = () => {
        if (this.showFeedbackButton) {
            if (this.typeformEmbed && this.typeformEmbed.typeform) {
                //this.setState({formOpen: true});
                this.typeformEmbed.typeform.open();
            }
        }
    }

    render() {
        const highlight = (this.state.highlightFeedback) ? style.highlight : null;

        let feedbackFormButton = null;
        let feedbackForm = null;

        if (this.showFeedbackButton) {
            feedbackFormButton = <div className={`${style.sideMenuButton} ${highlight}`} onClick={this.openFeedbackForm}>Feedback</div>;
            feedbackForm = <ReactTypeformEmbed ref={tf => { this.typeformEmbed = tf; }} 
                url="https://m4qprs8snmg.typeform.com/to/VR6cKIW3" popup={true} mode="drawer_right" />;
        }

        return <div className={style.wrapper}>
            {feedbackFormButton}
            <Link className={style.sideMenuButton} to="/privacy-and-cookies">Privacy &amp; Cookies</Link>
            {feedbackForm}
        </div>;
    }
}

export default withRouter(SideMenu);