import React from 'react';

// Components
import FQNavElem from './FQNavElement';

// Styles
import style from '../../styles/FeynmanQuiz.module.css';

class FQQuestionNavigator extends React.Component {

    getColorForAnswerState(index) {
        let c = "#000";
        switch(this.props.questionStates[index]) {
            case "success":
                c="#0f0";
                break;
            case "partly":
                c="#ff0";
                break;
            case "wrong":
                c="#f00";
                break;
            case "unanswered":
            default:
                c = "#fff"
                break;
        }

        return c;
    }

    render() {
        let navBar = [];
        for (let i=0; i<this.props.numberOfQuestions; i++) {
            let index = i+1;
            let text = <span>Q<sub>{index}</sub></span>;
            let color = this.getColorForAnswerState(i);
            navBar.push(<FQNavElem id={i} key={i*2} currentQuestion={this.props.currentQuestion} 
                onQuestionNavClick={this.props.onQuestionNavClick} color={color}>{text}</FQNavElem>);
            navBar.push(<div className={style.questionNavigationSpacer} key={i*2+1} />);
        }
        return <div className={style.questionNavigation}>
            <div className={style.questionNavigationSpacer} />
            {navBar}
            <FQNavElem id={this.props.numberOfQuestions} key={this.props.numberOfQuestions} currentQuestion={this.props.currentQuestion} 
                onQuestionNavClick={this.props.onQuestionNavClick} color={"#fff"}>∑</FQNavElem>
            <div className={style.questionNavigationSpacer} />
        </div>;
    }
}
export default FQQuestionNavigator;