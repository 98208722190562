import React from 'react';

import style from "../styles/ContentPages.module.css";

class ContentPageHeader extends React.Component {
    render() {
        return <div>
            <h1 className={style.headerTitle}>{this.props.title}</h1>
        </div>;
    }
}
export default ContentPageHeader;