import BFShapeHelper from './BFShapeHelper';

export default class BFParallelogram {
    constructor(key_l_a, key_l_b, angle, fillColor = "#fff", strokeToInside = true) {
        this.key_l_a = key_l_a;
        this.key_l_b = key_l_b;
        this.angle = angle;

        // Generate ID
        this.shapeId = this.key_l_a + this.key_l_b + this.angle;
        this.id = BFShapeHelper.getNextFreeId();

        this.strokeToInside = strokeToInside;
        this.resize();

        this.position = {x : 0, y: 0};
        this.rotation = 0;
        this.isSelected = false;

        this.fillColor = fillColor;
    }

    draw(ctx) {
        ctx.save();
        ctx.translate(this.position.x, this.position.y);
        ctx.rotate(this.rotation);

        ctx.fillStyle = this.isSelected ? "#aef2c1" : this.fillColor;
        ctx.beginPath();
        ctx.moveTo(this.aX, this.aY);
        ctx.lineTo(this.bX, this.bY);
        ctx.lineTo(this.cX, this.cY);
        ctx.lineTo(this.dX, this.dY);
        ctx.closePath();
        ctx.fill();

        BFShapeHelper.fillQuad(ctx, this.aX, this.aY, this.inner_aX, this.inner_aY, this.inner_bX, this.inner_bY, this.bX, this.bY, BFShapeHelper.getColorByLength(this.l_a));
        BFShapeHelper.fillQuad(ctx, this.bX, this.bY, this.inner_bX, this.inner_bY, this.inner_cX, this.inner_cY, this.cX, this.cY, BFShapeHelper.getColorByLength(this.l_b));
        BFShapeHelper.fillQuad(ctx, this.cX, this.cY, this.inner_cX, this.inner_cY, this.inner_dX, this.inner_dY, this.dX, this.dY, BFShapeHelper.getColorByLength(this.l_a));
        BFShapeHelper.fillQuad(ctx, this.dX, this.dY, this.inner_dX, this.inner_dY, this.inner_aX, this.inner_aY, this.aX, this.aY, BFShapeHelper.getColorByLength(this.l_b));

        ctx.restore();
    }

    drawThumbnail(ctx, scale) {
        let lineWidth = ctx.lineWidth;
        
        ctx.save();
        ctx.scale(scale, scale);
        ctx.lineWidth = lineWidth / scale;

        BFShapeHelper.fillQuad(ctx, this.aX, this.aY, this.inner_aX, this.inner_aY, this.inner_bX, this.inner_bY, this.bX, this.bY, BFShapeHelper.getColorByLength(this.l_a));
        BFShapeHelper.fillQuad(ctx, this.bX, this.bY, this.inner_bX, this.inner_bY, this.inner_cX, this.inner_cY, this.cX, this.cY, BFShapeHelper.getColorByLength(this.l_b));
        BFShapeHelper.fillQuad(ctx, this.cX, this.cY, this.inner_cX, this.inner_cY, this.inner_dX, this.inner_dY, this.dX, this.dY, BFShapeHelper.getColorByLength(this.l_a));
        BFShapeHelper.fillQuad(ctx, this.dX, this.dY, this.inner_dX, this.inner_dY, this.inner_aX, this.inner_aY, this.aX, this.aY, BFShapeHelper.getColorByLength(this.l_b));

        ctx.restore();
    }

    getClone() {
        return new BFParallelogram(this.key_l_a, this.key_l_b, this.angle);
    }

    checkClick(ctx, mousePos) {
        ctx.save();
        ctx.translate(this.position.x, this.position.y);
        ctx.rotate(this.rotation);

        ctx.beginPath();
        ctx.moveTo(this.aX, this.aY);
        ctx.lineTo(this.bX, this.bY);
        ctx.lineTo(this.cX, this.cY);
        ctx.lineTo(this.dX, this.dY);
        ctx.closePath();

        ctx.restore();

        return ctx.isPointInPath(mousePos.x, mousePos.y);
    }

    resize(positionScale) {
        // 0 - Save triangle side lengths
        this.l_a = BFShapeHelper[this.key_l_a];
        this.l_b = BFShapeHelper[this.key_l_b];

        // 1 - Calculate vertex positions a, b, and c with a at Origin and b at x-axis in distance l_c
        this.aX = 0;
        this.aY = 0;
        this.bX = this.l_a;
        this.bY = 0;

        this.dX = this.l_b * Math.cos(-this.angle);
        this.dY = this.l_b * Math.sin(-this.angle);

        this.cX = this.dX + this.l_a;
        this.cY = this.dY;

        // 2 - Calculate Bisection Points bis_a, bis_b, and bis_c
        this.bis_aX = this.l_a/2;
        this.bis_aY = 0; 
        this.bis_bX = (this.bX + this.cX)/2;
        this.bis_bY = (this.bY + this.cY)/2;
        this.bis_cX = this.bis_aX + this.dX;
        this.bis_cY = this.dY;
        this.bis_dX = this.bis_bX - this.l_a;
        this.bis_dY = this.dY;
        
        // 3 - Calculate centroid m
        let mX = (this.aX + this.bX + this.cX + this.dX) / 4;
        let mY = (this.aY + this.bY + this.cY + this.dY) / 4;
        
        // 4 - Translate all points so that m is at origin
        this.aX -= mX; this.aY -= mY;
        this.bX -= mX; this.bY -= mY;
        this.cX -= mX; this.cY -= mY;
        this.dX -= mX; this.dY -= mY;
        this.bis_aX -= mX; this.bis_aY -= mY;
        this.bis_bX -= mX; this.bis_bY -= mY;
        this.bis_cX -= mX; this.bis_cY -= mY;
        this.bis_dX -= mX; this.bis_dY -= mY;

        // 5 - Calculate vertex offset to center for thicker lines
        let l = 2 * Math.sqrt(this.aX * this.aX + this.aY* this.aY);
        let perimeter = this.l_a + this.l_b + l;
        let incenterX = (this.aX * this.l_b + this.bX * l + this.cX * this.l_a) / perimeter;
        let incenterY = (this.aY * this.l_b + this.bY * l + this.cY * this.l_a) / perimeter;
        let inradius = 0.5 * Math.sqrt(((this.l_b + l - this.l_a)*(l + this.l_a - this.l_b)*(this.l_a + this.l_b - l))/perimeter);

        this.inner_bX = incenterX + (1 + ((this.strokeToInside) ? -1 : 1) * BFShapeHelper.lineWidth / inradius) * (this.bX - incenterX);
        this.inner_bY = incenterY + (1 + ((this.strokeToInside) ? -1 : 1) * BFShapeHelper.lineWidth / inradius) * (this.bY - incenterY);

        this.inner_dX = -this.inner_bX; 
        this.inner_dY = -this.inner_bY;

        l = 2 * Math.sqrt(this.bX * this.bX + this.bY * this.bY);
        perimeter = this.l_a + this.l_b + l;
        incenterX = (this.bX * this.l_a + this.cX * l + this.dX * this.l_b) / perimeter;
        incenterY = (this.bY * this.l_a + this.cY * l + this.dY * this.l_b) / perimeter;
        inradius = 0.5 * Math.sqrt(((this.l_b + l - this.l_a)*(l + this.l_a - this.l_b)*(this.l_a + this.l_b - l))/perimeter);

        this.inner_cX = incenterX + (1 + ((this.strokeToInside) ? -1 : 1) * BFShapeHelper.lineWidth / inradius) * (this.cX - incenterX);
        this.inner_cY = incenterY + (1 + ((this.strokeToInside) ? -1 : 1) * BFShapeHelper.lineWidth / inradius) * (this.cY - incenterY);

        this.inner_aX = -this.inner_cX; 
        this.inner_aY = -this.inner_cY;

        // Optional - Scale position based on resize factor
        if (positionScale) {
            this.position.x *= positionScale;
            this.position.y *= positionScale;
        }
    }

    flip () {
        this.aX *= -1;
        this.bX *= -1;
        this.cX *= -1;
        this.dX *= -1;

        this.inner_aX *= -1;
        this.inner_bX *= -1;
        this.inner_cX *= -1;
        this.inner_dX *= -1;

        this.rotation *= -1;
    }

    setCustomFillColor(color) {
        this.fillColor = color;
    }
}