import style from '../styles/FeynmanDiagrams.module.css';

const conf = [
    {   // 0 - Tutorial Step 1
        intro:
            <div className={style.introWrapper}>
                <span>
                    The simplest Feynman diagram represents a particle propagating without any interaction between two points. This can be represented by a line connecting them.
                </span>
                <br />
                <span>
                    Use the external point and propagator tool to represent the propagation of a particle between two points! The line is dashed for a scalar particle, wavy for a photon and straight for an electron.
                </span>
            </div>,
        parts: 
            {
                theory: "",
                externalVertex: 2,
                internalVertex: 0,
                universalPropagator: 1,
                electronPropagator: 0,
                photonPropagator: 0,
                dashedLinePropagator: 0
            },
        preplacedVertices:
            []
    },
    {   // 1 - Tutorial Step 2
        intro:
            <div className={style.introWrapper}>
                <span>
                    Things start to get weird when we talk about antiparticles. To distinguish an electron from its antiparticle, the positron, we add an arrow direction to their lines. An electron is represented by a line that follows the direction of time, while a positron goes in the opposite way.
                </span>
                <br />
                <span>
                    Try to draw an electron or a positron propagating between the two points!
                </span>
            </div>,
        parts: 
            {
                theory: "qed",
                externalVertex: 0,
                internalVertex: 0,
                universalPropagator: 0,
                electronPropagator: 1,
                photonPropagator: 0,
                dashedLinePropagator: 0
            },
        preplacedVertices:
            [
                {
                    type: "external",
                    x: -4,
                    y: 0
                },
                {
                    type: "external",
                    x: 4,
                    y: 0
                }
            ]
    },
    {   // 2 - Tutorial Step 3
        intro:
            <div className={style.introWrapper}>
                <span>
                    The world is quantum and particles like to do everything they are allowed to do when propagating from one point to another. For example, a moving electron can spend some of its energy to produce a photon. To represent this ‘interaction’ we use what is called an internal vertex.
                </span>
                <br />
                <span>
                    Add an external vertex and three propagators to this diagram to represent this interaction.
                </span>
            </div>,
        parts: 
            {
                theory: "qed",
                externalVertex: 1,
                internalVertex: 0,
                universalPropagator: 0,
                electronPropagator: 2,
                photonPropagator: 1,
                dashedLinePropagator: 0
            },
        preplacedVertices:
            [
                {
                    type: "external",
                    x: -3,
                    y: 1
                },
                {
                    type: "external",
                    x: 3,
                    y: 1
                },
                {
                    type: "internal",
                    x: 0,
                    y: 1
                }
            ]
    },
    {   // 3 - Tutorial Step 4
        intro:
            <div className={style.introWrapper}>
                <span>
                    An important fact to note is that there are certain Feynman rules one must follow when creating a Feynman diagram.
                </span>
                <br />
                <span>
                    In the previous task we were considering QED with electrons, positrons, and photons. Given a fixed set of external particles, one must connect these vertices with propagator lines according to the Feynman rules in order to obtain a full Feynman diagram.
                </span>
                <br />
                <span>
                    For QED, the only rule is that lines can only be connected through a vertex in the following way:
                </span>
                <br />
                <div className={style.introImagesWrapper}>
                    <img className={style.introImages} alt="" src="/images/feynman-diagrams/Rules/qed/rule.svg"></img>
                </div>
                <br />
                <span>
                    Can you draw a Feynman diagram which represents the interaction of two electrons and satisfies this Feynman rule?
                </span>
            </div>,
        parts: 
            {
                theory: "qed",
                externalVertex: 0,
                internalVertex: 0,
                universalPropagator: 5,
                electronPropagator: 0,
                photonPropagator: 0,
                dashedLinePropagator: 0
            },
        preplacedVertices:
            [
                {
                    type: "external",
                    x: -4,
                    y: -3
                },
                {
                    type: "external",
                    x: -4,
                    y: 3
                },
                {
                    type: "external",
                    x: 4,
                    y: -3
                },
                {
                    type: "external",
                    x: 4,
                    y: 3
                },
                {
                    type: "internal",
                    x: 0,
                    y: -1
                },
                {
                    type: "internal",
                    x: 0,
                    y: 1
                }
            ]
    },
    {   // 4 - Tutorial Step 5
        intro:
            <div className={style.introWrapper}>
                <span>
                    Different theories have a different content in particles, which are represented by different types of lines. For example the phi-4 theory contains only a “scalar” particle represented by a dashed line, which needs to satisfy the following rule:
                </span>
                <div className={style.introImagesWrapper}>
                    <img className={style.introImages} alt="" src="/images/feynman-diagrams/Rules/phi/rule.svg"></img>
                </div>
                <span>
                    Can you guess how three scalars can interact among themselves without producing extra particles?
                </span>
            </div>,
        parts: 
            {
                theory: "phi_4",
                externalVertex: 0,
                internalVertex: 2,
                universalPropagator: 0,
                electronPropagator: 0,
                photonPropagator: 0,
                dashedLinePropagator: 7
            },
        preplacedVertices:
            [
                {
                    type: "external",
                    x: -4,
                    y: -3
                },
                {
                    type: "external",
                    x: -4,
                    y: 0
                },
                {
                    type: "external",
                    x: -4,
                    y: 3
                },
                {
                    type: "external",
                    x: 4,
                    y: -3
                },
                {
                    type: "external",
                    x: 4,
                    y: 0
                },
                {
                    type: "external",
                    x: 4,
                    y: 3
                }
            ]
    },
    {   // 5 - Tutorial Step 6
        intro:
            <div className={style.introWrapper}>
                <span>
                    When particles interact, it can happen that one or more particles are emitted and reabsorbed within the same process. For each time this happens, a “loop” is introduced to the associated Feynman diagram. For example the following are “two loop” diagrams:
                </span>
                <div className={style.introImagesWrapper}>
                    <img className={style.introImages} alt="" src="/images/feynman-diagrams/tutorial/phi-1.svg"></img>
                    <img className={style.introImages} alt="" src="/images/feynman-diagrams/tutorial/phi-2.svg"></img>
                </div>
                <span>
                    Diagrams with a higher number of loops typically represent weaker quantum effects. Therefore for physics, calculating a two-loop diagram is usually more important that calculating a seven-loop diagram.
                </span>
                <br />
                <span>
                    Can you imagine a way in which the three-particle interaction in QED
                </span>
                <div className={style.introImagesWrapper}>
                    <img className={style.introImages} alt="" src="/images/feynman-diagrams/Rules/qed/rule.svg"></img>
                </div>
                <span>
                    Is corrected at one-loop level, i.e. find a one-loop diagram with the same external particles?
                </span>
            </div>,
        parts: 
            {
                theory: "qed",
                externalVertex: 0,
                internalVertex: 0,
                universalPropagator: 6,
                electronPropagator: 0,
                photonPropagator: 0,
                dashedLinePropagator: 0
            },
        preplacedVertices:
            [
                {
                    type: "external",
                    x: -4,
                    y: -3
                },
                {
                    type: "external",
                    x: 4,
                    y: -3
                },
                {
                    type: "external",
                    x: 0,
                    y: 4
                },
                {
                    type: "internal",
                    x: -1,
                    y: -1
                },
                {
                    type: "internal",
                    x: 1,
                    y: -1
                },
                {
                    type: "internal",
                    x: 0,
                    y: 1
                }
            ]
    },
    {
        intro:
            <div className={style.introWrapper}>
                <h1>Congratulations!</h1>
                <span>
                    You now know how particle physicists describe interactions between elementary particles! Now feel free to construct your own Feynman diagrams in our sandbox mode! Use the check button to see if what you draw is an allowed diagram.
                </span>
            </div>
    }
]

export default conf;