import React from 'react';

// Config
import config from "../../config/PBParticleShooterConfig";

// Styles
import style from '../../styles/ContentPages.module.css';

// Helper
import ParticleShooterLogic from '../../helper/PBParticleShooter/ParticleShooterLogic';
import DrawUtil from '../../helper/DrawUtil';

class PBParticleShooter extends React.Component {
    constructor(props) {
        super(props);

        this.canvas = React.createRef();
        
        this.timeSinceStartUp = 0;
    }

    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        this.updateCanvasProps();
        this.stageStart = performance.now();

        this.gameController = new ParticleShooterLogic(this.refLength);
        this.gameController.setDebug({
            showLine: false,
            showPolygon: false
        });

        this.update();
    }

    componentDidUpdate(oldProps) {
        if (this.props.polygonIndex !== oldProps.polygonIndex && this.props.stage !== oldProps.stage) {
            if (this.props.stage > oldProps.stage) {
                if (this.props.selectionCorrect) {
                    this.points += 1;
                }
                this.gameController.reveal(this.props.selectionCorrect);
                setTimeout(() => {
                    this.updateShape();
                }, 2500);
            } else {
                this.updateShape();
                this.gameController.resetGameState();
            }
        }
    }

    updateShape = () => {
        let polygon = [];
        if (this.props.polygonIndex !== -1) {
            let template = config.polygons[this.props.polygonIndex].path;
            for (let i=0; i<template.length; i++) {
                polygon.push({
                    x: template[i].x*this.refLength,
                    y: template[i].y*this.refLength
                });
            }
        }
        this.gameController.setPolygon(polygon);
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.requestID);
        window.removeEventListener('resize', this.onWindowResize);
    }

    onMouseDown = (ev) => {
        this.mouseClicked = true;
        const {pageX, pageY} = ev.touches ? ev.touches[0] : ev;

        this.mousePos = this.pageToCanvasCoordinates(pageX, pageY);

        if (this.props.screen === "start" || this.props.screen === "end") {
            this.props.onScreenChange();
            this.updateShape();
            this.points = 0;
        }
    }

    onMouseUp = (ev) => {
        this.mouseClicked = false;
    }

    onMouseMove = (ev) => {
        if (!this.mouseClicked) { return; }
        const {pageX, pageY} = ev.touches ? ev.touches[0] : ev;

        let newMousePos = this.pageToCanvasCoordinates(pageX, pageY);
        if (this.mousePos) {
            // Apply delta
            this.gameController.moveShooter((this.mousePos.x - newMousePos.x), this.refLength);
        }
        
        this.mousePos = newMousePos;
    }

    pageToCanvasCoordinates = (clickX, clickY) => {
        const rect = this.canvas.current.getBoundingClientRect();
        return {
            x: (clickX - rect.left) * this.dpr - this.offset.x,
            y: (clickY - rect.top) * this.dpr - this.offset.y
        };
    }

    updateCanvasProps = () => {
        this.dpr = window.devicePixelRatio || 1;

        // Get current dpr adjusted width / height of DOM element
        this.width = this.canvas.current.clientWidth * this.dpr;
        this.height = this.canvas.current.clientHeight * this.dpr;

        // Set Canvas resolution based on width / height
        this.canvas.current.width = this.width;
        this.canvas.current.height = this.height;
        
        this.refLength = Math.min(this.height, this.width);
        
        this.offset = {
            x: (this.width - this.refLength) / 2,
            y: (this.height - this.refLength) / 2,
        };
    
        this.ctx = this.canvas.current.getContext("2d");
    }

    update = () => {
        // Timing
        let newTimeSinceStartUp = performance.now() - this.stageStart;
        this.deltaT = newTimeSinceStartUp - this.timeSinceStartUp;
        this.timeSinceStartUp = newTimeSinceStartUp;

        this.ctx.clearRect(0,0,this.width, this.height);
        this.ctx.lineWidth = 1 * this.dpr;

        // Translate offset
        this.ctx.save();
        this.ctx.translate(this.offset.x, this.offset.y);
    
        // Fill Background white
        this.ctx.fillStyle = "#ffffff";
        this.ctx.strokeStyle = "#ffffff";
        this.ctx.beginPath();
        this.ctx.rect(0, 0, this.refLength, this.refLength);
        this.ctx.stroke();
        this.ctx.globalAlpha = 0.4;
        this.ctx.fill();
        this.ctx.globalAlpha = 1;

        switch(this.props.screen) {
            case "game":
                this.gameController.update(this.timeSinceStartUp, this.deltaT, this.ctx, this.refLength);
                this.gameController.draw(this.ctx, this.refLength);
                break;
            case "end":
                this.drawEndScreen();
                break;
            case "start":
            default:
                this.drawStartScreen();
                break;
        }
        
        this.ctx.restore();

        this.requestID = window.requestAnimationFrame(this.update);
    }

    drawEndScreen() {
        this.ctx.fillStyle = "#fff";
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";

        this.ctx.font = this.refLength*0.1+"px Arial";
        this.ctx.fillText("Game cleared!", 0.5*this.refLength, 0.1*this.refLength);
        this.ctx.fillText("Thanks for Playing!", 0.5*this.refLength, 0.7*this.refLength);

        this.ctx.font = this.refLength*0.075+"px Arial";
        this.ctx.fillText("Click to Play Again", 0.5*this.refLength, 0.85*this.refLength);

        this.ctx.font = this.refLength*0.075+"px Arial";
        this.ctx.fillText("You detected ", 0.5*this.refLength, 0.3*this.refLength);
        this.ctx.fillText(this.points+" of 5", 0.5*this.refLength, 0.4*this.refLength);
        this.ctx.fillText("shapes correctly", 0.5*this.refLength, 0.5*this.refLength);
    }

    drawStartScreen () {
        this.ctx.font = this.refLength*0.1+"px Arial";
        this.ctx.fillStyle = "#52d132";
        this.ctx.strokeStyle = "#fff";
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.fillText("Scattering Geometry", 0.5*this.refLength, 0.1*this.refLength);
        this.ctx.strokeText("Scattering Geometry", 0.5*this.refLength, 0.1*this.refLength);

        DrawUtil.drawCircleWithOutline(this.ctx, this.refLength*0.5, this.refLength*0.5, this.refLength* 0.25, "#fff", "#52d132");

        this.ctx.fillStyle = "#fff";
        this.ctx.beginPath();
        this.ctx.moveTo(this.refLength*0.4, this.refLength*0.4);
        this.ctx.lineTo(this.refLength*0.65, this.refLength*0.5);
        this.ctx.lineTo(this.refLength*0.4, this.refLength*0.6);
        this.ctx.closePath();
        this.ctx.fill();
        this.ctx.stroke();

        this.ctx.font = this.refLength*0.05+"px Arial";
        this.ctx.fillStyle = "#fff";
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.fillText("Click to START the game", 0.5*this.refLength, 0.9*this.refLength);
    }

    onWindowResize = () => {
        this.updateCanvasProps();
    };

    render() {
      return <div className={`${style.mediaWrapper} ${style.largeMediaContent}`}>
              <canvas ref={this.canvas} className={style.canvasContent} 
                onMouseDown={this.onMouseDown} onTouchStart={this.onMouseDown} 
                onMouseUp={this.onMouseUp} onTouchEnd={this.onMouseUp} onMouseLeave={this.onMouseUp} 
                onMouseMove={this.onMouseMove} onTouchMove={this.onMouseMove}></canvas>
          </div>;
    }
}
export default PBParticleShooter;