import React from 'react';

// Styles
import style from '../../styles/FeynmanQuiz.module.css';

class FQQuestionNavigator extends React.Component {
    handleClick = () => {
        this.props.onQuestionNavClick(this.props.id);
    }
    
    render() {
        let active = (this.props.currentQuestion !== this.props.id) ? style.inactive : null;
        return <div className={`${style.questionNavigationElem} ${active}`} style={{color: this.props.color}} onClick={this.handleClick}>
          {this.props.children}
        </div>;
    }
}
export default FQQuestionNavigator;