import React from 'react';
import {withRouter} from "react-router-dom";

// Components
import SubsectionContentController from '../components/SubsectionContentController';
import SubsectionFooter from '../components/SubsectionFooter';
import ContentPageHeader from '../components/ContentPageHeader';

// Config
import * as SubpageContents from "../config/SubpageContents";

// Styles 
import style from '../styles/App.module.css';

class ExhibitionContent extends React.Component {
    constructor(props) {
        super(props);

        let topic = this.props.match.params.topicID;
        let content = SubpageContents[topic];
        let numberOfPages = content.panels.length;

        let page = this.props.match.params.subPage;
        if (!page || !content.panels[page]) {
            //this.props.history.push("/exhibition-hub/"+topic+"/0");
            page = 0;
        }

        this.state = {
            topic: topic,
            page: page,
            numberOfPages: numberOfPages,
            content: content
        }
        this.updatePageTitle();
    }

    componentDidUpdate(oldprops, oldState) {
        if (this.props.location.pathname !== oldprops.location.pathname) {
            let stateObj = {};
            
            // Check if topic was changed
            if (this.props.match.params.topicID !== oldprops.match.params.topicID) {
                stateObj.topic = this.props.match.params.topicID;
                stateObj.content = SubpageContents[stateObj.topic];
                stateObj.numberOfPages = stateObj.content.panels.length;
                window.scrollTo(0,0); 
            }

            // Check if page was changed
            if (this.props.match.params.subPage !== oldprops.match.params.subPage) {
                let topic = (stateObj.topic) ? stateObj.topic : this.state.topic;
                let content = (stateObj.content) ? stateObj.content : this.state.content;
                if (!content.panels[this.props.match.params.subPage]) {
                    stateObj.page = 0;
                    this.props.history.push("/exhibition-hub/"+topic+"/0");
                } else {
                    stateObj.page = this.props.match.params.subPage;
                }
                window.scrollTo(0,0); 
            }
            
            this.setState(stateObj);
        }

        if (oldState.topic !== this.state.topic || oldState.page !== this.state.page) {
            this.updatePageTitle();
        }
    }

    onPageUpdate = (newPage) => {
        if (this.checkPageValid(newPage)) {
            this.props.history.push("/exhibition-hub/"+this.state.topic+"/"+newPage);
        }
    }

    checkPageValid(newPage) {
        return newPage >= 0 && newPage < this.state.numberOfPages;
    }

    updatePageTitle() {
        this.props.onTitleChange("SAGEX Exhibition - "+this.state.content.title+": "+this.state.content.panels[this.state.page].sectionTitle);
    }

    render() {
        return <div className={style.layoutLimiter}>
            <div className={style.layoutWrapper}>
                <ContentPageHeader title={this.state.content.panels[this.state.page].sectionTitle}/>
                <SubsectionContentController topic={this.state.topic} page={this.state.page} panels={this.state.content.panels} onPageUpdate={this.onPageUpdate} />
                <SubsectionFooter title={this.state.content.title} color={this.state.content.color} page={this.state.page} 
                    numberOfPages={this.state.numberOfPages} onPageUpdate={this.onPageUpdate}/>
            </div>
        </div>;
    }
}
export default withRouter(ExhibitionContent);