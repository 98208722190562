import React from 'react';
import { NavLink } from "react-router-dom";

import style from "../styles/App.module.css";

class LandingPage extends React.Component {
    componentDidMount() {
        this.props.onTitleChange("SAGEX Exhibition - Start");
    }

    render() {
        return (
            <div>
                <h1 className={style.landingpageTitle}><span>SAGEX Online Exhibition</span></h1>
                <h3 className={style.hubLink}>
                    <NavLink to="/exhibition-hub">Enter the Exhibition</NavLink>
                </h3>
            </div>
        );
    }
}

export default LandingPage;
